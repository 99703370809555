import React, { Component } from 'react'

export default class Timer extends Component {

    constructor(props){
        super(props)

        var typeString = ""

        switch (this.props.type){
            case 1:
                typeString = "Oksjon"
                break
            case 2:
                typeString = "Hange"
                break
            default:
                typeString = "Oksjon"
                break
        }

        this.state = {
            timeSring: this.dhm(this.props.time),
            timeCount: this.props.time,
            objectStatus: this.props.status,
            typeString: typeString
        }
      }


    componentDidMount() {

        if (this.state.timeCount > 0){
            this.myInterval = setInterval(() => {
                const { timeString, timeCount } = this.state
                if (timeCount > 0) {
                    this.setState(({ timeCount }) => ({
                        timeCount: timeCount - 1,
                        timeString: this.dhm(timeCount-1)
                    }))
                }
                else {
                    clearInterval(this.myInterval)
                }
                
            }, 1000)
        }
    }

    dhm(t){
        var cd = 24 * 60 * 60,
            ch = 60 * 60,
            d = Math.floor(t / cd),
            h = Math.floor( (t - d * cd) / ch),
            m = Math.floor( (t - d * cd - h * ch) / 60),
            s = Math.round( (t - d * cd - h * ch - m * 60))

        if (s === 60) {
            m++
            s = 0
        }
        if( m === 60 ){
            h++;
            m = 0;
        }
        if( h === 24 ){
            d++;
            h = 0;
        }
        if (d === 0 && h === 0){
            return d + "p " + h + "h " + m + "min " + s + "s"
        }
        else {
            return d + "p " + h + "h " + m + "min "
        }
       
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        return (
            <span>
                { this.state.timeCount === 0 || this.state.timeCount < 0 || this.state.objectStatus !== 3
                    ? <span>{this.state.typeString} on lõppenud!</span>
                    : <span>{this.state.timeString}</span>
                }
            </span>
        )
    }
}