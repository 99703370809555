import React, {useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { connect } from 'react-redux'
import { store } from '../store'
import RoundButton from './RoundButton';
import TypeCount from './TypeCount'
import CustomLink from './CustomLink';

const OverlayMenu = ({user}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const logout = () => {
    localStorage.setItem("token","");
    store.dispatch({type:"STORE_USER",payload: ""})
  }

  return (
    <span ref={ref}>
            <div className="top-login-name" onClick={handleClick}>
              {user.first_name !== undefined && user.surname !== undefined ? 
              <TypeCount count={user.first_name.toString().substring(0,1) + user.surname.toString().substring(0,1)}/>
              :
              ''}
               {user.first_name !== undefined ? 
              <div className="top-login-firstname">{user.first_name.toString()}</div> :
              <div className="top-login-firstname">{user.toString()}</div>
               }
            </div>
      
            <Overlay
              show={show}
              target={target}
              placement="bottom-end"
              container={ref.current}
              containerPadding={20}
              onHide={()=>{setShow(false)}}
              rootClose={true}
              rootCloseEvent='click'
            >
              <Popover id="popover-contained" className="overlay-menu lato size-16 shadow">
                <div>
                  <ul>
                    <li><Link to="/minukonto" onClick={()=>{setShow(false)}}>Minu andmed</Link></li>
                    <li><Link to="/minumaahange" onClick={()=>{setShow(false)}}>Minu tegevused</Link></li>
                  </ul>
                  <RoundButton title="Logi välja" color="button-white" size="16" onClick={logout}/>
                </div>
              </Popover>
            </Overlay>
          </span> 
  )
}

export class Login extends React.Component {

    render() {
      return <div className="top-login">
          {this.props.user !== "" ? 
            <OverlayMenu user={this.props.user}/>
            :
            <CustomLink
              tag='span'
              to="/logisisse"
              >
                  <RoundButton title="Logi&nbsp;sisse" color="button-red" size="size-14"/>
              </CustomLink>}
          
         
          </div>;
    }
  }

  function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(Login)
