import React from 'react';

export default class TypeCount extends React.Component {
    render() {
      var myClass = ""
      if (this.props.list){
        myClass = "type-count lato-black type-count-list"
      }
      else {
        myClass = "type-count lato-black"
      }
      return <div className={myClass}>{this.props.count}</div>;
    }
  }