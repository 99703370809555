import React, { useState, memo } from 'react';
import { useForm, Controller } from 'react-hook-form'
import config from 'react-global-configuration';
import RoundButton from '../components/RoundButton';

export const MobileIdSign = memo(props => {

    const { handleSubmit, errors, control, setValue } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [phone, setPhone] = useState('')
    const [serverError, setServerError] = useState('')

    const isEmpty = (str) => {
        return (!str || 0 === str.length);
    }

    const sign = values => {

        var headers = {
            'Content-Type' : 'application/json'
        }
        headers.Authorization = 'Bearer '+ localStorage.getItem("token")

        var signRequest = {
            uuid: props.objectId,
            objectType: props.objectType
        }

        fetch(config.get('apiUrl')+'midSignRequest',{
        method: 'POST',
        headers,
        body: JSON.stringify(signRequest)
    })
    .then(res=>res.json())
    .then((data)=> {

        let loginError = ""

        if (!isEmpty(data.error)){
            console.log(data.message)
            loginError = data.message
        }


        let verificationCode = data.verificationCode
        let sessionId = data.sessionId

        var signatureRequest = {
            uuid: props.objectId,
            objectType: props.objectType,
            sessionId: sessionId
        }

        props.firstCallback({
            modal:true,
            modalTitle: "Mobiil-ID",
            verificationCode: verificationCode,
            loginError: loginError
        })
        if (isEmpty(data.error)){
            fetch(config.get('apiUrl')+'midSignature',{
                method: 'POST',
                headers,
                body: JSON.stringify(signatureRequest)
            })
            .then(res=>res.json())
            .then((data)=> {
                if (!isEmpty(data.error)){
                    props.secondCallback({
                        modal:true,
                        modalTitle: "Mobiil-ID",
                        verificationCode: "",
                        loginError: data.message,
                        userVerified: false 
                    })
                }
                else {
                        props.secondCallback({
                            modal:false,
                            modalTitle: "Mobile-ID",
                            verificationCode: "",
                            loginError: "",
                            userVerified: true
                        })
                }
            })
        }
    })
    
    }

    return (
       <span>
        <RoundButton icon="mobile-id" color="button-green" size="size-icon" onClick={()=>sign()}/>
       </span>
        
    )
})