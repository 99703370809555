import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Rater from 'react-rater'
import moment from 'moment'
import config from 'react-global-configuration';


export default class FeedbackItem extends React.Component {


    render() {
      return <Container lg="12" className="feedback-container">
              <Row>
                <Col className="lato-bold size-20 highlight-color">{this.props.feedback.title}</Col>
              </Row>
              <Row>
                  <Col className="lato-light size-12 feedback-item-date">{moment(this.props.feedback.created_at).format("DD.MM.YYYY")}</Col>
              </Row>
              <Row className="feedback-item-rates">
                  <Col lg="2" className="feedback-item-total lato-black size-36">{this.props.feedbackAverage}</Col>
                  <Col lg="4">
                      <Container>
                          <Row>
                              <Col className="d-flex w-50">Kvaliteet</Col>
                              <Col className="d-flex w-50 flex-nowrap"><Rater total={5} rating={this.props.feedback.quality_rate} interactive={false} /></Col>
                          </Row>
                          <Row>
                              <Col className="d-flex w-50">Suhtlus</Col>
                              <Col className="d-flex w-50 flex-nowrap"><Rater total={5} rating={this.props.feedback.communication_rate} interactive={false} /></Col>
                          </Row>
                          <Row>
                              <Col className="d-flex w-50">Kiirus</Col>
                              <Col className="d-flex w-50 flex-nowrap"><Rater total={5} rating={this.props.feedback.time_rate} interactive={false} /></Col>
                          </Row>
                      </Container>
                  </Col>
                  <Col lg="6" className="lato size-16">
                      <Container>
                      <Row><Col>{this.props.feedback.content}</Col></Row>
                      
                    {this.props.images.length > 0 ? <Row><Col>
                        {this.props.images.map((image) => 
                            <Image src={config.get('apiUrl') + '/files/' + image.name} thumbnail/>)}
                    </Col></Row> : ''}    
                    </Container></Col>
              </Row>

          </Container>
    }
  }