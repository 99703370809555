import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

export default class CustomerItem extends React.Component {


    render() {
      return <Container className="customer-item">
                <Row>
                    <Col className="customer-logo"><Image src={this.props.image}/></Col>
                </Row> 
             </Container>;
      ;
    }
  }