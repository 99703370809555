import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Redirect } from 'react-router-dom';
import config from 'react-global-configuration';
import LoadingAnimation from '../components/LoadingAnimation';


export default class Content extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            content:null
        }

        this.getContent(this.props.match.params.id)

    }

    getContent = (id) => {
        fetch(config.get('apiUrl')+'content/'+ id,
        {headers: {
            Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
          if (data.content){
            this.setState({
                content: data.content
              })
          }
          
        })
    }



    render() {

        if (this.state.content == null) {
            return <LoadingAnimation style="page-loading-animation"/>
        }

        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{height:"auto",float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">{this.state.content.title}</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow box-content-padding">
                <Col sm="12" lg="12">{this.state.content.content}</Col>
                
            </Row>
        </Container>
        </Container>
        }


}