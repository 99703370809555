import React, { useState, memo } from 'react';
import { useForm, Controller } from 'react-hook-form'
import config from 'react-global-configuration';
import RoundButton from '../components/RoundButton';

export const SmartIdSign = memo(props => {

    const { handleSubmit, errors, control } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [idCode, setIdCode] = useState('')


    const isEmpty = (str) => {
        return (!str || 0 === str.length);
    }

    const sign = values => {

        var headers = {
            'Content-Type' : 'application/json'
        }
        headers.Authorization = 'Bearer '+ localStorage.getItem("token")

        var signRequest = {
                uuid: props.objectId,
                objectType: props.objectType
            }
        
        fetch(config.get('apiUrl')+'sidSignRequest',{
        method: 'POST',
        headers,
        body: JSON.stringify(signRequest)
    })
    .then(res=>res.json())
    .then((data)=> {
        let verificationCode = data.verificationCode
        let sessionId = data.sessionId

        let loginError = ""

        if (!isEmpty(data.error)){
            loginError = data.message
        }
        props.firstCallback({
            modal:true,
            modalTitle: "Smart-ID",
            verificationCode: verificationCode,
            loginError: loginError
        })

        var signatureRequest = {
            uuid: props.objectId,
            objectType: props.objectType,
            sessionId: sessionId
        }

        if (isEmpty(data.error)){
            fetch(config.get('apiUrl')+'sidSignature',{
                method: 'POST',
                headers,
                body: JSON.stringify(signatureRequest)
            })
            .then(res=>res.json())
            .then((data)=> {
                if (!isEmpty(data.error)){
                    props.secondCallback({
                        modal:true,
                        modalTitle: "Smart-ID",
                        verificationCode: "",
                        loginError: data.message,
                        containerSigned: false 
                    })
                }
                else {

                    props.secondCallback({
                        modal:false,
                        modalTitle: "Smart-ID",
                        verificationCode: "",
                        loginError: "",
                        userVerified: true
                    })
                    
                }
            })
        }
        })
       

    }

    return (
        <span>
        <RoundButton icon="smart-id" color="button-green" size="size-icon" onClick={()=>sign()}/>
        </span>
    )
})