import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import { Link, Redirect } from 'react-router-dom'
import PinModal from '../components/PinModal'
import AddObjectProgress from '../components/AddObjectProgress';
import RoundButton from '../components/RoundButton';
import MyActionsMenu from '../components/MyActionsMenu';
import {SmartIdSign} from '../components/SmartIdSign'
import {MobileIdSign} from '../components/MobileIdSign'
import {IdCardSign} from '../components/IdCardSign'

export default class ObjectConfirm extends React.Component {

    constructor(props){
        super(props)

        var existingObject = {}

        if (this.props.location.state != null){
                existingObject = this.props.location.state.object
        }


        this.state = {
            existingObject: existingObject,
            objectId: null,
            objectConfirmed: false,
            step: 2,
            contractCreated: false,
        }

        this.createContract()

    }

    firstAuthenticationHandler = (values) => {

        this.setState({
            modal:values.modal,
            modalTitle: values.modalTitle,
            verificationCode: values.verificationCode,
            loginError: values.loginError,
        })
    }

    secondAuthenticationHandler = (values) => {

        this.setState({
            modal:values.modal,
            modalTitle: values.modalTitle,
            verificationCode: values.verificationCode,
            loginError: values.loginError,
            loginSuccessful: values.userVerified,
            objectConfirmed: values.userVerified
        })
    }

    idCardCallback = (values) => {
        this.setState({
            loginError: values.loginError,
            loginSuccessful: values.userVerified,
            objectConfirmed: values.userVerified
        })
    }

    createContract = () => {
        console.log(this.state.existingObject)
        fetch(config.get('apiUrl')+ 'createContract/' + this.state.existingObject.uuid + '/' + this.state.existingObject.objectType.id,{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            this.setState({contractCreated: true})
        })
    }

    createDocumentContainer = () => {
        fetch(config.get('apiUrl')+ 'createContractContainer' + '/' + this.state.existingObject.uuid,{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            
        })
    }

    sign = () => {
        var path = ""

        switch (this.state.existingObject.objectType.id) {
            case 1: 
                path = "auctionConfirm/"
                break;
            case 2:
                path = "procurementConfirm/"
                break;
            case 3:
                path = "classifiedConfirm/"
                break
            default:
                path = "auctionConfirm/"
                break;
        }

        fetch(config.get('apiUrl')+ path + this.state.existingObject.uuid,{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            if (data.object.status_id === 2) {
                this.setState({
                    objectConfirmed: true
                })
            }
        })
    }

    render() {

        if (this.state.objectConfirmed) {
            return <Redirect to={{
                pathname: "/minumaahange",
              }}/>
        }

        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Lisa objekt</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">
                <Container fluid>
                    <MyActionsMenu step={this.state.step} type={2}/>
                </Container>
                <Container fluid>
                <Row className="box-content-padding">
                    <Container fluid>
                    <Row>
                        <Col className="lato size-16">Objekti lisamiseks pead allkirjastama lepingu ID-kaardi, Mobiil-ID või Smart-ID-ga. Vajadusel paranda andmeid.</Col>
                    </Row>
                    {this.state.contractCreated === true ? 
                    <Row>
                        <Col className="lato size-16"><a href={config.get('contractUrl')+ this.state.existingObject.uuid+'.docx'} target="_blank">Tutvu lepinguga</a></Col>
                    </Row>
                    :
                    <Row>
                        <Col className="lato size-16">Loon lepingut, palun oota ...</Col>
                    </Row>
                    }
                    <Row>
                        <Col className="link-buttons">
                        <Link 
                            to={{
                            pathname: "/lisaobjekt",
                            state: {
                                object: this.state.existingObject
                            }
                            }}
                            className="button-white size-18 button">Paranda andmeid</Link>
                            <IdCardSign objectId={this.state.existingObject.uuid} objectType={this.state.existingObject.objectType.id} firstCallback={this.idCardCallback}/>
                            <SmartIdSign objectId={this.state.existingObject.uuid} objectType={this.state.existingObject.objectType.id} firstCallback={this.firstAuthenticationHandler} secondCallback={this.secondAuthenticationHandler}/>
                            <MobileIdSign objectId={this.state.existingObject.uuid} objectType={this.state.existingObject.objectType.id} firstCallback={this.firstAuthenticationHandler} secondCallback={this.secondAuthenticationHandler}/>
                      </Col>
                    </Row>
                    </Container>

                </Row>

                </Container>
            </Row>

            <PinModal 
                show={this.state.modal}
                onHide={() => this.setState({modal:false})}
                title={this.state.modalTitle}
                verificationcode={this.state.verificationCode}
                errormessage={this.state.loginError}
                sign={true}
            />

            </Container>
          </Container>

    }

}