import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class ListItemBody extends React.Component {


    render() {
      return <Container className="list-item-body">
                <Row>
                    <Col className="item-title lato-black">{this.props.title}</Col>
                </Row> 
                <Row>
                    <Col className="item-location">{this.props.location}</Col>
                </Row> 
                <Row>
                    <Col sm="7" lg="7" className="item-table-header">Katastritunnus</Col>
                    <Col sm="5" lg="5" className="item-table-header">Pindala</Col>
                </Row> 
                <Row>
                    <Col sm="7" lg="7" className="lato-bold size-16 highlight-color">{this.props.cadastrenumber}</Col>
                    <Col sm="5" lg="5" className="lato-bold size-16 highlight-color">{this.props.area} ha</Col>
                </Row>
                {this.props.type !== 2 ? 
                <Row>
                    <Col className="item-price">{this.props.price} €</Col>
                </Row> : '' }
             </Container>;
      ;
    }
  }