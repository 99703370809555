import React from 'react';
import {ReactComponent as TypeEstate} from './../assets/type_estate.svg'
import {ReactComponent as TypeField} from './../assets/type_field.svg'
import {ReactComponent as TypeLogs} from './../assets/type_logs.svg'

export default class SubTypeIcon extends React.Component {

    constructor(props){
        super(props)

        console.log(this.props.type.id);

        let images = {
            3: <TypeLogs/>,
            4: <TypeField/>,
            1: <TypeEstate/>,
            2: <TypeEstate/>
        }
        
        this.state = {
            img: images[this.props.type.id]
        }

    }

    render() {
      return <div className="sub-types-icon-shadow">
          <div className="sub-types-icon">
            {this.state.img}
            </div>
        </div>
        ;
    }
  }