import React from 'react';
import SubTypes from './SubTypes';

export default class SubTypesMenu extends React.Component {

  constructor(props){
    super(props)
    this.state = {
        logCount: 0,
        fieldCount: 0,
        estateCount:0,
        allCount:0
    }
  }

  componentWillReceiveProps(props) {
    var estateCount = 0
    var fieldCount = 0
    var logCount = 0
    var allCount = 0

    props.count.map((object) => {
       switch(object.transaction_type_id){
         case "1":
            estateCount = object.object_count
            break;
         case "2":
            estateCount = estateCount + object.object_count
            break;
         case "3":
           logCount = object.object_count
            break;
         case "4":
           fieldCount = object.object_count
           break;
         case "all":
           allCount = object.object_count
           break;
         default:
            estateCount = object.object_count
            break;
       }
       return null
    })

    this.setState({
      estateCount:estateCount,
      logCount:logCount,
      fieldCount:fieldCount,
      allCount:allCount
    })
  }



    render() {

      return <div className="sub-types-menu">
          <SubTypes type="estate" count={this.state.estateCount} />
          <SubTypes type="logs" count={this.state.logCount} />
          <SubTypes type="field" count={this.state.fieldCount} />
          <SubTypes count={this.state.allCount} />
      </div>;
    }
  }