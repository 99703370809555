import React from 'react';
import Container from 'react-bootstrap/Container'
import TopMenu from './TopMenu';

export default class Header extends React.Component {

    render() {
      
      return <Container fluid>
                <TopMenu count={this.props.count}/>
            </Container>
    }
  }
