import React from 'react';
import './App.css';
import Header from './components/Header';
import Main from './Main';
import Footer from './components/Footer';
import config from 'react-global-configuration';

//config.set({apiUrl: 'http://localhost/maahange/api/public/',contractUrl:'http://localhost/maahange/api/contracts/'})
//config.set({apiUrl: 'https://api.maahange.ee/public/',contractUrl:'https://api.maahange.ee/contracts/'})
config.set({apiUrl: 'https://mh.etnobird.com/api/public/',contractUrl:'http://mh.etnobird.com/api/contracts/'})

function App() {
  return (
    <div className="App">
      <Header/>
      <Main/>
      <Footer/>
    </div>
  );
}

export default App;
