import React from 'react';
import { Table } from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom'
import {ReactComponent as Count} from './../assets/count.svg'
import FormCheckbox from './FormCheckbox';
import RoundButton from './RoundButton';


class TableItem extends React.Component {


    handleClick = (entity) => {
      if (this.props.link !== false){
        if (entity.status === 1){
            var object = {
                object: entity}
            this.props.history.push({
                pathname: "lisaobjekt",
                state: object
            }
            )
          }
          else {
              this.props.history.push(this.props.entityType + "/"+ entity.uuid)
          }
        }
    }

    handleOfferSelect = (entity) => {
      this.props.history.push("../pakkumine/"+ entity)
    }

    handleFeedback = (entity) => {
      this.props.history.push("../tagasiside/"+ entity)
    }

    handleEntityOpen = (entity) => {
      this.props.history.push(this.props.entityType + "/"+ entity.uuid)
    }

    handleCheck = (status, entity, id) => {
      this.props.subscriptionFeedback(status, entity, id)
    }

    render() {
      return <Table striped responsive>
      <thead>
          <tr>
            {this.props.header.map((item) => <th>{item}</th>)}
            {this.props.lineStatus === true || this.props.reviewStatus ? <th></th> : ''}
          </tr>
      </thead>
      <tbody>
              {this.props.entities.map((entity) => 
                      <tr className={this.props.link !== false ? 'clickable' : ''} onClick={() => this.handleClick(entity)}>
                        {entity.content.map((entityItem, idx) => {

                          if (idx < entity.content.length -1){
                            return  <td className={idx < 2 ? 'no-wrap' : ''}>{entityItem}</td>
                          }
                          else {
                            var tableRow = []
                            tableRow.push(<td className={idx < 2 ? 'no-wrap' : ''}>{entityItem}</td>)

                            if (this.props.referenceLink){
                              var referenceLink = "0"
                              if (entity.references !== 0){
                                referenceLink = <Link to={"/portfolio/"+entity.companyUuid}>{entity.references}</Link>
                              }
                              tableRow.push(<td className={idx < 2 ? 'no-wrap' : ''}><span className="star-icon">★</span>: {entity.averageRate === "N/A" ? entity.averageRate  : (entity.averageRate/10).toFixed(1)} <br/><Count className="number-count"/>: {referenceLink}</td>)
                            }
                            
                            if (this.props.lineStatus){
                              tableRow.push(<td><RoundButton title="Vali võitja" color="button-green" size="size-12" onClick={()=> this.handleOfferSelect(entity.uuid)}/></td>)
                            }
                            if (this.props.reviewStatus){console.log(entity.reviewed)
                              if (entity.selected && (entity.reviewed !== 1)){
                                tableRow.push(<td><RoundButton title="Jäta tagasiside" color="button-green" size="size-12" onClick={()=> this.handleFeedback(entity.uuid)}/></td>)
                              }
                              else {
                                tableRow.push(<td></td>)
                              }
                            }
                            if (this.props.subscription) {
                              tableRow.push(<td className="cb"><FormCheckbox id="email" entity={entity} type="checkbox" checked={entity.email} hideLabel={true} className="lato size-18 default-color" checkmark="checkmark" onChange={this.handleCheck}/></td>)
                              tableRow.push(<td className="cb"><FormCheckbox id="sms" entity={entity} type="checkbox" checked={entity.sms} hideLabel={true} className="lato size-18 default-color" checkmark="checkmark" onChange={this.handleCheck}/></td>)
                              tableRow.push(<td><RoundButton title="Vaata" color="button-green" size="size-14" onClick={()=> this.handleEntityOpen(entity)}/></td>)

                            }
                            return tableRow
                          }

                        })}
                      </tr>
                  
                  )
              }
              
      </tbody>
  </Table>
    }
}

export default withRouter(TableItem)