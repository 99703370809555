import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import ReferenceItem from '../components/ReferenceItem';

export default class ReferenceCarousel extends React.Component {

    constructor(props){
        super(props)

        let itemList = [
            {name:"Tarmo (36)", occupation:"kinnisvaramaakler",reference:"Tegelen igapäevaselt maade ja metsade vahendusteenuse pakkumisega. Üks maakleriteenuse protsessi osasid on objekti müügiedu ja võimalike ostjate kaardistamine. Kasutan Maaportaal.ee eksperdipaketti ja selle abil näen ära, kes võiks olla objekti potensiaalsed ostjad ja palju neid olla võib. Samuti saan sealt väga vajalikku infot PRIA põllumassiivide kohta - mida põllul kasvatatakse ja kes ettevõtetest toetust taotleb. Võin kindlalt väita, et eksperdipaketti kasutades olen teeninud lisarahana tuhandeid eurosid ja hoidnud kokku väärtuslikku aega"},
            {name:"Toomas (36)", occupation:"kinnisvaramaakler",reference:"Tegelen igapäevaselt maade ja metsade vahendusteenuse pakkumisega. Üks maakleriteenuse protsessi osasid on objekti müügiedu ja võimalike ostjate kaardistamine. Kasutan Maaportaal.ee eksperdipaketti ja selle abil näen ära, kes võiks olla objekti potensiaalsed ostjad ja palju neid olla võib. Samuti saan sealt väga vajalikku infot PRIA põllumassiivide kohta - mida põllul kasvatatakse ja kes ettevõtetest toetust taotleb. Võin kindlalt väita, et eksperdipaketti kasutades olen teeninud lisarahana tuhandeid eurosid ja hoidnud kokku väärtuslikku aega"},
            {name:"Ants (36)", occupation:"kinnisvaramaakler",reference:"Tegelen igapäevaselt maade ja metsade vahendusteenuse pakkumisega. Üks maakleriteenuse protsessi osasid on objekti müügiedu ja võimalike ostjate kaardistamine. Kasutan Maaportaal.ee eksperdipaketti ja selle abil näen ära, kes võiks olla objekti potensiaalsed ostjad ja palju neid olla võib. Samuti saan sealt väga vajalikku infot PRIA põllumassiivide kohta - mida põllul kasvatatakse ja kes ettevõtetest toetust taotleb. Võin kindlalt väita, et eksperdipaketti kasutades olen teeninud lisarahana tuhandeid eurosid ja hoidnud kokku väärtuslikku aega"},
            {name:"Arno (36)", occupation:"kinnisvaramaakler",reference:"Tegelen igapäevaselt maade ja metsade vahendusteenuse pakkumisega. Üks maakleriteenuse protsessi osasid on objekti müügiedu ja võimalike ostjate kaardistamine. Kasutan Maaportaal.ee eksperdipaketti ja selle abil näen ära, kes võiks olla objekti potensiaalsed ostjad ja palju neid olla võib. Samuti saan sealt väga vajalikku infot PRIA põllumassiivide kohta - mida põllul kasvatatakse ja kes ettevõtetest toetust taotleb. Võin kindlalt väita, et eksperdipaketti kasutades olen teeninud lisarahana tuhandeid eurosid ja hoidnud kokku väärtuslikku aega"},
        ]

        this.state = {
            items: itemList
        }

    }

    render() {
        const items = this.state.items.map((item, key) => 
            <Carousel.Item><ReferenceItem name={item.name} occupation={item.occupation} reference={item.reference}/></Carousel.Item>
        )
      return <Carousel className="reference-carousel" interval={100000} >
                {items}
             </Carousel>;
      ;
    }
  }