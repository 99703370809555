import React from 'react';
import {ReactComponent as Clock} from './../assets/clock.svg'
import Timer from './Timer';
import CustomLink from './CustomLink';
import {withRouter} from 'react-router-dom'

class ListItemFooter extends React.Component {

    constructor(props){
        super(props)

        let star = {
            selected: require('./../assets/star-selected.png'),
            unselected: require('./../assets/star-unselected.png')
        }
        var img = star['unselected']
        if (this.props.favourite) {
            img = star['selected']
        }
        this.state = {
            img: img
        }

    }

    subscribe = () => {
        if (!localStorage.getItem("token")){
            this.props.history.push("/logisisse/")
        }
    }

    render() {


        console.log(this.props.favourite)
      return <div className="list-item-footer">
             {this.props.type !== 3 ?
             <span>
                <Clock classname="time"/> 
                <span className="time-remaining"><Timer time={this.props.time} type={this.props.type} status={this.props.status}/></span>
             </span> : ''}
             <CustomLink
            className="clickable"
            tag='span'
            propagate={false}
            onClick={this.subscribe}
            >
                <img src={this.state.img} className="star"></img>
            </CustomLink>
           

             </div>;
      ;
    }
  }

  export default withRouter(ListItemFooter)