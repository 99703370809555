import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import {Link, withRouter} from 'react-router-dom'
import RoundButton from '../components/RoundButton';
import LoadingAnimation from '../components/LoadingAnimation';


class ConfirmOffer extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            object: null,
            token: localStorage.getItem("token"),
            finished: false

        }

        this.getOffer(this.props.match.params.uuid)
        

    }

    selectOffer = () => {
        fetch(config.get('apiUrl')+"offer/"+this.props.match.params.uuid,{
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
            this.returnToProcurement()
        })
    }

    returnToProcurement = () => {
        this.props.history.push("../hange/"+ this.state.object.procurement.uuid)
    }

  
    getOffer = (uuid) => {
        var header = {}
        if (localStorage.getItem("token")){
            header = {headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            }
        }
        fetch(config.get('apiUrl')+'offer/'+ uuid, header)
        .then(res=>res.json())
        .then((data)=> {

            this.setState({
                object: data.object
                })

        })
    }


    render() {

        if (this.state.object == null) {
            return <LoadingAnimation style="page-loading-animation"/>
        }


        return <Container className="page-container object-page" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="back-link lato size-16">
                <Col>
                    <Link to={"/hange/"+this.state.object.procurement.uuid}>&larr; Tagasi hankele</Link>
                </Col>
            </Row>
            <Row className="page-title">
                <Col lg="9">
                    <Row className="lato-black size-40">{this.state.object.procurement.title}</Row>
                    <Row className="lato size-20">{this.state.object.procurement.cadastres[0].location.settlement_unit}, 
                                        {this.state.object.procurement.cadastres[0].location.municipality.name}, 
                                        {this.state.object.procurement.cadastres[0].location.municipality.county.name}</Row>
                </Col>
           
            </Row>
            <Row className="white-entry-box shadow">
                <Container fluid>

                <Row className="object-content-padding shadow">
                    <Col>
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Pakkumise andmed</Col>
                        </Row>
                        <Row className="table-row">
                            <Col lg="4" className="lato-bold size-16">Pakkumise summa</Col>
                            <Col lg="8" className="lato size-16">{this.state.object.price} €</Col>
                        </Row>
                        <Row className="table-row">
                            <Col lg="4" className="lato-bold size-16">Pakkumise sisu</Col>
                            <Col lg="8" className="lato size-16">{this.state.object.contents}</Col>
                        </Row>
                        <Row>
                            <Col className="center single-row-button">
                                <RoundButton title="Loobu" color="button-white" size="size-16" onClick={()=>{this.returnToProcurement()}}/>
                                <RoundButton title="Vali võitja" color="button-green" size="size-16" onClick={()=>{this.selectOffer()}}/>
                            </Col>
                        </Row>
                  
                    </Col>
                </Row>

                </Container>
            </Row>


            </Container>
          </Container>

    }

}


export default withRouter(ConfirmOffer)