import React, { useRef, useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormTextField from '../components/FormTextField';
import RoundButton from '../components/RoundButton';
import FormCheckbox from '../components/FormCheckbox';
import TypeCount from '../components/TypeCount';
import { useForm, Controller } from 'react-hook-form'
import { Redirect } from 'react-router-dom';
import config from 'react-global-configuration';
import FormTextFieldFormatted from '../components/FormTextFieldFormatted';


function RegistrationForm({ parentCallback, modalCallback }){
    
    const { handleSubmit, errors, control, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [firstName, setFirstName] = useState('')
    const [surname, setSurname] = useState('')
    const [idCode, setIdCode] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [terms, setTerms] = useState('')
    const [sell, setSell] = useState('')
    const [buy, setBuy] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [registrationSuccessful, setRegistrationSuccessful] = useState(false);
    const [serverError, setServerError] = useState('')
    
    const onSubmit = values => {
        fetch(config.get('apiUrl')+'register',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify(values)
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.error) {
                setServerError(data.error)
            }
            else {
                setRegistrationSuccessful(true)
                parentCallback(true)
            }
        })
        
        
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col className="lato-bold size-30 highlight-color sub-title">Isikuandmed</Col>
                    </Row>
                    {serverError !== '' ?  <Row><Col className="error">{serverError}</Col></Row> : ''}
                    <Row>
                        <Col lg="4" className="lato size-18">Eesnimi</Col>
                        <Col lg="8">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="firstName" 
                                        labelVisible={false}
                                        onChange={(value) => {
                                            setFirstName(value)
                                            return value
                                        }}
                                        value={firstName}/>
                                )} 
                                name="firstName" 
                                defaultValue={firstName}
                                rules={{
                                    required:"Eesnime sisetamine on kohustuslik!",
                                    pattern: {
                                        value: /[A-Za-z-]/,
                                        message: "Sisestatud eesnimi on vigane"
                                    }
                                }} 
                                control={control}
                            />
                        </Col>
                    </Row>
                    {errors.firstName && (
                                <Row><Col lg={{offset:4}} className="error">{errors.firstName.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="4" className="lato size-18">Perenimi</Col>
                        <Col lg="8">
                            <Controller 
                                    render={({ onChange }) => (
                                        <FormTextField 
                                            id="surname" 
                                            labelVisible={false}
                                            onChange={(value) => {
                                                setSurname(value)
                                                return value
                                            }}
                                            value={surname}/>
                                    )} 
                                    name="surname"
                                    defaultValue={surname}
                                    rules={{
                                        required:"Perenime sisetamine on kohustuslik!",
                                        pattern: {
                                            value: /[A-Za-z-]/,
                                            message: "Sisestatud perenimi on vigane"
                                        }
                                    }} 
                                    control={control}
                                />
                            
                            </Col>
                    </Row>
                    {errors.surname && (
                                <Row><Col lg={{offset:4}} className="error">{errors.surname.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="4" className="lato size-18">Isikukood</Col>
                        <Col lg="8">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="idCode" 
                                        labelVisible={false} 
                                        onChange={(value) => {
                                            setIdCode(value)
                                            return value
                                        }}
                                        value={idCode}/>
                                )} 
                                name="idCode"
                                defaultValue={idCode}
                                rules={{
                                    required:"Isikukoodi sisetamine on kohustuslik!",
                                    pattern: {
                                        value: /[0-9]/,
                                        message: "Sisestatud isikukood on vigane"
                                    }
                                }} 
                                control={control}
                            />
                            </Col>
                    </Row>
                    {errors.idCode && (
                                <Row><Col lg={{offset:4}} className="error">{errors.idCode.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="4" className="lato size-18">Email</Col>
                        <Col lg="8">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="email" 
                                        labelVisible={false}
                                        onChange={(value) => {
                                            setEmail(value)
                                            return value
                                        }}
                                        value={email}/>
                                )} 
                                name="email"
                                defaultValue={email}
                                rules={{
                                    required:"E-posti aadressi sisetamine on kohustuslik!",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Sisestatud e-posti aadress on vigane"
                                    }
                                }} 
                                control={control}
                            />
                            </Col>
                    </Row>
                    {errors.email && (
                                <Row><Col lg={{offset:4}} className="error">{errors.email.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="4" className="lato size-18">Telefon</Col>
                        <Col lg="8">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextFieldFormatted 
                                        id="phone" 
                                        type="text" 
                                        onChange={(value) => {
                                            setPhone(value)
                                            return value
                                        }}
                                        labelVisible={false} 
                                        value={phone} 
                                        prefix="+372 "/>
                                )} 
                                name="phone"
                                defaultValue={phone}
                                rules={{
                                    required:"Telefoninumbri sisetamine on kohustuslik!",
                                    pattern: {
                                        value: /[0-9]/i,
                                        message: "Sisestatud telefoninumber on vigane"
                                    }
                                }} 
                                control={control}
                            />
                            </Col>
                    </Row>
                    {errors.phone && (
                                <Row><Col lg={{offset:4}} className="error">{errors.phone.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="4" className="lato size-18">Salasõna</Col>
                        <Col lg="8">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="password" 
                                        labelVisible={false}
                                        onChange={(value) => {
                                            setPassword(value)
                                            return value
                                        }}
                                        value={password}/>
                                )} 
                                name="password"
                                type="password"
                                defaultValue={password}
                                rules={{
                                    required:"Salasõna sisetamine on kohustuslik!"
                                }} 
                                control={control}
                            />
                        </Col>
                    </Row>
                    {errors.password && (
                                <Row><Col lg={{offset:4}} className="error">{errors.password.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="4" className="lato size-18">Kinnita salasõna</Col>
                        <Col lg="8">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="confirmPassword" 
                                        labelVisible={false}
                                        onChange={(value) => {
                                            setConfirmPassword(value)
                                            return value
                                        }}
                                        value={confirmPassword}/>
                                )} 
                                name="confirmPassword" 
                                type="password"
                                defaultValue={confirmPassword}
                                rules={{
                                    validate: value => {return(value === getValues("password") || 'Sisestatud salasõnad pole ühesugused')}
                                }} 
                                control={control}
                            />
                        </Col>
                    </Row>
                    {errors.confirmPassword && (
                                <Row><Col lg={{offset:4}} className="error">{errors.confirmPassword.message}</Col></Row>
                            )}
                    <Row>
                        <Col className="lato-bold size-30 highlight-color sub-title">Lisainfo</Col>
                    </Row>
                    <Row>
                        <Col lg="4" className="lato size-18 align-top">Peamiselt huvitun</Col>
                        <Col lg="8">
                            <Container>
                                <Row>
                                    <Col>
                                    <Controller
                                        render={({ onChange }) => (
                                            <FormCheckbox 
                                                id="buy" 
                                                type="checkbox" 
                                                current={buy} 
                                                label="Ostmisest"
                                                onChange={(value) => {setBuy(true) }}
                                                className="lato size-18 default-color" 
                                                checkmark="checkmark"/>
                                        )}
                                        name="buy"
                                        control={control}
                                        defaultValue={false}
                                    />
                                    
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <Controller
                                        render={({ onChange }) => (
                                            <FormCheckbox 
                                                id="sell" 
                                                type="checkbox" 
                                                current={sell}
                                                onChange={(value) => {setSell(true) }}
                                                label="Müümisest või rentimisest" 
                                                className="lato size-18 default-color" 
                                                checkmark="checkmark"/>
                                        )}
                                        name="sell"
                                        control={control}
                                        defaultValue={false}
                                    />
                                    
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <Controller
                                        render={({ onChange }) => (
                                            <FormCheckbox 
                                                id="terms" 
                                                type="checkbox" 
                                                current={terms} 
                                                label="Olen tutvunud ja nõustun" 
                                                labelLink={modalCallback}
                                                onChange={(value) => {setTerms(true) }}
                                                labelLinkText="tingimustega" 
                                                className="lato size-18 default-color" 
                                                checkmark="checkmark"/>
                                        )}
                                        name="terms"
                                        //  rules={{
                                        //      required: "Sa pead nõustuma tingimustega"
                                        //  }}
                                        control={control}
                                        defaultValue={false}
                                    />
                                    
                                    </Col>
                                </Row>
                                {errors.terms && (
                                <Row><Col>{errors.terms.message}</Col></Row>
                            )}
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="8"><RoundButton title="Registreeri" color="button-green" size="size-18"/></Col>
                    </Row>
                    </form>
    )
}

export default class Register extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            registrationSuccessful: false,
        }

    }

    callback = (newStatus) => {
        this.setState({
            registrationSuccessful: newStatus
        })
    }

    navigateToPage = (id) => {
        var win = window.open('/sisu/'+id, '_blank');
    }


    render() {

        if (this.state.registrationSuccessful) {
            return <Redirect to={{
                pathname: "/logisisse",
                state: { loginSuccess: true }
              }}/>
        }

        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{height:"auto",float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Registreeru {this.props.registrationSuccessful}</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow box-content-padding">
                <Col sm="12" lg="6">
                    {<RegistrationForm parentCallback={this.callback} modalCallback={()=>{this.navigateToPage(1)}}/>}
                </Col>
                <Col sm="12" lg="6">
                    <Row>
                        <Col sm="1" lg="2"></Col>
                        <Col lg="10" className="lato-bold size-30 highlight-color sub-title">Kasutajaks registreerumine</Col>
                    </Row>
                    <Row>
                        <Col sm="1" lg="2" className="help-bullet"><TypeCount list={true} count={1}/></Col>
                        <Col sm="11" lg="10" className="lato size-18">Sisesta andmed ja registreeri ennast kasutajaks ning meie haldur võtab Teiega ühendust juba 1 tööpäeva jooksul, et selgitada portaali toimimist ja aidata Teil Maahange.ee lehelt võtta maksimum!</Col>
                    </Row>
                    <Row>
                        <Col sm="1" lg="2" className="help-bullet"><TypeCount list={true} count={2}/></Col>
                        <Col sm="11" lg="10" className="lato size-18">Tuvasta isik kasutades ID-kaarti, Mobiil-ID või Smart-ID</Col>
                    </Row>
                    <Row>
                        <Col sm="1" lg="2" className="help-bullet"><TypeCount list={true} count={3}/></Col>
                        <Col sm="11" lg="10" className="lato size-18">Kinnita oma e-posti aadress ja telefoni number</Col>
                    </Row>
                    <Row>
                        <Col sm="1" lg="2" className="help-bullet"><TypeCount list={true} count={4}/></Col>
                        <Col sm="11" lg="10" className="lato size-18">Alusta kasutamist</Col>
                    </Row>
                    <Row>
                        <Col sm="1" lg="2" className="help-bullet"><TypeCount list={true} count={5}/></Col>
                        <Col sm="11" lg="10" className="lato size-18">Ettevõtteid saad liita peale registreerimist</Col>
                    </Row>
                    <Row>
                        <Col sm="1" lg="4"></Col>
                        <Col sm="11" lg="8"><RoundButton title="Vajad abi registreerumisel?" color="button-green" size="size-18"/></Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        </Container>
        }


}