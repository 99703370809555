import React  from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import ListItem from '../components/ListItem';
import PartnerBlock from '../components/PartnerBlock';
import {useHistory} from 'react-router-dom'
import {SearchForm} from '../components/SearchForm'
import LoadingAnimation from '../components/LoadingAnimation';



function ListLinkItem(data){

    const history = useHistory()

    return(
        <ListItem 
            title={data.data.title}
            type={data.data.transaction_type} 
            image={data.data.cadastres[0].image} 
            location={data.data.cadastres[0].location.address + ", " + data.data.cadastres[0].location.settlement_unit + ", " + data.data.cadastres[0].location.municipality.name + ", " + data.data.cadastres[0].location.municipality.county.name}
            cadastrenumber={data.data.cadastres[0].cadastral_id} 
            area={Math.round((data.data.total_area/1000 + Number.EPSILON) * 100) / 100}
            favourite={data.data.subscriptionable_id} 
            time={data.data.timeInSeconds}
            status={data.data.status_id}
            objectType={2}
            onClick={() => {history.push("hange/"+data.data.uuid)}} 
            price={data.data.current_price}/>)
    

  }

export default class Procurements extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            objects: [],
            searchLimits: {},
            searchCount: 0
        }
        this.getObjectsAll()

      }

    getObjectsAll = () => {
        var headers = {
            'Content-Type' : 'application/json'
        }
        if (localStorage.getItem("token")){
            headers.Authorization = 'Bearer '+ localStorage.getItem("token")
        }
        fetch(config.get('apiUrl')+'objectsAll/2',{headers})
        .then(res=>res.json())
        .then((data)=> {
            this.setState({
                objects: data.results.data,
                searchCount:data.results.total
            })
            
        })
    }

    onChangeTextHandler = (val) => {
        this.setState({
            [val.target.id]: val.target.value
        })
    }

    searchHandler = (results) => {
        console.log(results)
        this.setState({
            objects: results.results.data,
            searchCount:results.results.total
        })
    }

  

    render() {
        return <Container className="page-container" fluid> 
        <Container className="sub-top-background-image" style={{backgroundImage:`url(${require("./../assets/hanked.svg")})`}} fluid>
        <Row>
            <Col>
                <Row className="lato-black size-65 white-color price-calculator-page-title">Hanked</Row>
            </Col>
        </Row>
        </Container>
        <SearchForm parentCallback={this.searchHandler} type={2} searchCount={this.state.searchCount}/>
        {Object.keys(this.state.objects).length !== 0 ?
        <Container className="search-results">
            <Row>
                <Col><hr className="hr-separator"/></Col>
            </Row>
            <Row>
                {this.state.objects.length > 0 ?
                    <Col  className="center">
                        {this.state.objects.map((object) => 
                                <ListLinkItem data={object}/>
                            )}
                    </Col>
                    :
                    <Col>
                        <Row>Hetkel pole ühtegi aktiivset hanget</Row>
                    </Col>
                }
            </Row>
            <Row>
                <Col></Col>
            </Row>
        </Container>
        :
        <Container className="search-results"><Row><LoadingAnimation style="inline-loading-animation"/></Row></Container>
        }
        <PartnerBlock/>
        </Container>;
    }
}