import React from 'react';
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import {ReactComponent as Star} from './../assets/star_white.svg'
import {ReactComponent as StarFill} from './../assets/star_white_fill.svg'
import CustomLink from '../components/CustomLink';

export default class SubscriptionButton extends React.Component {

    subscribe = () => {
        var mySubscription = {
            id: this.props.id,
            type: this.props.type,
            email: !this.props.subscription,
            sms: !this.props.subscription
        }
        fetch(config.get('apiUrl')+'subscription',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: JSON.stringify(mySubscription)
        })
        .then(res=>res.json())
        .then((data)=> {

            this.props.subscribeCallback(data.subscription.email)
           
        })
    }

    render() {
        return <CustomLink
            className="clickable ml-auto header-subscription lato-bold size-22"
            tag='span'
            onClick={this.subscribe}
            
            >
                {this.props.subscription ? <StarFill className="svg-fill"/> : <Star/>} &nbsp; <span>Jälgin</span>
        </CustomLink>
        
    }

}