import React, {useState} from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import RoundButton from '../components/RoundButton';
import Modal from 'react-bootstrap/Modal'
import FormTextField from './FormTextField';
import config from 'react-global-configuration';
import {FacebookShareButton} from 'react-share'


export default function ShareModal(props) {


    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')

    const closeModal = (status) => {
        setError("")
        setSuccess(false)
        props.parentCallback(status)
    }

    const sendEmail = () => {
        setError('')
        if (email === '') {
            setError("Palun sisestage email aadress")
        }
        else {

            var mySharing = {
                uuid: props.id,
                type: props.type,
                email: email,
                message: message
            }
            fetch(config.get('apiUrl')+'share/email',{
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    Authorization: 'Bearer '+ localStorage.getItem("token")
                },
                body: JSON.stringify(mySharing)
            })
            .then(res=>res.json())
            .then((data)=> {
                if (data.error){
                    setError(error)
                }
                else {
                    setSuccess(true)
                }
            })
        }
    }


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Jaga sõbraga
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.infoText ? <Row><Col className="lato size-16">{props.infoText}</Col></Row> : ''}
        {error ? <Row><Col className="error-message">{error}</Col></Row> : ''}
        {success ? 
            <Container>
                 <Row>
                    <Col className="center lato-bold size-16">
                        Info on saadetud sõbrale!
                    </Col>
                </Row>
                <Row>
                    <Col className="center single-row-button">
                        <RoundButton title="Sulge" color="button-white" size="size-16" onClick={()=>{closeModal(false)}}/>
                    </Col>
                </Row>
            </Container> : 
            <Container>
                    <Row>
                        <Col className="center">
                        <FacebookShareButton
                            url={window.location.href}
                            className="share-button facebook">
                                    <svg fill="#000000"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"><path d="M19,4V7H17A1,1 0 0,0 16,8V10H19V13H16V20H13V13H11V10H13V7.5C13,5.56 14.57,4 16.5,4M20,2H4A2,2 0 0,0 2,4V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V4C22,2.89 21.1,2 20,2Z" /></svg> Jaga Facebookis
                        </FacebookShareButton>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="center">
                        <h4><span>või saada e-kiri</span></h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} className="lato-bold size-16 ">
                            Sõbra email
                        </Col>
                        <Col lg={8}>
                            <FormTextField id="email" labelVisible={false} value={email} onChange={(value)=>setEmail(value)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={4} className="lato-bold size-16 ">
                            Sõnum
                        </Col>
                        <Col lg={8}>
                            <FormTextField id="message" textarea="textarea" rows={6} labelVisible={false} value={message} onChange={(value)=>setMessage(value)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="center single-row-button">
                            <RoundButton title="Loobu" color="button-white" size="size-16" onClick={()=>{closeModal(false)}}/>
                            <RoundButton title="Saada" color="button-green" size="size-16" onClick={()=>{sendEmail()}}/>
                        </Col>
                    </Row>


            </Container>
        }
        
        </Modal.Body>
      </Modal>
    );
  }