import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import { connect } from 'react-redux'
import TableItem from '../components/TableItem';
import MyActionsMenu from '../components/MyActionsMenu';
import RoundButton from '../components/RoundButton';
import CustomLink from '../components/CustomLink';

class MyClassifieds extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            classifieds: [],
        }
        this.getMyClassifieds()

    }

    getMyClassifieds = () => {
        fetch(config.get('apiUrl')+'myClassifieds',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(json => {
                var myClassifieds = []
                json.classifieds.map((classified) => 
                {
                    myClassifieds.push({
                        'uuid':classified.uuid,
                        'status':classified.status.uuid,
                        'content':[classified.title, classified.cadastres[0].cadastral_id,classified.start_price,classified.status.title ]
                    })
                })
                this.setState({
                    classifieds: myClassifieds
                })
            })
    }
  

    render() {


        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Tere, {this.props.user.first_name.toString()}</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">

                <Container fluid>
                    <Row>
                <Container fluid>
                    <MyActionsMenu step={4} type={1}/>
                </Container>
                
                <Container className="account shadow" fluid>
                <Row className="my-actions-section">
                        <Container>
                            <Row>
                                <Col className="lato size-25 highlight-color">Sinu kuulutused</Col>
                            </Row>
                            {this.state.classifieds.length === 0 ?  
                            <Row><Col className="informative-text">Kuulutused puuduvad</Col></Row> : 
                            <Row><Col>
                                <TableItem header={['Nimi','Katastritunnus','Hind','Staatus']} entities={this.state.classifieds} entityType="kuulutus"/>
                            </Col></Row>
                            }
                            <Row className="right">
                                <Col>
                                <CustomLink
                                        tag='span'
                                        target={false}
                                        to="/lisaobjekt"
                                        >
                                            <RoundButton title="Lisa uus" color="button-white" size="size-14"/>
                                </CustomLink>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Container>
                </Row>
                </Container>
                </Row>
                </Container>

     
        </Container>

}


}

function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(MyClassifieds)
