import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import SubTypeIcon from '../components/SubTypeIcon'


export default class ListItemHeader extends React.Component {


    render() {
      return <Container className="list-item-header" style={{backgroundImage:`url(${this.props.image})`}}>
                <Row className="item-icon">
                    <SubTypeIcon type={this.props.type}/>
                </Row> 
             </Container>;
      ;
    }
  }