import React from 'react';
import './../assets/style.css'

export default class TypesMenu extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            menuItems: [
                'Oksjonid','Hanked','Kuulutused'
            ],
            activeItem: 0
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            activeItem: nextProps.activeItem
        })
    }


    onMenuItemClick = (idx) => {
        this.setState({activeItem: idx})
        this.props.onMenuItemSelect(idx)
    }


    render() {
      return <div className="types-menu">
            <ul>
            { this.state.menuItems.map((menu, index) => 
                <li
                className={this.state.activeItem === index ? 'topMenuItemSelected' : ''}
                onClick={this.onMenuItemClick.bind(null, index)} 
                key={index}>{menu}</li> 
            )}
          </ul>
          </div>
    }
  }