import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Table } from 'react-bootstrap';
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import EstateDetails from '../components/EstateDetails';
import {ReactComponent as Star} from './../assets/star_white.svg'
import {ReactComponent as StarFill} from './../assets/star_white_fill.svg'
import EstateExternalDetails from '../components/EstateExternalDetails';
import CustomLink from '../components/CustomLink';
import SubscriptionButton from '../components/SubscriptionButton';
import LoadingAnimation from '../components/LoadingAnimation';
import Highlight from '../assets/highlight.svg'
import ShareButton from '../components/ShareButton';

export default class Classified extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            object: null,
            customer: null,
            error: null, 
            price: null,
            subscription: false,
            token: localStorage.getItem("token"),
            general: [
                "Tehingu liik",
                "Kuulutuse sisu",
                "Hind"
            ],
            generalData: [],
            contact: [
                "Nimi",
                "E-post",
                "Telefon"
            ],
            contactData: []
            
        }

        this.getClassified(this.props.match.params.uuid)
        this.getSubscription(this.props.match.params.uuid)

    }

    getClassified = (uuid) => {
        var header = {}
        if (localStorage.getItem("token")){
            header = {headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            }
        }
        fetch(config.get('apiUrl')+'classified/'+ uuid, header)
        .then(res=>res.json())
        .then((data)=> {
            this.getCustomer(data.object.user_id)
          this.setState({
              object: data.object,
              price: data.object.price,
              generalData: [
                data.object.transaction_type.name,
                data.object.description,
                data.object.start_price
              ]
            })
        })
    }


    getSubscription = (uuid) => {
        fetch(config.get('apiUrl')+'subscription/'+ uuid + '/3',
        {headers: {
            Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
          if (data.subscription){
            this.setState({
                subscription: data.subscription
              })
          }
          
        })
    }


    subscriptionCallback = (status) => {
        this.setState({
            subscription: status
        })
    }

    getCustomer = (id) => {
        fetch(config.get('apiUrl')+'customer/'+ id,{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
          this.setState({
              customer: data.user,
              contactData: [
                data.user.first_name,
                data.user.email,
                data.user.phone
              ]
            })
        })
    }



    render() {

        if (this.state.object == null) {
            return <LoadingAnimation style="page-loading-animation"/>
        }

        const lblink = "https://xgis.maaamet.ee/ky/" + this.state.object.cadastres[0].cadastral_id
        const mrlink = "https://register.metsad.ee/#/?kataster=" + this.state.object.cadastres[0].cadastral_id

        return <Container className="page-container object-page" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="back-link lato size-16">
                <Col>
                    <Link to="/kuulutused">&larr; Tagasi kuulutuste nimekirja</Link>
                </Col>
            </Row>
            <Row className="page-title">
                <Col lg="9">
                    <Row className="lato-black size-40">{this.state.object.title}</Row>
                    <Row className="lato size-20">{this.state.object.cadastres[0].location.settlement_unit}, 
                                        {this.state.object.cadastres[0].location.municipality.name}, 
                                        {this.state.object.cadastres[0].location.municipality.county.name}</Row>
                </Col>
                {this.state.token ? <ShareButton id={this.state.object.uuid} type="1"/> : ''}

                {this.state.token ? 
                    <SubscriptionButton id={this.state.object.id} type="3" subscription={this.state.subscription} subscribeCallback={this.subscriptionCallback}/>
                    :
                    ''
                }
            </Row>
            <Row className="white-entry-box shadow">
                <Container fluid>
                {this.state.error != null ? <Row className="error-message lato size-16"><Col>{this.state.error}</Col></Row> : ''}
   
                <Row className="object-content-padding shadow">
                    <Col>
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Kuulutuse andmed</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped responsive>
                                    <tbody>
                                    {this.state.general.map((item, idx) => {
                                        return (<tr>
                                            <td className="lato-bold size-16">{item}</td>
                                            <td className="lato size-16">{this.state.generalData[idx]}</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="object-content-padding shadow">
                    <Col>
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Kontaktandmed</Col>
                        </Row>
                        {this.state.customer !== null ? 
                        <Row>
                            <Col>
                                <Table striped responsive>
                                    <tbody>
                                    {this.state.contact.map((item, idx) => {
                                        return (<tr>
                                            <td className="lato-bold size-16">{item}</td>
                                            <td className="lato size-16">{this.state.contactData[idx]}</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col className="lato size-16">Kontaktandmete nägemiseks logi palun sisse.</Col>
                        </Row> }
                    </Col>
                </Row>
                {this.state.object.cadastres.map((cadastre,idx) => {
                return (
                <EstateDetails idx={idx+1} total={this.state.object.cadastres.length} cadastre={cadastre}/>
                )
                })}
                <EstateExternalDetails lblink={lblink} mrlink={mrlink}/>
                {this.state.object.additional_info ? 
                        <Row className="object-content-padding shadow">
                            <Col>
                                <Row>
                                    <Col className="lato-bold size-20 highlight-color sub-title">Lisainfo</Col>
                                </Row>
                                <Row>
                                    <Col className="lato size-16">{this.state.object.additional_info}</Col>
                                </Row>
                            </Col>
                    
                        </Row>
                        :
                        ''
                }

                </Container>
            </Row>


            </Container>
          </Container>

    }

}