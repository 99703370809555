import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import config from 'react-global-configuration';
import { Link } from 'react-router-dom'
import moment from 'moment'
import Highlight from '../assets/highlight.svg'
import MyActionsMenu from '../components/MyActionsMenu';


export default class ObjectReview extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            step: 1,
            object: null
        }

        this.getObject(this.props.location.state.objectId, this.props.location.state.objectType)

    }

    getObject = (uuid, type) => {
        var path = ""
        console.log(type.value)
        switch (type.value) {
            case 1: 
                path = "auction/"
                break;
            case 2:
                path = "procurement/"
                break;
            case 3:
                path = "classified/"
                break
            default:
                path = "auction/"
                break;
        }

        fetch(config.get('apiUrl')+ path + uuid)
        .then(res=>res.json())
        .then((data)=> {
            this.setState({object: data.object})
        })
    }

    render() {

        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Lisa objekt</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">
                <Container fluid>
                    <MyActionsMenu step={this.state.step} type={2}/>
                </Container>
                <Container fluid>
                <Row className="box-content-padding">
                    {this.state.object != null ? 
                    <Container fluid>
                    <Row>
                        <Col lg="3" className="lato-black size-18">Pealkiri</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.title}</Col>
                    </Row>
                    <Row>
                        <Col lg="3" className="lato-black size-18">Objekti tüüp</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.objectType.name}</Col>
                    </Row>
                    <Row>
                        <Col lg="3" className="lato-black size-18">Tehingu liik</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.transaction_type.name} </Col>
                    </Row>
                    {this.state.object.objectType.id === 1 || this.state.object.objectType.id === 3 ? 
                    <Row>
                        <Col lg="3" className="lato-black size-18">{this.state.object.objectType.id === 1 ? "Alghind" : "Hind"}</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.start_price} €</Col>
                    </Row> : '' }
                    {this.state.object.objectType.id === 1 ? 
                    <Row>
                        <Col lg="3" className="lato-black size-18">Pakkumise samm</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.step} €</Col>
                    </Row> : '' }
                    <Row>
                        <Col lg="3" className="lato-black size-18">Eelistatud alguskuupäev</Col>
                        <Col lg="9" className="lato size-18">{moment(this.state.object.start_date).format("DD.MM.YYYY")}</Col>
                    </Row>
                    <Row>
                        <Col lg="3" className="lato-black size-18">Eelistatud lõppkuupäev</Col>
                        <Col lg="9" className="lato size-18">{moment(this.state.object.end_date).format("DD.MM.YYYY")}</Col>
                    </Row>
                    {this.state.object.objectType.id === 2 ? 
                    <Row>
                        <Col lg="3" className="lato-black size-18">Tööde soovitud algusaeg</Col>
                        <Col lg="9" className="lato size-18">{moment(this.state.object.execution_start).format("DD.MM.YYYY")}</Col>
                    </Row> : ''}
                    {this.state.object.objectType.id === 2 ? 
                    <Row>
                        <Col lg="3" className="lato-black size-18">Tööde soovitud lõppaeg</Col>
                        <Col lg="9" className="lato size-18">{moment(this.state.object.execution_end).format("DD.MM.YYYY")}</Col>
                    </Row> : ''}
                    {this.state.object.objectType.id === 2 || this.state.object.objectType.id === 3 ? 
                    <Row>
                        <Col lg="3" className="lato-black size-18">{this.state.object.objectType.id === 2 ? "Hanke sisu" : "Kuulutuse sisu"}</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.description}</Col>
                    </Row> : ''}
                    <Row>
                        <Col lg="3" className="lato-black size-18">Katastritunnused</Col>
                        <Col lg="9">
                            {this.state.object.cadastres.map((cadastre, idx) => {

                            return (
                                <Container fluid>
                                    <Row>
                                        <Col className="lato size-18" lg="4">Kadastritunnus</Col>
                                        <Col lg="8" className="lato size-18">{cadastre.cadastral_id}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="lato size-18" lg="4">Asukoht</Col>
                                        <Col lg="8" className="lato size-18">{cadastre.location.address}, 
                                            {cadastre.location.settlement_unit}, 
                                            {cadastre.location.municipality.name}, 
                                            {cadastre.location.municipality.county.name}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="lato size-18" lg="4">Pindala</Col>
                                        <Col lg="8" className="lato size-18">{cadastre.area}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="lato size-18" lg="4">Kaart</Col>
                                        <Col lg="8"><Image className="cadastre-image" src={cadastre.image}/></Col>
                                    </Row>
                                </Container>
                            )
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3" className="lato-black size-18">Peamised omadused</Col>
                        <Col lg="9" className="lato size-18"><ul className="highlights">{this.state.object.highlights.split('\n').map((highlight) => {
                            return(<li><img src={Highlight}/> {highlight}</li>)
                        })}</ul></Col>
                    </Row>
                    <Row>
                        <Col lg="3" className="lato-black size-18">Lisainfo</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.help}</Col>
                    </Row>
                    {this.state.object.objectType.id === 1 || this.state.object.objectType.id === 2 ? 
                    <Row>
                        <Col lg="3" className="lato-black size-18">Tehingu sõlmimise tähtaeg</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.contract_due_time} päeva</Col>
                    </Row> : '' }
                    {this.state.object.objectType.id === 1 || this.state.object.objectType.id === 2 ? 
                    <Row>
                        <Col lg="3" className="lato-black size-18">Tehingu sõlmimise koht</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.contract_locations.map((location, idx) => {
                            var comma = ","
                            if (idx === this.state.object.contract_locations.length - 1){
                                comma = ""
                            }
                            return (<span>{location.city.name}{comma} </span> )
                        })}</Col>
                    </Row> : ''}
                    {this.state.object.objectType.id === 1 || this.state.object.objectType.id === 2 ? 
                    <Row>
                        <Col lg="3" className="lato-black size-18">Tehingu sõlmimisega seotud kulude tasumine</Col>
                        <Col lg="9" className="lato size-18">{this.state.object.contract_cost.name}</Col>
                    </Row> : '' }
                    <Row>
                        <Col lg="3"></Col>
                        <Col lg="9" className="link-buttons">
                        <Link 
                            to={{
                            pathname: "/lisaobjekt",
                            state: {
                                object: this.state.object
                            }
                            }}
                            className="button-white size-18 button">Paranda</Link>
                        <Link 
                            to={{
                            pathname: "/lisaobjekt3",
                            state: {
                                object: this.state.object
                            }
                            }}
                            className="button-green size-18 button">Kinnita</Link>
                           
                        </Col>
                    </Row>
                    </Container>
                    : '' }
                </Row>

                </Container>
            </Row>


            </Container>
          </Container>

    }

}