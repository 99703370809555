import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import {Link, withRouter} from 'react-router-dom'
import RoundButton from '../components/RoundButton';
import Rater from 'react-rater'
import FormTextField from '../components/FormTextField';
import 'react-rater/lib/react-rater.css'
import { useForm, Controller } from 'react-hook-form'
import LoadingAnimation from '../components/LoadingAnimation';
import ImageUploader from "react-images-upload";


function FeedbackForm({parentCallback, offerId, title}){
    const { handleSubmit, errors, control } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [content, setContent] = useState('')
    const [qualityRate, setQualityRate] = useState(0)
    const [communicationRate, setCommunicationRate] = useState(0)
    const [timeRate, setTimeRate] = useState(0)
    const [pictures, setPictures] = useState([])
    const [serverError, setServerError] = useState('')
    const [feedbackSuccess, setFeedbackSuccess] = useState(false)

    
    const pictureDrop = picture => {
        setPictures([...pictures, picture]);
    };

    const onSubmit = values => {
        setServerError("")
        let feedbackRequest = {
            content: values.content,
            offerId: offerId,
            qualityRate: qualityRate,
            communicationRate: communicationRate,
            timeRate: timeRate,
            title: title,
        }
        
        fetch(config.get('apiUrl')+'procurementFeedback',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: JSON.stringify(feedbackRequest)
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.error) {
                setServerError(data.error)
            }
            else {
                uploadPictures(data.reference.id)
            }
        })
    }

    const uploadPictures = (referenceId) => {
        pictures.map ((file, idx) => {
            if (!file.status){
                let form = new FormData()
                form.append('document',file[0])
                form.append('size',file[0].size)
                form.append('fileName',file[0].name)
                form.append('objectId',referenceId)
                form.append('objectType',4)
                
                fetch(config.get('apiUrl')+'file', {
                headers: {
                    Authorization: 'Bearer '+ localStorage.getItem("token")
                },
                method: "POST",
                body: form,
                })
                .then(response => response.json())
                .catch(error => console.error('Error:', error))
                .then(response => {
                    console.log('Success:', response)
                    let selectedFiles = pictures
                    selectedFiles.map((item, index) => 
                        index === idx ? selectedFiles[idx] = {...item, status: true, id:response.file.id } : item
                    )
                    setPictures(pictures)
                });
            }
        })
        setFeedbackSuccess(true)
        parentCallback(true)
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>

                    {serverError !== '' ?  <Row><Col>{serverError}</Col></Row> : ''}
                    {feedbackSuccess !== false ?  <Row><Col>Pakkumine lisatud</Col></Row> : ''}

                    <Row>
                        <Col lg="3" className="lato-bold size-16">Teenuse kvaliteet</Col>
                        <Col lg="9">
                            <Controller 
                                render={({ onChange }) => (
                                    <Rater 
                                        total={5}
                                        onRate={(value) => {
                                            setQualityRate(value[0].rating)
                                            return value
                                        }}
                                        rating={qualityRate} />
                                )} 
                                name="qualityRate"
                                rules={{
                                    required:"Teenuse kvaliteedi hindamine on kohustuslik!"
                                }}
                                control={control}
                            />
                            
                        </Col>
                    </Row>
                    {errors.qualityRate && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.qualityRate.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3" className="lato-bold size-16">Kliendisuhtlus</Col>
                        <Col lg="9">
                            <Controller 
                                render={({ onChange }) => (
                                    <Rater 
                                        total={5}
                                        onRate={(value) => {
                                            setCommunicationRate(value[0].rating)
                                            return value
                                        }}
                                        rating={communicationRate} />
                                )} 
                                name="communicationRate"
                                rules={{
                                    required:"Kliendisuhtluse hindamine on kohustuslik!"
                                }}
                                control={control}
                            />
                            
                        </Col>
                    </Row>
                    {errors.qualityRate && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.communicationRate.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3" className="lato-bold size-16">Tähtaegadest kinnipidamine</Col>
                        <Col lg="9">
                            <Controller 
                                render={({ onChange }) => (
                                    <Rater 
                                        total={5}
                                        onRate={(value) => {
                                            setTimeRate(value[0].rating)
                                            return value
                                        }}
                                        rating={timeRate} />
                                )} 
                                name="timeRate"
                                rules={{
                                    required:"Tähtaegadest kinnipidamise hindamine on kohustuslik!"
                                }}
                                control={control}
                            />
                            
                        </Col>
                    </Row>
                    {errors.timeRate && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.timeRate.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3" className="lato-bold size-16">Tagasiside</Col>
                        <Col lg="9">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="content" 
                                        textarea="textarea" 
                                        rows="5"
                                        onChange={(value) => {
                                            setContent(value)
                                            return value
                                        }}
                                        labelVisible={false} 
                                        value={content}/>
                                )} 
                                name="content"
                                rules={{
                                    required:"Tagasiside sisu sisestamine on kohustuslik!"
                                }}
                                control={control}
                            />
                            
                        </Col>
                    </Row>
                    {errors.content && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.content.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3" className="lato-bold size-16">Pildid tehtud tööst</Col>
                        <Col lg="9">
                        <ImageUploader
                            withIcon={true}
                            buttonText="Vali pildid"
                            onChange={pictureDrop}
                            withPreview={true}
                            buttonClassName="button button-green size-16"
                            label="Maks. faili suurus: 5MB, lubatud faililaiendid: jpg, png"
                            imgExtension={[".jpg", ".png"]}
                            maxFileSize={5242880}
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3"></Col>
                        <Col lg="9" className="single-row-button">
                            <RoundButton title="Lisa tagasiside" color="button-green" size="size-18"/>
                            <RoundButton title="Loobu" color="button-white" size="size-18" onClick={()=>{parentCallback(false)}}/>
                        </Col>
                    </Row>
        </form>
    )
}

class ProcurementFeedback extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            object: null,
            token: localStorage.getItem("token"),
            finished: false,
            created: false

        }

        this.getOffer(this.props.match.params.uuid)
        

    }

    feedbackFromCallback = (status) => {
        if (status === false){
            this.props.history.push("../hange/"+ this.state.object.procurement.uuid)
        }
        else {
            this.setState({
                created: true
            })
        }
    }

    returnToProcurement = () => {
        this.props.history.push("../hange/"+ this.state.object.procurement.uuid)
    }

  
    getOffer = (uuid) => {
        var header = {}
        if (localStorage.getItem("token")){
            header = {headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            }
        }
        fetch(config.get('apiUrl')+'offer/'+ uuid, header)
        .then(res=>res.json())
        .then((data)=> {

            this.setState({
                object: data.object
                })

        })
    }


    render() {

        if (this.state.object == null) {
            return <LoadingAnimation style="page-loading-animation"/>
        }


        return <Container className="page-container object-page" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="back-link lato size-16">
                <Col>
                    <Link to={"/hange/"+this.state.object.procurement.uuid}>&larr; Tagasi hankele</Link>
                </Col>
            </Row>
            <Row className="page-title">
                <Col lg="9">
                    <Row className="lato-black size-40">{this.state.object.procurement.title}</Row>
                    <Row className="lato size-20">{this.state.object.procurement.cadastres[0].location.settlement_unit}, 
                                        {this.state.object.procurement.cadastres[0].location.municipality.name}, 
                                        {this.state.object.procurement.cadastres[0].location.municipality.county.name}</Row>
                </Col>
           
            </Row>
            <Row className="white-entry-box shadow">
                <Container fluid>

                <Row className="object-content-padding shadow">
                    <Col>
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Teenuse hindamine</Col>
                        </Row>
                        {this.state.created === false ? 
                        <FeedbackForm offerId={this.state.object.uuid} parentCallback={this.feedbackFromCallback} title={this.state.object.procurement.title}/>
                        :
                        <Container>
                            <Row>
                                <Col className="lato size-14">Täname tagasiside lisamise eest</Col>
                            </Row>
                            <Row>
                                <Col className="lato size-14"><RoundButton title="Sulge" color="button-green" size="size-18" onClick={()=>{this.returnToProcurement()}}/></Col>
                            </Row>
                        </Container>
                        }
                    </Col>
                    
                </Row>

                

                </Container>
            </Row>


            </Container>
          </Container>

    }

}


export default withRouter(ProcurementFeedback)