import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import FormTextField from '../components/FormTextField';
import FormTextFieldFormatted from '../components/FormTextFieldFormatted';
import RoundButton from '../components/RoundButton';
import config from 'react-global-configuration';
import { Redirect } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import AddObjectProgress from '../components/AddObjectProgress';
import FormSelect from '../components/FormSelect';
import 'react-dates/initialize';
import {DateRangePicker, isInclusivelyBeforeDay, SingleDatePicker} from 'react-dates'
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/et'
import moment from 'moment'
import MyActionsMenu from '../components/MyActionsMenu';
import ImageUploader from "react-images-upload";


function AddObjectForm({ parentCallback, object }){


    var defaultObjectType = ''
    var defaultAuctionType = ''
    var defaultType = ''
    var defaultCadastre = ''
    var defaultPrice = 0

    const lihtOksjon = `Lihtoksjoni võitja on Enampakkuja, kes pakkus Oksjonil kõrgeima hinna.`;
    const pimeOksjon = `Pimeoksjoni võitja on Enampakkuja, kes pakkus Oksjonil kõrgeima hinna. Enampakkuja on Pimeoksjoni võitja ka juhul, kui kõrgeim pakutud hind jääb alla Pimeoksjoni alghinna, kuid sellisel juhul on Müüjal üksnes õigus, aga mitte kohustus, võõrandada Oksjoni objekt Enampakkujale.`;
    const ostakoheOksjon = `„Osta kohe“ oksjoni võitja on Enampakkuja, kes pakkus „osta kohe“ hinna. Kui „osta kohe“ hinda ei paku Oksjoni jooksul ükski Enampakkuja, võidab Enampakkuja, kes pakkus kõrgeima hinna.`;

    var defaultTypes = [
        {   
            label:"Kinnistute müük",
            options:[{
                value:"1",
                label:"Terve kinnistu müük"
            },
            {
                value:"2",
                label:"Osa kinnistu müük"
            },
        ]},
        {options:[{
            value:"3",
            label:"Raietööd"
        },
        {
            value:"4",
            label:"Põllumaa rent"
        }]}]

    var procurementTypes = [
        {   
            label:"Kinnistute müük",
            options:[{
                value:"1",
                label:"Terve kinnistu müük"
            },
            {
                value:"2",
                label:"Osa kinnistu müük"
            },
        ]},
        {   
            label:"Raietööd",
            options:[{
                value:"6",
                label:"Hooldusraied"
            },
            {
                value:"7",
                label:"Metsauuendus"
            },
            {
                value:"3",
                label:"Muud raietööd"
            },
        ]},
        {options:[
        {
                value:"5",
                label:"Maakorraldus"
            },
    
        {
            value:"4",
            label:"Põllumaa rent"
        }
        ]}]



    if (object.objectType != null){
        defaultObjectType = {label:object.objectType.name,value:object.objectType.id}
        defaultAuctionType = {label:object.auctionType.name,value:object.auctionType.id}
        defaultType = {label:object.transaction_type.name,value:object.transaction_type.id}
        defaultCadastre = object.cadastres[0].cadastre_id
        defaultPrice = object.start_price
    }
    else {
        defaultCadastre = ""
        defaultAuctionType = {value:1,label:"Lihtoksjon"}
    }
    
    const { register, handleSubmit, errors, control, setValue } = useForm(
        {mode:"onSubmit",
        reValidateMode:"onSubmit",
        defaultValues: {
            cadastres: [{ value: defaultCadastre }]
          }
    })
    const fieldArrayName = 'cadastres'
    const { fields, append, remove } = useFieldArray({
        control,
        name: fieldArrayName
      });
    const [objectTypes, setObjectTypes] = useState([{value:1,label:"Oksjon"},{value:2,label:"Hange"},{value:3,label:"Kuulutus"}])
    const [objectType, setObjectType] = useState(defaultObjectType)
    const [auctionTypes, setAuctionTypes] =  useState([{value:1,label:"Lihtoksjon"},{value:2,label:"Pimeoksjon"},{value:3,label:"\"Osta kohe\" oksjon"}])
    const [auctionType, setAuctionType] = useState(defaultAuctionType)
    const [auctionTypeHelper, setAuctionTypeHelper] = useState(lihtOksjon)
    const [types, setTypes] = useState(defaultTypes)
    const [type, setType] = useState(defaultType)
    const [cadastres, setCadastres] = useState(defaultCadastre)
    const [counter, setCounter] = useState(1)
    const [price, setPrice] = useState(defaultPrice)
    const [step, setStep] = useState(100)
    const [highlights, setHighlights] = useState('')
    const [buyNowPrice, setBuyNowPrice] = useState(object.buy_now_price)
    const [startDate, setStartDate] = useState(moment(object.start_date))
    const [endDate, setEndDate] = useState(moment(object.end_date))
    const [executionStart, setExecutionStart] = useState(moment(object.execution_start))
    const [executionEnd, setExecutionEnd] = useState(moment(object.execution_end))
    const [help, setHelp] = useState(object.help)
    const [description, setDescription] = useState(object.description)
    const [focusStart, setFocusStart] = useState(false)
    const [focusEnd, setFocusEnd] = useState(false)
    const [focusExecutionStart, setFocusExecutionStart] = useState(false)
    const [focusExecutionEnd, setFocusExecutionEnd] = useState(false)
    const [error, setError] = useState('')
    const [files, setFiles] = useState([])
    const [contractDueTime, setContractDueTime] = useState(14)
    const [contractCostTypes, setContractCostTypes] = useState([{value:1,label:"Ostja"},{value:2,label:"Müüja"},{value:3,label:"Kahasse"}])
    const [contractCostType, setContractCostType] = useState()
    const [contractLocation, setContractLocation] = useState()
    const [contractLocations, setContractLocations] = useState([])

    if (contractLocations.length === 0){
        fetch(config.get('apiUrl')+'cities')
            .then(res=>res.json())
            .then((data)=> {
                setContractLocations(data.cities)
            })
    }


    const renderTooltip = (infotext) => (
        <Tooltip id="button-tooltip">
          {infotext}
        </Tooltip>
      );

    const fileDrop = newFile => { 
        console.log(newFile)
        setFiles([...files, newFile[newFile.length-1]]);
    };

    const deleteUploadFile = (idx) => {
        console.log(idx)
        let myFiles = []
        for (let i=0; i < files.length; i++){
            if (i !== idx) myFiles.push(files[i])
        }

        setFiles(myFiles)
    }

      const uploadFiles = (object, objectType, dataError) => {
        files.map ((file, idx) => {
            if (!file.status){
                let form = new FormData()
                form.append('document',file)
                form.append('size',file.size)
                form.append('fileName',file.name)
                form.append('objectId',object.id)
                form.append('objectType',objectType.value)
                
                fetch(config.get('apiUrl')+'file', {
                headers: {
                    Authorization: 'Bearer '+ localStorage.getItem("token")
                },
                method: "POST",
                body: form,
                })
                .then(response => response.json())
                .catch(error => console.error('Error:', error))
                .then(response => {
                    console.log('Success:', response)
                    let selectedFiles = files
                    selectedFiles.map((item, index) => 
                        index === idx ? selectedFiles[idx] = {...item, status: true, id:response.file.id } : item
                    )
                    setFiles(files)
                });
            }
        })
        parentCallback(object, objectType, dataError)
    }

    const onSubmit = values => {
        console.log(values)
        setError('')
        var method = "POST"
        var path = ""
        values['endDate'] = moment(values['endDate']).format("YYYY-MM-DD HH:mm:ss")
        values['startDate'] = moment(values['startDate']).format("YYYY-MM-DD HH:mm:ss")
        values['executionStart'] = moment(values['executionStart']).format("YYYY-MM-DD HH:mm:ss")
        values['executionEnd'] = moment(values['executionEnd']).format("YYYY-MM-DD HH:mm:ss")
        if (object.objectType != null){
            method = "PATCH"
            values['uuid'] = object.uuid
        }
        console.log(objectType)
        switch (objectType.value) {
            case 1: 
                path = "auction"
                break;
            case 2:
                path = "procurement"
                break;
            case 3:
                path = "classified"
                break
            default:
                path = "auction"
                break;

        }

        fetch(config.get('apiUrl')+path,{
            method: method,
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: JSON.stringify(values)
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.error){setError(data.error)}
            else {
                uploadFiles(data.object, objectType, data.error ? data.error : '')
            }
            
        })
        
        
    }
    return (
        
        <form onSubmit={handleSubmit(onSubmit)}>
                    {error !== '' ?  <Row><Col className="error">{error}</Col></Row> : ''}
                    <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">Objekti tüüp</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller
                            render={({ onChange }) => (
                                <FormSelect
                                options={objectTypes}
                                name="objectType"
                                defaultValue={objectType}
                                isClearable={false}
                                onChange={(value) => {
                                    setObjectType(value);
                                    console.log("objektitüüp")
                                    console.log(value)
                                    if (value.value === 2){
                                        setTypes(procurementTypes)
                                    }
                                    else {
                                        setTypes(defaultTypes)
                                    }
                                    setValue("objectType",value)
                                    return value
                                }}
                                placeholder="Vali objekti tüüp"
                                />
                              )} 
                            defaultValue={objectType}
                            name="objectType"
                            rules={{
                                required:"Objekti tüübi valimine on kohustuslik!"
                            }} 
                            control={control}
                        />
                        </Col>
                        <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide:400}}
                                overlay={renderTooltip("Objekti tüüp")}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                            
                        </Col>
                    </Row>
                    {errors.objectType && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.objectType.message}</Col></Row>
                            )}
                    {objectType !== '' && objectType.value === 1 ? 
                    <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">Oksjoni tüüp</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller 
                            render={({ onChange }) => (
                                <FormSelect
                                options={auctionTypes}
                                name="auctionType"
                                defaultValue={auctionType}
                                isClearable={false}
                                onChange={(value) => {
                                    setAuctionType(value);
                                    switch (value) {
                                        case 1: 
                                            setAuctionTypeHelper(lihtOksjon)
                                            break
                                        case 2: 
                                            setAuctionTypeHelper(pimeOksjon)
                                            break
                                        case 3: 
                                            setAuctionTypeHelper(ostakoheOksjon)
                                            break
                                        default: 
                                            setAuctionTypeHelper(lihtOksjon)
                                    }
                                    setValue("auctionType",value)
                                    return value
                                }}
                                placeholder="Vali oksjoni tüüp"
                                />
                            )} 
                            defaultValue={auctionType}
                            name="auctionType"
                            rules={{
                                required:"Oksjoni tüübi valimine on kohustuslik!"
                            }} 
                            control={control}
                        />
                        </Col>
                        <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide: 400}}
                                overlay={renderTooltip(auctionTypeHelper)}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                            
                        </Col>
                    </Row> : '' }
                    {errors.auctionType && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.auctionType.message}</Col></Row>
                            )}
                    <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">Tehingu liik</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormSelect
                                    options={types}
                                    name="type"
                                    defaultValue={type}
                                    placeholder="Vali tehingu liik"
                                    onChange={(value) => {setType(value)}}
                                    isClearable={false}
                                    onChange={(value) => {
                                        setType(value);
                                        setValue("type",value)
                                        return value
                                    }}
                                    />
                                )}
                                defaultValue={type}
                                name="type" 
                                rules={{
                                    required:"Tehingu liigi valimine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            </Col>
                        <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide:400}}
                                overlay={renderTooltip("Tehingu liik")}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    {errors.type && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.type.message}</Col></Row>
                            )}
                    
                                    {fields.map((cadastre, idx) => {
                                       const fieldName = `${fieldArrayName}[${idx}].name`
                                       return (
                                        <span key={idx}>
                                        <Row>
                                            {idx === 0 ? <Col sm="12" md="12" lg="3" className="lato size-18 align-top">Katastritunnus</Col> : <Col sm="12" md="12" lg="3"></Col>}
                                            <Col sm="10" md="11" lg="6">
                                                <Controller 
                                                    render={({ onChange }) => (
                                                        <FormTextField 
                                                            id={fieldName} 
                                                            labelVisible={false} 
                                                            onChange={(value) => {
                                                                setValue(fieldName,value)
                                                                return value
                                                            }}/>
                                                    )} 
                                                    name={fieldName}
                                                    defaultValue={fields[idx].name}
                                                    rules={{
                                                        required:"Kadastritunnuse sisestamine on kohustuslik!"
                                                    }} 
                                                    control={control}
                                                />
                                            </Col>
                                            {idx === 0 ? 
                                            <Col sm="2" md="1" lg="1">
                                                    <OverlayTrigger
                                                        placement="top-end"
                                                        delay={{ show: 250, hide:400}}
                                                        overlay={renderTooltip("Katastritunnus")}>
                                                        <span className="help">?</span>
                                                    </OverlayTrigger>
                                            </Col>
                                            :
                                            ''
                                                }
                                            <Col lg="3">
                                                {idx !== 0 ? <RoundButton type="button" title="Eemalda" color="button-white" size="size-16" onClick={() => {remove(idx)}} /> : ''}
                                            </Col>
                                        </Row>
                                       
                                        {errors.cadastres !== undefined  && errors.cadastres[idx] !== undefined && errors.cadastres[idx].name !== undefined && (
                                            <Row><Col lg={{offset:3}} className="error" >{errors.cadastres[idx].name.message}</Col></Row>
                                        )}
                                        </span>
                                       )
                                    })}

                     <Row>
                     <Col lg={{offset:3}}>
                         <RoundButton type="button" title="Lisa katastritunnus" color="button-green" size="size-18" onClick={() => append({})} />
                     </Col>
                     </Row>
                    {objectType !== '' && objectType.value !== 2  ?
                    <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">{objectType.value === 1 ? "Alghind" : "Hind"}</Col>
                        <Col sm="10" md="11" lg="6">
                    
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextFieldFormatted 
                                        id="price" 
                                        suffix=" €" 
                                        labelVisible={false}
                                        value={price}
                                        onChange={(value) => {
                                            setPrice(value);
                                            setValue("price",value)
                                            return value
                                        }}/>
                                )} 
                                name="price" 
                                defaultValue={price}
                                rules={{
                                    required:"Alghinna sisestamine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            </Col>
                        <Col sm="2" md="1" lg="1">
                        <OverlayTrigger
                            placement="top-end"
                            delay={{ show: 250, hide:400}}
                            overlay={renderTooltip("Hind")}>
                            <span className="help">?</span>
                        </OverlayTrigger>
                        </Col>
 
                    </Row> : ''}
                    {errors.price && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.price.message}</Col></Row>
                            )}
                    {objectType !== '' && objectType.value === 1 ? 
                    <Row>
                    <Col sm="12" md="12" lg="3" className="lato size-18">Pakkumise samm</Col>
                    <Col sm="10" md="11" lg="6">
                
                        <Controller 
                            render={({ onChange }) => (
                                <FormTextFieldFormatted 
                                    id="step" 
                                    suffix=" €" 
                                    labelVisible={false}
                                    value={step}
                                    onChange={(value) => {
                                        setStep(value);
                                        setValue("step",value)
                                        return value
                                    }}
                                    />)} 
                            name="step" 
                            defaultValue={step}
                            rules={{
                                required:"Pakkumise sammu sisestamine on kohustuslik!"
                            }} 
                            control={control}
                        />
                        </Col>
                    <Col sm="2" md="1" lg="1">
                    <OverlayTrigger
                        placement="top-end"
                        delay={{ show: 250, hide:400}}
                        overlay={renderTooltip("Pakkumise samm")}>
                        <span className="help">?</span>
                    </OverlayTrigger>
                    </Col>
                    {errors.step && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.step.message}</Col></Row>
                            )}
                </Row> : ''}

                    {objectType !== '' && objectType.value === 1  && auctionType.value === 3 ? 
                    <Row>
                    <Col sm="12" md="12" lg="3" className="lato size-18">"Osta kohe" hind</Col>
                    <Col sm="10" md="11" lg="6">
                
                        <Controller 
                            render={({ onChange }) => (
                                <FormTextFieldFormatted 
                                    id="buyNowPrice"
                                    suffix=" €" 
                                    labelVisible={false}
                                    value={buyNowPrice}
                                    onChange={(value) => {
                                        setBuyNowPrice(value);
                                        setValue("buyNowPrice",value)
                                        return value
                                    }}/>
                            )} 
                            name="buyNowPrice" 
                            defaultValue={buyNowPrice}
                            rules={{
                                required:"Osta kohe hinna sisestamine on kohustuslik!"
                            }} 
                            control={control}
                        />
                        </Col>
                    <Col sm="2" md="1" lg="1">
                    <OverlayTrigger
                        placement="top-end"
                        delay={{ show: 250, hide:400}}
                        overlay={renderTooltip("Hind")}>
                        <span className="help">?</span>
                    </OverlayTrigger>
                    </Col>
                    {errors.buyNowPrice && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.buyNowPrice.message}</Col></Row>
                            )}
                </Row> : ''}
                    <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">Eelistatud alguskuupäev</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller 
                                render={({ onChange }) => (
                                    <SingleDatePicker 
                                        id="startDate"
                                        name="startDate"
                                        numberOfMonths={1}
                                        firstDayOfWeek={1}
                                        onDateChange={(value) => {
                                            if (endDate.isBefore(value)){
                                                setEndDate(value)
                                            }
                                            setStartDate(value);
                                            setValue("startDate",value)
                                            return value
                                        }}
                                        onFocusChange={(focused) => setFocusStart(focused.focused) }
                                        focused={focusStart}
                                        placeholder=""
                                        displayFormat="DD.MM.YYYY"
                                        initialDate={startDate}
                                        value={startDate}
                                        hideKeyboardShortcutsPanel={true}
                                        date={startDate}/>
                                )} 
                                name="startDate"
                                value={startDate}
                                defaultValue={startDate}
                                rules={{
                                    required:"Eelistatud alguskuupäeva sisestamine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            </Col>
                        <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide:400}}
                                overlay={renderTooltip("Alguskuupäev")}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                        </Col>
                    </Row> 
                    {errors.startDate && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.startDate.message}</Col></Row>
                            )}
                    <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">Eelistatud lõppkuupäev</Col>
                        <Col sm="10" md="11" lg="6">
                    
                            <Controller 
                                render={({ onChange }) => (<SingleDatePicker 
                                    id="endDate" 
                                    numberOfMonths={1}
                                    firstDayOfWeek={1}
                                    onDateChange={(value) => {
                                        setEndDate(value);
                                        setValue("endDate",value)
                                        return value
                                    }}
                                    onFocusChange={(focused) => setFocusEnd(focused.focused)}
                                    focused={focusEnd}
                                    placeholder=""
                                    isOutsideRange={day=>
                                     isInclusivelyBeforeDay(day,startDate)}
                                    displayFormat="DD.MM.YYYY"
                                    hideKeyboardShortcutsPanel={true}
                                    date={endDate}/>
                                )} 
                                name="endDate" 
                                defaultValue={endDate}
                                rules={{
                                    required:"Eelistatud lõppkuupäeva sisestamine on kohustuslik!"
                                }}
                                control={control}
                            />
                            </Col>
                            <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide:400}}
                                overlay={renderTooltip("Lõppkuupäev")}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    {errors.endDate && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.endDate.message}</Col></Row>
                            )}
                     {objectType !== '' && objectType.value === 2  ?
                     <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">Tööde soovitud algusaeg</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller 
                                render={({ onChange }) => (<SingleDatePicker 
                                    id="executionStart"
                                    name="executionStart"
                                    numberOfMonths={1}
                                    firstDayOfWeek={1}
                                    onDateChange={(value) => {
                                        if (executionEnd.isBefore(value)){
                                            setExecutionEnd(value)
                                        }
                                        setExecutionStart(value);
                                        setValue("executionStart",value)
                                        return value
                                    }}
                                    onFocusChange={(focused) => setFocusExecutionStart(focused.focused) }
                                    focused={focusExecutionStart}
                                    placeholder=""
                                    displayFormat="DD.MM.YYYY"
                                    initialDate={executionStart}
                                    value={executionStart}
                                    hideKeyboardShortcutsPanel={true}
                                    date={executionStart}/>
                                )} 
                                name="executionStart"
                                value={executionStart}
                                defaultValue={executionStart}
                                rules={{
                                    required:"Tööde soovitud algusaja sisestamine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            </Col>
                            <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide:400}}
                                overlay={renderTooltip("Algusaeg")}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                        </Col>
                    </Row> : '' }
                    {errors.executionStart && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.executionStart.message}</Col></Row>
                            )}
                    {objectType !== '' && objectType.value === 2  ?
                     <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">Tööde soovitud lõppaeg</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller 
                                render={({ onChange }) => (<SingleDatePicker 
                                    id="executionEnd"
                                    name="executionEnd"
                                    numberOfMonths={1}
                                    firstDayOfWeek={1}
                                    onDateChange={(value) => {
                                        setExecutionEnd(value);
                                        setValue("executionEnd",value)
                                        return value
                                    }}
                                    onFocusChange={(focused) => setFocusExecutionEnd(focused.focused) }
                                    focused={focusExecutionEnd}
                                    placeholder=""
                                    displayFormat="DD.MM.YYYY"
                                    isOutsideRange={day=>
                                        isInclusivelyBeforeDay(day,executionStart)}
                                    value={executionEnd}
                                    hideKeyboardShortcutsPanel={true}
                                    date={executionEnd}/>
                                )} 
                                name="executionEnd"
                                value={executionEnd}
                                defaultValue={executionEnd}
                                rules={{
                                    required:"Tööde soovitud lõppaja sisestamine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            </Col>
                            <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide:400}}
                                overlay={renderTooltip("Lõppaeg")}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                        </Col>
                    </Row> : '' }
                    {errors.executionEnd && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.executionEnd.message}</Col></Row>
                            )}
                     {objectType !== '' && (objectType.value === 2  || objectType.value === 3) ? 
                     <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18 align-top">{objectType.value === 2 ? "Hanke sisu" : "Kuulutuse sisu"}</Col>
                        <Col sm="10" md="11" lg="6">
                        <Controller 
                            render={({ onChange }) => (
                                <FormTextField 
                                    id="description" 
                                    textarea="textarea" 
                                    rows="5"
                                    onChange={(value) => {
                                        setDescription(value);
                                        setValue("description",value)
                                        return value
                                    }}
                                    labelVisible={false} 
                                    value={description}/>
                            )} 
                            defaultValue={description}
                            name="description" 
                            rules={{
                                required:"Sisu sisestamine on kohustuslik!"
                            }} 
                            control={control}
                        />
                        </Col>
                        <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide:400}}
                                overlay={renderTooltip("Kirjeldus")}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                        </Col>
 
                    </Row> : '' }
                    {errors.description && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.description.message}</Col></Row>
                            )}
                    <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18 align-top">Peamised omadused</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="highlights" 
                                        textarea="textarea" 
                                        rows="5"
                                        onChange={(value) => {
                                            setHighlights(value);
                                            setValue("highlights", value)
                                            return value
                                        }}
                                        labelVisible={false} 
                                        value={highlights}/>
                                )} 
                                name="highlights"
                                defaultValue={highlights}
                                control={control}
                            />
                            </Col>
                        <Col sm="2" md="1" lg="1">
                        <OverlayTrigger
                            placement="top-end"
                            delay={{ show: 250, hide:400}}
                            overlay={renderTooltip("Peamised omadused")}>
                            <span className="help">?</span>
                        </OverlayTrigger>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18 align-top">Lisainfo</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="help" 
                                        textarea="textarea" 
                                        rows="5"
                                        onChange={(value) => {
                                            setHelp(value);
                                            setValue("help",value)
                                            return value
                                        }}
                                        labelVisible={false} 
                                        value={help}/>
                                )} 
                                name="help"
                                defaultValue={help}
                                control={control}
                            />
                            </Col>
                        <Col sm="2" md="1" lg="1">
                        <OverlayTrigger
                            placement="top-end"
                            delay={{ show: 250, hide:400}}
                            overlay={renderTooltip("Lisainfo")}>
                            <span className="help">?</span>
                        </OverlayTrigger>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3" className="lato size-18 align-top">Lisainfo failidena</Col>
                        <Col lg="6">
                        <ImageUploader
                            withIcon={true}
                            buttonText="Vali failid"
                            onChange={fileDrop}
                            accept=""
                            buttonClassName="button button-green size-16"
                            label="Maks. faili suurus: 5MB, lubatud faililaiendid: pdf, docx"
                            imgExtension={[".pdf", ".docx"]}
                            maxFileSize={5242880}
                        />
                        </Col>
                        <Col sm="2" md="1" lg="1">
                        <OverlayTrigger
                            placement="top-end"
                            delay={{ show: 250, hide:400}}
                            overlay={renderTooltip("Nt. metsamajanduskava")}>
                            <span className="help">?</span>
                        </OverlayTrigger>
                        </Col>
                    </Row>

                    {
                        files.map((file, idx) => {
                            console.log(file)
                            return (
                                    <Row className="detail-documentation size-14">
                                        <Col lg="3"></Col>
                                        <Col lg="1">{file.type === 'application/pdf' ? <Image src={require("./../assets/pdf.png")}/> : <Image src={require("./../assets/word.png")}/>}</Col>
                                        <Col>{file.name} <span className="delete" onClick={()=>deleteUploadFile(idx)}>x</span></Col>
                                    </Row>
                                
                                )
                         })
                        }

                    {objectType !== '' && (objectType.value === 1  || objectType.value === 2) ? 
                    <Row>
                    <Col sm="12" md="12" lg="3" className="lato size-18">Tehingu sõlmimise tähtaeg (päevades)</Col>
                    <Col sm="10" md="11" lg="6">
                
                        <Controller 
                            render={({ onChange }) => (
                                <FormTextField 
                                    id="contractDueTime"
                                    value={contractDueTime}
                                    onChange={(value) => {
                                        setContractDueTime(value);
                                        setValue("contractDueTime",value)
                                        return value
                                    }}
                                    labelVisible={false} />
                            )} 
                            name="contractDueTime" 
                            defaultValue={contractDueTime}
                            rules={{
                                required:"Tehingu sõlmimise tähtpäeva sisestamine on kohustuslik!"
                            }} 
                            control={control}
                        />
                        </Col>
                    <Col sm="2" md="1" lg="1">
                    <OverlayTrigger
                        placement="top-end"
                        delay={{ show: 250, hide:400}}
                        overlay={renderTooltip("Tehingu sõlmimise tähtaeg päevades")}>
                        <span className="help">?</span>
                    </OverlayTrigger>
                    </Col>
                    {errors.contractDueTime && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.contractDueTime.message}</Col></Row>
                            )}
                </Row> : ''}
                {objectType !== '' && (objectType.value === 1 || objectType.value === 2) ? 
                <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">Tehingu sõlmimise koht</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormSelect
                                    options={contractLocations}
                                    name="contractLocation"
                                    multiple={true}
                                    placeholder="Vali asukohad"
                                    onChange={(value) => {
                                        setContractLocation(value)
                                        setValue("contractLocation",value)
                                    }}
                                    isClearable={false}
                                    />
                                )}
                                defaultValue={contractLocation}
                                name="contractLocation"
                                rules={{
                                    required:"Tehingu sõlmimise koha valimine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            </Col>
                        <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide:400}}
                                overlay={renderTooltip("Sobilikud tehingu sõlmimise kohad")}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                        </Col>
                    </Row> : '' }
                    {errors.contractLocation && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.contractLocation.message}</Col></Row>
                            )}
                {objectType !== '' && (objectType.value === 1 || objectType.value === 2) ? 
                <Row>
                        <Col sm="12" md="12" lg="3" className="lato size-18">Tehingu sõlmimisega seotud kulude tasumine</Col>
                        <Col sm="10" md="11" lg="6">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormSelect
                                    options={contractCostTypes}
                                    name="contractCostType"
                                    placeholder="Kes tasub kulud?"
                                    onChange={(value) => {
                                        setContractCostType(value)
                                        setValue("contractCostType",value)
                                    }}
                                    isClearable={false}
                                    />
                                )}
                                defaultValue={contractCostType}
                                name="contractCostType"
                                rules={{
                                    required:"Tehingu sõlmimisega seotud kulude tasumise valimine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            </Col>
                        <Col sm="2" md="1" lg="1">
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 250, hide:400}}
                                overlay={renderTooltip("Kes tasub tehingu sõlimisega seotud kulud?")}>
                                <span className="help">?</span>
                            </OverlayTrigger>
                        </Col>
                    </Row> : '' }
                    {errors.contractCostType && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.contractCostType.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3"></Col>
                                <Col lg="6">
                            
                                    <RoundButton title="Edasi" color="button-green" size="size-18" onClick={() => console.log(objectType.id)} />
                                </Col>
                    </Row>
        </form>
    )

}

export default class AddObject extends React.Component {

    constructor(props){
        super(props)

        var existingObject = {}

        if (this.props.location.state != null){
                existingObject = this.props.location.state.object
        }
        console.log(this.props.location.state)

        this.state = {
            existingObject: existingObject,
            objectId: null,
            objectType: null,
            error: '',
            step: 0
        }

    }

    callback = (object, objectType, error) => {
        console.log("tere")
        console.log(object)
        console.log(error)
        this.setState({
            object: object, 
            objectType: objectType,
            error: error
        })
    }

    render() {

        if (!localStorage.getItem("token")){
            return <Redirect to={{
                pathname: "/logisisse",
              }}/>
        }

    
        if (this.state.error === '' && this.state.object) {
            return <Redirect to={{
                pathname: "/lisaobjekt2",
                state: { objectId: this.state.object.uuid, objectType: this.state.objectType }
              }}/>
        }

        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Lisa objekt</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">
                <Container fluid>
                    <MyActionsMenu step={this.state.step} type={2}/>
                </Container>
                <Container fluid>
                <Row className="box-content-padding">
                    <Container fluid>
                    <AddObjectForm parentCallback={this.callback} object={this.state.existingObject}/>
                    </Container>
                </Row>

                </Container>
            </Row>


            </Container>
          </Container>

    }

}