import React from 'react';
import ListItemHeader from '../components/ListItemHeader'
import ListItemBody from './ListItemBody';
import ListItemFooter from './ListItemFooter';


export default class ListItem extends React.Component {


    render() {
      return <div className="list-item" onClick={this.props.onClick}>
          <ListItemHeader image={this.props.image} type={this.props.type}/>
          <ListItemBody title={this.props.title} location={this.props.location} cadastrenumber={this.props.cadastrenumber} area={this.props.area} price={this.props.price} type={this.props.objectType}/>
          <ListItemFooter favourite={this.props.favourite} time={this.props.time} type={this.props.objectType} status={this.props.status}/>
        </div>;
      ;
    }
  }