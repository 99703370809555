import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormTextField from '../components/FormTextField';
import RoundButton from '../components/RoundButton';
import PinModal from '../components/PinModal'
import { MobileIdAuthentication } from '../components/MobileIdAuthentication'
import { SmartIdAuthentication } from '../components/SmartIdAuthentication'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import config from 'react-global-configuration';
import { useForm, Controller } from 'react-hook-form'
import CustomLink from '../components/CustomLink';
import FormTextFieldFormatted from '../components/FormTextFieldFormatted';
import TableItem from '../components/TableItem';
import LoadingAnimation from '../components/LoadingAnimation';


function CodeValidation({parentCallback, type}) {

    const { handleSubmit, errors, control, setError } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [validationCode, setValidationCode] = useState('')
    
    const onSubmit = values => {

        var requestUrl = ""
        console.log(type)
        if (type === 1) {
            requestUrl = "verifyPhoneCode"
        }
        else {
            requestUrl = "verifyEmailCode"
        }
        fetch(config.get('apiUrl')+requestUrl,{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: JSON.stringify(values)
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.status === 'success'){
                parentCallback(type, 1)
            }
            else {
                setError("validationCode","notMatch","Sisestatud kinnituskood on vigane")
            }
        })
    }

    return  <form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mt-3">
                        <Col lg="5">
                        <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="validationCode" 
                                        label="Kinnituskood" 
                                        labelVisible={true}
                                        onChange={(value) => {
                                            setValidationCode(value)
                                            return value
                                        }}
                                        value={validationCode}/>
                                )} 
                                name="validationCode"
                                rules={{
                                    required:"Kinnituskoodi sisestamine on kohustuslik",
                                }} 
                                control={control}
                            />
                            
                        </Col>
                        </Row>
                    {errors.validationCode && (
                                <Row><Col className="error">{errors.validationCode.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg={{span:8}}><RoundButton title="Valideeri" color="button-green" size="size-18"/></Col>
                    </Row>
        </form>
}

function UserDetails({parentCallback, user}){
    const { handleSubmit, errors, control, setError,reset } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [email, setEmail] = useState(user.email)
    const [phone, setPhone] = useState(user.phone)

    if (email !== user.email){
        setEmail(user.email)
        reset({
            email:user.email
        })
    }

    if (phone !== user.phone){
        setPhone(user.phone)
        reset({
            phone:user.phone
        })
    }


    const onSubmit = values => {


        fetch(config.get('apiUrl')+"user",{
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: JSON.stringify(values)
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            if (!data.user.email_verified && !data.user.phone_verified){
                parentCallback(3,0)
            }
            else if (!data.user.email_verified){
                parentCallback(2,0)
            }
            else if (!data.user.phone_verified){
                parentCallback(1,0)
            }
        })
    }

    return  <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                        <Col lg="4" className="lato size-18">
                            <FormTextField id="firstName" label="Eesnimi" labelVisible={true} disabled={true} value={user.first_name}/>
                        </Col>
                        <Col lg="4" className="lato size-18">
                            <FormTextField id="surname" label="Perenimi" labelVisible={true} disabled={true} value={user.surname}/>
                        </Col>
                        <Col lg="4" className="lato size-18">
                            <FormTextField id="idCode" label="Isikukood" labelVisible={true} disabled={true} value={user.id_code}/>
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                        <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="email" 
                                        label="Email" 
                                        value={email}
                                        onChange={(value) => {
                                            setEmail(value)
                                            return value
                                        }}
                                        labelVisible={true}/>
                                )} 
                                name="email"
                                defaultValue={email}
                                rules={{
                                    required:"E-posti aadressi sisestamine on kohustuslik",
                                }} 
                                control={control}
                            />
                            {errors.email && (
                               <span className="error">{errors.email.message}</span>
                            )}
                        </Col>
                        <Col lg="4">
                        <Controller 
                                render={({ onChange }) => (
                                    <FormTextFieldFormatted 
                                        id="phone" 
                                        label="Telefon" 
                                        type="text"
                                        value={phone}
                                        onChange={(value) => {
                                            setPhone(value)
                                            return value
                                        }}
                                        prefix="+372 " 
                                        labelVisible={true}/>
                                )} 
                                name="phone" 
                                defaultValue={phone}
                                rules={{
                                    required:"Telefoninumbri sisestamine on kohustuslik",
                                }} 
                                control={control}
                            />
                            {errors.phone && (
                               <span className="error">{errors.phone.message}</span>
                            )}
                        </Col>
                        
                        <Col lg="4">
                            <RoundButton title="Muuda andmeid" color="button-green" size="size-18"/>
                        </Col>
                        </Row>
                    {errors.validationCode && (
                                <Row><Col className="error" lg={{offset:4}}>{errors.validationCode.message}</Col></Row>
                            )}
        </form>
}

function ChangePassword(){
    const { handleSubmit, errors, control, setError, getValues } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [password, setPassword] = useState()
    const [passwordNew, setPasswordNew] = useState()
    const [passwordNewConfirm, setPasswordNewConfirm] = useState()
    
    const onSubmit = values => {


        fetch(config.get('apiUrl')+"changePassword",{
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: JSON.stringify(values)
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            if (data.status === 'success'){
                //parentCallback(values)
            }
            else {
                setError("password","notMatch","Kehtiv salasõna on vigane")
            }
        })
    }

    return  <form onSubmit={handleSubmit(onSubmit)}>
 
                        <Row>
                        <Col lg="4">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="password" 
                                        label="Kehtiv salasõna"
                                        onChange={(value) => {
                                            setPassword(value)
                                            return value
                                        }}
                                        labelVisible={true}/>
                                )} 
                                name="password"
                                type="password"
                                rules={{
                                    required:"Kehtiva salasõna sisetamine on kohustuslik!",
                                }} 
                                control={control}
                            />
                            {errors.password && (
                               <span className="error">{errors.password.message}</span>
                            )}
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="passwordNew" 
                                        label="Uus salasõna"
                                        onChange={(value) => {
                                            setPasswordNew(value)
                                            return value
                                        }}
                                        labelVisible={true}/>
                                )} 
                                name="passwordNew"
                                type="password"
                                rules={{
                                    required:"Uue salasõna sisetamine on kohustuslik!",
                                }} 
                                control={control}
                            />
                            {errors.passwordNew && (
                               <span className="error">{errors.passwordNew.message}</span>
                            )}
                        </Col>
                        <Col lg="4">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="passwordNewConfirm" 
                                        label="Korda uut salasõna"
                                        onChange={(value) => {
                                            setPasswordNewConfirm(value)
                                            return value
                                        }}
                                        labelVisible={true}/>
                                )} 
                                name="passwordNewConfirm"
                                type="password"
                                rules={{
                                    validate: value => {return(value === getValues("passwordNew") || 'Sisestatud salasõnad pole ühesugused')}
                                }} 
                                control={control}
                            />
                            {errors.passwordNewConfirm && (
                               <span className="error">{errors.passwordNewConfirm.message}</span>
                            )}
                        </Col>
                        <Col lg="4">
                            <RoundButton title="Muuda salasõna" color="button-green" size="size-18"/>
                        </Col>
                        </Row>
                    
        </form>
}

class MyAccount extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            modal: false,
            modalTitle: "",
            user: {},
            companies: [],
            verificationCode: "",
            loginError: "",
            authenticationHash: "",
            phoneVerificationInProgress: false,
            emailVerificationInProgress: false,
        }
        if (localStorage.getItem("token")){
            console.log(localStorage.getItem("token"))
            this.getUser()
            this.getCompanies()
        }

    }

    getUser = () => {
        fetch(config.get('apiUrl')+'user',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then((json) => {

                this.setState({
                    user: json.user
                })
            })
    }

    getCompanies = () => {
        fetch(config.get('apiUrl')+'companies',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then((json) => {

                var myCompanies = []
                json.companies.map((company) => 
                    myCompanies.push({
                        'uuid':company.uuid,
                        'content':[company.name,company.registration_number, company.kmkr === 'undefined' ? '' : company.kmkr, company.representative_type.name,company.status.title]
                    })
                )

                this.setState({
                    companies: myCompanies
                })
            })
    }

    initiateValidation = (type) => {
        var requestUrl = ""
        if (type === 1){
            requestUrl = "verifyPhone"
        }
        else {
            requestUrl = "verifyEmail"
        }

        fetch(config.get('apiUrl')+requestUrl,{
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.status === 'success'){
                if (type === 1){
                    this.setState({
                        phoneVerificationInProgress: true
                    })
                }
                else {
                    this.setState({
                        emailVerificationInProgress: true
                    })
                }
            }
        })
    }

    validationCallback = (type, status) => {
        if (status === 1){
            this.getUser()
        }
        var user = this.state.user
        if (type === 1){
            user.phone_verified = status
            this.setState({
                user: user
            })
            if (status === 0){
                this.setState({
                    phoneVerificationInProgress:false
                })
            }
        }
        else if (type === 2) {
            user.email_verified = status
            this.setState({
                user: user
            })
            if (status === 0){
                this.setState({
                    emailVerificationInProgress:false
                })
            }
        }
        else if (type === 3) {
            user.email_verified = status
            user.phone_verified = status
            this.setState({
                user: user,
                emailVerificationInProgress:false,
                phoneVerificationInProgress:false
            })
        }

        
    }


    firstAuthenticationHandler = (values) => {

        this.setState({
            modal:values.modal,
            modalTitle: values.modalTitle,
            verificationCode: values.verificationCode,
            loginError: values.loginError,
            authenticationHash: values.authenticationHash
        })
    }

    secondAuthenticationHandler = (values) => {
        var user = this.state.user
        user.user_verified = values.userVerified

        this.setState({
            modal:values.modal,
            modalTitle: values.modalTitle,
            verificationCode: values.verificationCode,
            loginError: values.loginError,
            user: user 
        })
    }

    render() {

        if (!localStorage.getItem("token")){
                return <Redirect to={{
                    pathname: "/logisisse",
                  }}/>
        }

        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Tere, {this.props.user.first_name}</Row>
                </Col>
            </Row>
                { this.state.user_verified === 0 || this.state.user.email_verified === 0 || this.state.user.phone_verified === 0 ?
            <Row className="white-entry-box shadow" style={{backgroundRepeat:"no-repeat", backgroundSize:"63%", backgroundImage:`url(${require("./../assets/white_background.svg")})`}}>
                    <Container className="account verification" fluid>
                    <Row>
                        <Col lg="7" className="verification-intro">
                            <Container>
                                <Row>
                                    <Col className="lato-bold size-30 highlight-color sub-title">Täname registreerumast!</Col>
                                </Row>
                                {this.state.user.status === 0 ?
                                    <Row>
                                        <Col>
                                            Teie konto on hetkel meie halduri poolt ülevaatamisel. Meie haldur võtab Teiega ühendust juba 1 tööpäeva jooksul, et selgitada portaali toimimist ja aidata Teil Maahange.ee lehelt võtta maksimum!
                                        </Col>
                                    </Row>
                                    : ''}
                                {this.state.user.user_verified === 0  || this.state.user.email_verified === 0 || this.state.user.phone_verified === 0 ?
                                    <Row>
                                        <Col>
                                            Enne portaali kasutamist peate tuvastama oma isiku ID-kaardi, Mobiil-ID või Smart-ID vahendusel ning kinnitama registreerimisel sisestatud e-posti aadressi ja telefoninumbri.
                                        </Col>
                                    </Row> : 
                                    ''
                                }
                            </Container>
                            
                        </Col>
                        <Col lg="5">
                        <Container fluid>
                            <Row>
                                {this.state.user.user_verified === 0 ?
                                <Col>
                                    <Row>
                                        <Col className="lato-bold size-30 highlight-color sub-title">Isikutuvastamine</Col>
                                    </Row>
                                    <Row>
                                        <Col>Mobiil-ID ja Smart-ID korral kasutatakse tuvastamisel telefonina registreerimise käigus sisestatud mobiilinumbrit.</Col>
                                    </Row>
                                    <Row>
                                        <Col><SmartIdAuthentication login={false} firstCallback={this.firstAuthenticationHandler} secondCallback={this.secondAuthenticationHandler}/></Col>
                                </Row>
                                <Row>
                                    <Col><MobileIdAuthentication login={false} firstCallback={this.firstAuthenticationHandler} secondCallback={this.secondAuthenticationHandler}/></Col>  
                                </Row>
                                <Row>
                                <Col><RoundButton title="ID&#8209;kaart" color="button-green" size="size-16"/></Col>
                                </Row>
                                </Col>
                                :
                                <Col>
                                    <Row>
                                        <Col className="lato-bold size-30 highlight-color sub-title">Isikutuvastamine</Col>
                                    </Row>
                                    <Row>
                                        <Col>Täname! Teie isik on tuvastatud.</Col>
                                    </Row>
                                </Col>
                            }
                            </Row>
                        </Container>
                        </Col>
                        </Row>

                    <Row>
                    <Col className="lato-bold size-30 highlight-color sub-title">Kontaktandmete kinnitamine</Col>
                    </Row>
                    <Row className="contact-verification">
                    <Col>
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">E-posti aadressi kinnitamine</Col>
                        </Row>
                        {this.state.user.email_verified === 0 ?
                        <span>
                            {this.state.emailVerificationInProgress === false ?
                            <span>
                                <Row>
                                    <Col lg="8" className="lato size-18">E-posti aadressi kinnitamiseks pead saatma oma e-posti aadressile kinnituskoodi.</Col>
                                    <Col lg="4"><RoundButton title="Saada kinnituskood" color="button-white" size="size-18" onClick={()=>{this.initiateValidation(2)}}/></Col>
                                </Row>
                            </span>
                            :
                            <span>
                                <Row>
                                    <Col className="lato size-18">Teie e-posti aadressile on saadetud kinnituskood. Sisestage see palun allpool olevale väljale.</Col>
                                </Row>
                                <Row>
                                <Col>
                                        <CodeValidation parentCallback={this.validationCallback} type={2}/>
                                   </Col>
                                </Row>
                                <Row>
                                    <Col className="lato size-16 clickable mt-3" onClick={()=>{this.initiateValidation(2)}}>Pole koodi? Saada kood uuesti!</Col>
                                </Row>
                            </span>
                            }
                        </span> 
                        :
                        <Row>
                            <Col className="lato size-18">Täname! Teie e-posti aadress on kinnitatud</Col>
                        </Row>
                        }
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title areas-heading">Mobiilinumbri kinnitamine</Col>
                        </Row>
                        {this.state.user.phone_verified === 0 ?
                            <span>
                                {this.state.phoneVerificationInProgress === false ?
                                <span>
                                <Row>
                                    <Col lg="8" className="lato size-18">Mobiilinumbri kinnitamiseks pead saatma oma mobiilinumbrile kinnituskoodi.</Col>
                                    <Col lg="4"><RoundButton title="Saada kinnituskood" color="button-white" size="size-18" onClick={()=>{this.initiateValidation(1)}}/></Col>
                                </Row>
                                </span>
                                :
                                <span>
                                <Row>
                                    <Col className="lato size-18">Teie mobiilile on saadetud kinnituskood. Sisestage see palun allpool olevale väljale.</Col>
                                </Row>
                                <Row>
                                <Col>
                                        <CodeValidation parentCallback={this.validationCallback} type={1}/>
                                   </Col>
                                </Row>
                                <Row>
                                    <Col className="lato size-16 clickable mt-3" onClick={()=>{this.initiateValidation(1)}}>Pole koodi? Saada kood uuesti!</Col>
                                </Row>
                                </span>
                            }
                            </span>
                            :
                            <Row>
                            <Col className="lato size-18">Täname! Teie telefoninumber on kinnitatud</Col>
                        </Row>
                        }
                        </Col>
                        </Row>
                        </Container>
                        </Row> : 
 
                        <Row className="white-entry-box shadow">
                        <Container fluid>
                            
                            <Row>
                        
                        <Container className="account shadow" fluid>
                        {this.state.user.status === 0 ?
                                    <Row>
                                        <Col>
                                            Teie konto on hetkel meie halduri poolt ülevaatamisel. Meie haldur võtab Teiega ühendust juba 1 tööpäeva jooksul, et selgitada portaali toimimist ja aidata Teil Maahange.ee lehelt võtta maksimum!
                                        </Col>
                                    </Row>
                                    : ''}
                            <Row>
                                <Col className="lato-bold size-30 highlight-color sub-title">Isikuandmed</Col>
                            </Row>
                            <UserDetails parentCallback={this.validationCallback} user={this.state.user}/>
                        </Container>

                        <Container className="account shadow" fluid>
                            <Row>
                                <Col className="lato-bold size-30 highlight-color sub-title">Muuda salasõna</Col>
                            </Row>
                            <ChangePassword/>
                        </Container>

                        <Container className="account" fluid>
                            <Row>
                                <Col className="lato-bold size-30 highlight-color sub-title">Minuga seotud ettevõtted</Col>
                            </Row>
                            {this.state.companies.length === 0 ?  
                                <Row>
                                    <Col className="informative-text">Seotud ettevõtted puuduvad</Col>
                                </Row>
                                : 
                                <TableItem header={['Nimi','Reg. number','Kmkr nr.','Minu seos','Staatus']} link={false} entities={this.state.companies} entityType="ettevõte"/>
                            }

                            <Row>
                                <Col lg="4">
                                <CustomLink
                                    tag='span'
                                    to="/lisaettevote"
                                    >
                                        <RoundButton title="Lisa uus" color="button-green" size="size-18"/>
                                    </CustomLink>
                                    
                                </Col>
                            </Row>
                            
                        </Container>
                        </Row>
                        </Container>
                        </Row>
                    
                    }
                    

            <PinModal 
                show={this.state.modal}
                onHide={() => this.setState({modal:false})}
                title={this.state.modalTitle}
                verificationcode={this.state.verificationCode}
                errormessage={this.state.loginError}
            />
            </Container>
          </Container>

    }
}

function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(MyAccount)
