import React, { useState, memo } from 'react';
import { useForm, Controller } from 'react-hook-form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import FormTextField from '../components/FormTextField';
import config from 'react-global-configuration';
import RoundButton from '../components/RoundButton';
import { store } from '../store'

export const SmartIdAuthentication = memo(props => {

    const { handleSubmit, errors, control, setValue } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [idCode, setIdCode] = useState('')

    const  getUser = () =>{
        console.log("get user")
        return function(dispatch) {
            return fetch(config.get('apiUrl')+'user',{
                headers: {
                    Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            })
                .then(response => response.json())
                .then(json => {
                    dispatch({type:"STORE_USER",payload: json.user})
                })
        }
    }

    const authenticate = values => {
        
        var authenticateRequest = ""
        var headers = {
            'Content-Type' : 'application/json'
        }
        if (localStorage.getItem("token")){
            headers.Authorization = 'Bearer '+ localStorage.getItem("token")
        }
        else {
            authenticateRequest = {
                idCode: values.idCode
            }
        }
        fetch(config.get('apiUrl')+'sidAuthenticate',{
        method: 'POST',
        headers,
        body: JSON.stringify(authenticateRequest)
    })
    .then(res=>res.json())
    .then((data)=> {
        let verificationCode = data.verificationCode
        let myAuthenticationHash = data.authenticationHash
        let loginError = ""
        let sessionId = data.sessionId
        console.log(sessionId)
        if (data.message){
            loginError = data.message
        }
        props.firstCallback({
            modal:true,
            modalTitle: "Smart-ID",
            verificationCode: verificationCode,
            loginError: loginError,
            authenticationHash: myAuthenticationHash
        })

        if (data.message === undefined) {
        let interval = setInterval(() => {

            if (props.login) {

                    let loginRequest = {
                        grant_type:"smartId",
                        client_id:"2",
                        client_secret:"LmoHbyXNYqTVGDawn04yRq1GWXwVbNBmRsFhSEXZ",
                        sessionId: sessionId,
                        authenticationHash: myAuthenticationHash,
                        idCode: values.idCode
                    }
                    
                    fetch(config.get('apiUrl')+'oauth/token',{
                        method: 'POST',
                        headers,
                        body: JSON.stringify(loginRequest)
                    })
                    .then(res=>res.json())
                    .then((data)=> {

                        if (data.error) {
                            const message = data.message.split(/:/)
                            if (message[1] !== " Authentication in progress"){
                                props.secondCallback({
                                    modal:true,
                                    modalTitle: "Smart-ID",
                                    verificationCode: "",
                                    loginError: message[1],
                                    userVerified: false
                                })
                                clearInterval(interval)
                            }
                        }
                        else {
                            localStorage.setItem("token",data.access_token)
                            store.dispatch(getUser())
                            props.secondCallback({
                                modal:false,
                                modalTitle: "Smart-ID",
                                verificationCode: "",
                                loginError: "",
                                userVerified: true
                            })
                            clearInterval(interval)
                        }
                    })

            }

            else {

                let body = {
                    sessionId: sessionId,
                    authenticationHash: myAuthenticationHash
                }
                fetch(config.get('apiUrl')+'sidAuthenticationStatus',{
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body)
                })
                .then(res=>res.json())
                .then((data)=> {
                    if (data.success){

                        props.secondCallback({
                            modal:false,
                            modalTitle: "Smart-ID",
                            verificationCode: "",
                            loginError: "",
                            userVerified: true
                        })
                        clearInterval(interval)
                    }
                    else if (data.message.length > 0){
                        props.secondCallback({
                            modal:true,
                            modalTitle: "Smart-ID",
                            verificationCode: "",
                            loginError: data.message[0],
                            userVerified: false
                        })
                        clearInterval(interval)
                    }
                })
            }
        }, 3000)
    }
        })
    
    }

    return (
        <span>
        {props.login ? 
        <form onSubmit={handleSubmit(authenticate)}>
                <Container>
                <Row>
                    <Col lg="3" className="lato size-18">Isikukood</Col>
                    <Col lg="9">
                    <Controller 
                            render={({ onChange }) => (
                                <FormTextField 
                                    id="idCode" 
                                    label="idcode"
                                    onChange={(value) => {
                                        setIdCode(value)
                                        setValue("idCode", value)
                                        return value
                                    }}
                                    labelVisible={false} 
                                    value={idCode}/>
                            )} 
                            name="idCode"
                            rules={{
                                required:"Isikukoodi sisestamine on kohustuslik!",
                                pattern: {
                                    value: /[0-9]/,
                                    message: "Sisestatud isikukood on vigane"
                                }
                            }} 
                            control={control}
                        />
                        
                    </Col>
                </Row>
                {errors.idCode && (
                            <Row><Col className="error" lg={{offset:3}}>{errors.idCode.message}</Col></Row>
                        )}
                <Row>
                    <Col lg="3"></Col>
                    <Col lg="9"><RoundButton icon="smart-id" color="button-green" size="size-icon"/></Col>
                </Row>
                </Container>
    </form>
        
        : 
        <RoundButton title="Smart&#8209;ID" color="button-green" size="size-16" onClick={()=>authenticate()}/>
        }
        </span>
    )
})