import React, {useState} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormTextField from './FormTextField';
import FormCheckbox from './FormCheckbox'
import RoundButton from './RoundButton';
import { useForm, Controller } from 'react-hook-form'
import config from 'react-global-configuration';
import FormAutosuggest from './FormAutosuggest';



function CalculatorForm(){
    
    const { handleSubmit, errors, control, setValue } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [cadastre, setCadastre] = useState('')
    const [coordinates, setCoordinates] = useState('')
    const [name, setName] = useState('')
    const [regNumber, setRegNumber] = useState('')
    const [method, setMethod] = useState('both')
    const [suggestions, setSuggestions] = useState([])
    

    const onSubmit = (values) => {
        console.log(values)
        let priceRequest = {
            cadastre: values.cadastre,
            coordinates: coordinates,
            regNumber: values.regNumber,
            method: method
        }
        fetch(config.get('apiUrl')+'calculatePrice',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify(priceRequest)
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
        })
        
        
    }

    const loadAddressSuggestions = (value) => {
        if (value.value.length > 2){
            fetch(config.get('apiUrl')+'addressSearch/'+value.value)
            .then(res=>res.json())
            .then((data)=> {
                console.log(data.results)
                setSuggestions(data.results)
            })
        }
    }

    const suggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        setCoordinates(suggestion.id)
        setName(suggestion.name)
        console.log(suggestion)
    }

    const emptySuggestions = () => {
        setSuggestions([])
      };
    
    const valueEntry = (event, {newValue}) => {
        if (newValue === 'undefined') newValue = ''
        setName(newValue)
        setCoordinates('')
        
    }

    const inputProps = {
        placeholder: "",
        value: name,
        onChange: valueEntry
    }

    return (
        <Row>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller 
                render={({ onChange }) => (
                    <FormTextField 
                        id="cadastre" 
                        label="Katastritunnus" 
                        labelVisible={true}
                        onChange={(value) => {
                            setCadastre(value);
                            setValue("cadastre", value)
                            return value
                        }}
                        value={cadastre}/>
                )} 
                name="cadastre"
                defaultValue=""
                control={control}
            />
            <Controller 
                render={({ onChange }) => (
                    <FormAutosuggest
                        suggestions={suggestions}
                        suggestionSelected={suggestionSelected}
                        emptySuggestions={emptySuggestions}
                        loadSuggestions={loadAddressSuggestions}
                        inputProps={inputProps}
                        onChange={(value) => {
                            setValue("coordinates", value)
                            return value
                        }}
                        label="Aadress"
                    />
                )} 
                name="coordinates"
                control={control}
            />
            <Controller 
                render={({ onChange }) => (
                    <FormTextField 
                        id="regNumber" 
                        label="Registrikood" 
                        labelVisible={true}
                        onChange={(value) => {
                            setRegNumber(value)
                            setValue("regNumber", value)
                            return value
                        }}
                        value={regNumber}/>
                )} 
                name="regNumber"
                defaultValue=""
                control={control}
            />

            <Controller 
                render={({ onChange }) => (
                    <FormCheckbox 
                        id="forest" 
                        name="method" 
                        type="radio" 
                        current={method}
                        onChange={(value) => { setMethod('forest')}}
                        label="Arvuta hind metsamaale" 
                        className="lato-bold size-16 default-color" 
                        checkmark="checkmark"/>
                )} 
                name="method" 
                control={control}
            />
            <Controller 
                render={({ onChange }) => (
                    <FormCheckbox 
                        id="field" 
                        name="method" 
                        type="radio" 
                        current={method}
                        onChange={(value) => {setMethod('field') }}
                        label="Arvuta hind põllumaale" 
                        className="lato-bold size-16 default-color" 
                        checkmark="checkmark"/>
                )} 
                name="method" 
                control={control}
            />
            <Controller 
                render={({ onChange }) => (
                    <FormCheckbox 
                        id="both" 
                        name="method" 
                        type="radio" 
                        current={method}
                        onChange={(value) => {setMethod('both') }}
                        label="Arvuta hind mõlemale" 
                        className="lato-bold size-16 default-color" 
                        checkmark="checkmark"/>
                )} 
                name="method" 
                control={control}
            />

                <RoundButton title="Arvuta" color="button-green" size="size-18"/>
        </form>
        </Row>
    )
}

export default class PriceCalculatorForm extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            catastre: '',
            address: '',
            regNumber: '',
            forest: false,
            field: false,
            both: false
        }

    }

    render() {
      return <Container className="price-calculator">
            <Row>
            <Col sm="5" lg="5" className="price-calculator-column">
                <Row className="lato-black size-25 highlight-color price-calculator-title">Hinnakalkulaator</Row>
                <CalculatorForm/>
            </Col>
            <Col sm="1" lg="1" className="price-calculator-column arrow-line"><div className="vertical-arrow-line"/></Col>
            <Col sm="6" lg="6" className="price-calculator-column results">
                <Row className="areas areas-heading">
                    <Col xs="7" sm="7" lg="7" className="lato-black size-16 highlight-color">Haritava maa pindala</Col>
                    <Col xs="5" sm="5" lg="5" className="lato-black size-16 highlight-color">Rohumaa pindala</Col>
                </Row>
                <Row className="areas">
                    <Col xs="7" sm="7" lg="7" className="lato-black size-25 highlight-color">{this.props.area1}</Col>
                    <Col xs="5" sm="5" lg="5" className="lato-black size-25 highlight-color">{this.props.area2}</Col>
                </Row>
                <Row>
                    <Col className="lato-light size-15 default-color estimated-value">Hinnanguline väärtus</Col>
                </Row>
                <Row>
                    <Col className="lato-black size-60 highlight-color">{this.props.price}</Col>
                </Row>
                <Row className="price-report">
                    <Col className="lato-light size-15 default-color">Soovid hindamisraportit e-mailile?</Col>
                </Row>
                <Row className="price-report">
                    <Col ><RoundButton title="Saadan e-mailile" color="button-green" size="size-18"/></Col>
                </Row>
            </Col>
            </Row>
           
        </Container>;
    }
  }