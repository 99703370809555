import React from 'react';

export default class ColorBar extends React.Component {
    render() {
      var borderRadius = "0px 0px 0px 0px"
      if (this.props.start) {borderRadius = "3px 0px 0px 3px"}
      if (this.props.end) {borderRadius = "0px 3px 3px 0px"}

      return <div className="color-bar" style={{backgroundColor:this.props.color, borderRadius:borderRadius}}></div>;
    }
  }