import React from 'react';
import {ReactComponent as EstateCheckbox} from './../assets/estate_cb.svg'
import {ReactComponent as LogsCheckbox} from './../assets/logs_cb.svg'
import {ReactComponent as FieldCheckbox} from './../assets/field_cb.svg'
import {ReactComponent as AllCheckbox} from './../assets/all_cb.svg'



export default class CheckboxIcon extends React.Component {

    constructor(props){
        super(props)

        let images = {
            logs: <LogsCheckbox/>,
            field: <FieldCheckbox/>,
            estate: <EstateCheckbox/>,
            all: <AllCheckbox/>
        }
        this.state = {
            img: images[this.props.type]
        }

    }

    render() {

      return <div className="checkbox-icon">
                {this.state.img}
            </div>

        ;
    }
  }