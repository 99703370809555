import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import RoundButton from '../components/RoundButton';
import moment from 'moment'
import FormTextField from '../components/FormTextField';
import FormSelect from '../components/FormSelect';
import { useForm, Controller } from 'react-hook-form'
import EstateDetails from '../components/EstateDetails';
import {ReactComponent as Star} from './../assets/star_white.svg'
import {ReactComponent as StarFill} from './../assets/star_white_fill.svg'
import EstateExternalDetails from '../components/EstateExternalDetails';
import CustomLink from '../components/CustomLink';
import SubscriptionButton from '../components/SubscriptionButton';
import TableItem from '../components/TableItem';
import LoadingAnimation from '../components/LoadingAnimation';
import Highlight from '../assets/highlight.svg'
import ShareButton from '../components/ShareButton';
import { Table } from 'react-bootstrap';


function OfferForm({parentCallback, procurementId, companies}){
    const { handleSubmit, errors, control } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [price, setPrice] = useState('')
    const [contents, setContents] = useState('')
    const [company, setCompany] = useState('')
    const [serverError, setServerError] = useState('')
    const [bidSuccess, setBidSuccess] = useState(false)

    
    const onSubmit = values => {
        setServerError("")
        let offerRequest = {
            price: values.price,
            contents: values.contents,
            company: company.value,
            procurement_id: procurementId
        }
        
        fetch(config.get('apiUrl')+'offer',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: JSON.stringify(offerRequest)
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.error) {
                setServerError(data.error)
            }
            else {
                setBidSuccess(true)
                parentCallback(data.offer)
            }
        })
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>

                    {serverError !== '' ?  <Row><Col>{serverError}</Col></Row> : ''}
                    {bidSuccess !== false ?  <Row><Col>Pakkumine lisatud</Col></Row> : ''}
                    {companies.length > 0 ? <Row>
                        <Col lg="3" className="lato-bold size-16">Ettevõte</Col>
                        <Col lg="9">
                            <Controller 
                               render={({ onChange }) => (
                                    <FormSelect
                                    options={companies}
                                    name="company"
                                    onChange={(value) => {
                                        if (value){
                                            setCompany({value:value.value})
                                        }
                                        else {
                                            setCompany('')
                                        }
                                        return value}}
                                    defaultValue={company}
                                    isClearable={true}
                                    placeholder="Vali ettevõte"
                                    
                                    />
                               )} 
                                name="company"
                                control={control}
                            />
                            </Col>
                    </Row> : ''}
                    <Row>
                        <Col lg="3" className="lato-bold size-16">Hind</Col>
                        <Col lg="9">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="price" 
                                        label="Price" 
                                        labelVisible={false}
                                        onChange={(value) => {
                                            setPrice(value)
                                            return value
                                        }}
                                        value={price}/>
                                )} 
                                name="price"
                                rules={{
                                    required:"Hinna sisestamine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            </Col>
                    </Row>
                    {errors.price && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.price.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3" ><span className="lato-bold size-16">Pakkumise sisu</span><span className="lato size-16"> (oma ettevõtte tutvustus, miks valida just Teid?)</span></Col>
                        <Col lg="9">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="contents"
                                        onChange={(value) => {
                                            setContents(value)
                                            return value
                                        }}
                                        textarea="textarea" 
                                        rows="5" 
                                        labelVisible={false} 
                                        value={contents}/>
                                )} 
                                name="contents"
                                rules={{
                                    required:"Pakkumise sisu sisestamine on kohustuslik!"
                                }}
                                control={control}
                            />
                            
                        </Col>
                    </Row>
                    {errors.contents && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.contents.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3"></Col>
                        <Col lg="9"><RoundButton title="Tee pakkumine" color="button-green" size="size-18"/></Col>
                    </Row>
        </form>
    )
}

export default class Procurement extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            object: null,
            offerPrice: null, 
            error: null, 
            offers: null,
            offerHeader: [],
            averagePrice: null,
            endTime: null,
            companies: [],
            subscription: false,
            token: localStorage.getItem("token"),
            finished: false,
            referenceLink: false,
            general: [
                "Tehingu liik",
                "Algusaeg",
                "Lõppaeg",
                "Tööde algusaeg",
                "Tööde lõppaeg"
            ],
            generalData: [],
            contract : [
                "Tehingu sõlmimise tähtaeg",
                "Tehingu sõlmimise koht",
                "Tehingu sõlmimisega seotud kulude tasumine"
            ],
            contractData : []

        }

        this.getProcurement(this.props.match.params.uuid)
        this.getSubscription(this.props.match.params.uuid)
        

    }

    getSubscription = (uuid) => {
        fetch(config.get('apiUrl')+'subscription/'+ uuid + '/2',
        {headers: {
            Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
          if (data.subscription){
            this.setState({
                subscription: data.subscription
              })
          }
          
        })
    }


    subscriptionCallback = (status) => {
        this.setState({
            subscription: status
        })
       }

    getProcurement = (uuid) => {
        var header = {}
        if (localStorage.getItem("token")){
            header = {headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            }
        }
        fetch(config.get('apiUrl')+'procurement/'+ uuid, header)
        .then(res=>res.json())
        .then((data)=> {
            var finished = false
            var finalized = true
            if (data.object.timeInSeconds > 0) {
                this.getActiveCompanies()
            }
            else {
                if (data.object.status_id === 3){
                    finished = true
                }
                else if (data.object.status_id === 4){
                    finalized = true
                }
            }
            
            
            this.setState({
                object: data.object,
                averagePrice: data.object.average_price,
                endTime: data.object.timeInSeconds,
                finished: finished,
                finalized: finalized,
                generalData: [
                    data.object.transaction_type.name,
                    moment(data.object.start_date).format("DD.MM.YYYY HH:mm:ss"),
                    moment(data.object.end_date).format("DD.MM.YYYY HH:mm:ss"),
                    moment(data.object.execution_start).format("DD.MM.YYYY"),
                    moment(data.object.execution_end).format("DD.MM.YYYY")
                ],
                contractData: [
                  data.object.contract_due_time + " päeva",
                  (data.object.contract_locations.map(location=>location.city.name)).join(', '),
                  data.object.contract_cost.name
                ]
                })
            this.getOffers(this.props.match.params.uuid)
        })
    }

    getActiveCompanies = () => {

        var header = {headers: {
            Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        }
        fetch(config.get('apiUrl')+'activeCompanies', header)
        .then(res=>res.json())
        .then((data)=> {
            var companies = []
            Object.keys(data.companies).forEach(key => {
                companies.push({
                    value: data.companies[key].id,
                    label:data.companies[key].name
                })
            })
            this.setState({
                companies: companies
                })
        })
    }

    getOffers = (uuid) => {
        var header = {}

        if (localStorage.getItem("token")){
            header = {headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            }
        }
        fetch(config.get('apiUrl')+'procurement/'+ uuid + '/offers',header)
        .then(res=>res.json())
        .then((data)=> {
          var offerItems = []
          var offerHeader = []
          var referenceLink = false
            if (Object.keys(data).length > 0){

                if (this.state.object.myProcurement === true){
                    if (this.state.object.status_id === 3){
                        Object.keys(data).forEach(key => {
                            offerItems.push({
                                'uuid':data[key].uuid,
                                'content':[moment(data[key].created_at).format("DD.MM.YYYY HH:mm:ss"),data[key].price + " €",data[key].contents],
                                'companyUuid':data[key].company !== null ? data[key].company.uuid : null,
                                'userUuid': data[key].user.uuid,
                                'selected': data[key].selected,
                                'reviewed': data[key].reviewed,
                                'references': data[key].company_id !== null ? data[key].company.totalReferences : data[key].user.totalReferences,
                                'averageRate': data[key].company_id !== null && data[key].company.average_rate !== null ? data[key].company.average_rate : 'N/A'
                            })
                        });
                        offerHeader = ['Kuupäev','Summa','Sisu', 'Pakkuja referentsid']
                        referenceLink = true
                    }
                    else {
                        Object.keys(data).forEach(key => {
                            offerItems.push({
                                'uuid':data[key].uuid,
                                'content':[moment(data[key].created_at).format("DD.MM.YYYY HH:mm:ss"),data[key].price + " €",data[key].contents],
                                'companyUuid':data[key].company !== null ? data[key].company.uuid : null,
                                'userUuid': data[key].user.uuid,
                                'selected': data[key].selected,
                                'reviewed': data[key].reviewed,
                            })
                        });
                        offerHeader = ['Kuupäev','Summa','Sisu']
                    }
                }
                else {
                    Object.keys(data).forEach(key => {
                        offerItems.push({
                            'uuid':data[key].uuid,
                            'content':[moment(data[key].created_at).format("DD.MM.YYYY HH:mm:ss"),data[key].price + " €"]
                        })
                    })
                     offerHeader = ['Kuupäev','Summa']
                }
                
                
                this.setState({
                    offers:offerItems,
                    offerHeader: offerHeader,
                    referenceLink: referenceLink
                })
            }
        })
    }




    dhm(t){
        var cd = 24 * 60 * 60,
            ch = 60 * 60,
            d = Math.floor(t / cd),
            h = Math.floor( (t - d * cd) / ch),
            m = Math.round( (t - d * cd - h * ch) / 60)
        if( m === 60 ){
            h++;
            m = 0;
        }
        if( h === 24 ){
            d++;
            h = 0;
        }
      return d + "p " + h + "h " + m + "min"
    }

    handleOfferCallback = (offer) => {
        console.log(offer)
        this.getOffers(this.props.match.params.uuid)

    }

    render() {

        if (this.state.object == null) {
            return <LoadingAnimation style="page-loading-animation"/>
        }

        const lblink = "https://xgis.maaamet.ee/ky/" + this.state.object.cadastres[0].cadastral_id
        const mrlink = "https://register.metsad.ee/#/?kataster=" + this.state.object.cadastres[0].cadastral_id

        return <Container className="page-container object-page" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="back-link lato size-16">
                <Col>
                    <Link to="/hanked">&larr; Tagasi hangete nimekirja</Link>
                </Col>
            </Row>
            <Row className="page-title">
                <Col lg="9">
                    <Row className="lato-black size-40">{this.state.object.title}</Row>
                    <Row className="lato size-20">{this.state.object.cadastres[0].location.settlement_unit}, 
                                        {this.state.object.cadastres[0].location.municipality.name}, 
                                        {this.state.object.cadastres[0].location.municipality.county.name}</Row>
                </Col>
                {this.state.token ? <ShareButton id={this.state.object.uuid} type="1"/> : ''}

                {this.state.token ? 
                    <SubscriptionButton id={this.state.object.id} type="2" subscription={this.state.subscription} subscribeCallback={this.subscriptionCallback}/>
                    :
                    ''
                }
            </Row>
            <Row className="white-entry-box shadow">
                <Container fluid>
                {this.state.error != null ? <Row className="error-message lato size-16"><Col>{this.state.error}</Col></Row> : ''}
                <Row className="our-page-menu shadow object-header">
                    <Col lg="6">
                        <Row className="item-table-header">Keskmine pakkumise hind</Row>
                        <Row className="lato-bold size-20 highlight-color">{this.state.averagePrice === 0 ? "Pakkumised puuduvad" :  this.state.averagePrice + " €"}</Row>
                    </Col>
                    <Col lg="6">
                        <Row className="item-table-header">Aega hanke lõpuni</Row>
                        {this.state.endTime > 0 ? 
                            <Row className="lato-bold size-20 highlight-color">{this.dhm(this.state.endTime)}</Row>
                            :
                            <Row className="lato-bold size-20 highlight-color">Hange on lõppenud!</Row>
                        }
                    </Col>
                    
                    

                </Row>
                <Row className="object-content-padding shadow">
                    <Col lg="5">
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Hanke andmed</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped responsive>
                                    <tbody>
                                    {this.state.general.map((item, idx) => {
                                        return (<tr>
                                            <td className="lato-bold size-16">{item}</td>
                                            <td className="lato size-16">{this.state.generalData[idx]}</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="1"></Col>
                    {this.state.endTime > 0 && this.state.object.myProcurement !== true ?
                    <Col lg="6">
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Tee pakkumine</Col>
                        </Row>
                        <Row>
                            <OfferForm parentCallback={this.handleOfferCallback} procurementId={this.state.object.uuid} companies={this.state.companies}/>
                        </Row>
                        
                    </Col>
                    :
                    ''
                    }
                    </Row>
                    <Row className="object-content-padding shadow">
                        <Col>
                            <Row>
                                <Col className="lato-bold size-20 highlight-color sub-title">Hanke sisu</Col>
                            </Row>
                            <Row>
                                <Col className="lato size-16">{this.state.object.description}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="object-content-padding shadow">
                        <Col>
                            <Row>
                                <Col className="lato-bold size-20 highlight-color sub-title">Pakkumised</Col>
                            </Row>
                            {this.state.offers == null ?
                            <Row>
                            <Col className="lato size-16">Pakkumised puuduvad</Col>
                            </Row>
                            :
                            <TableItem header={this.state.offerHeader} entities={this.state.offers} link={false} referenceLink={this.state.referenceLink} lineStatus={this.state.finished} reviewStatus={this.state.finalized}/>
                            }
                        </Col>
                    </Row>
                    {this.state.object.highlights ? 
 
                        <Row className="object-content-padding shadow">
                        <Col>
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Peamised omadused</Col>
                        </Row>
                        <Row >
                        <ul className="highlights">{this.state.object.highlights.split('\n').map((highlight) => {
                            return(<li><img src={Highlight}/> {highlight}</li>)
                        })}</ul>
                        </Row>
                        </Col>
                        </Row>: ''    
                        }
                    {this.state.object.cadastres.map((cadastre,idx) => {

                    return (
                    <EstateDetails idx={idx+1} total={this.state.object.cadastres.length} cadastre={cadastre}/>
                    )
                    })}
                    <EstateExternalDetails lblink={lblink} mrlink={mrlink}/>
                    {this.state.object.additional_info ? 
                        <Row className="object-content-padding shadow">
                            <Col>
                                <Row>
                                    <Col className="lato-bold size-20 highlight-color sub-title">Lisainfo</Col>
                                </Row>
                                <Row>
                                    <Col className="lato size-16">{this.state.object.additional_info}</Col>
                                </Row>
                            </Col>
                    
                        </Row>
                        :
                        ''
                    }

                    {this.state.object.files.length > 0 ? 
                    <Row className="object-content-padding shadow">
                        <Col className="detail-documentation">
                            <Row>
                                <Col className="lato-bold size-20 highlight-color sub-title">Hanke detailinfo</Col>
                            </Row>
                            {this.state.object.files.map((file) => {
                                return (
                                    <Row>
                                        <Col className="lato size-16">
                                            <span>
                                                {file.file_type === 'pdf' ? <Image src={require("./../assets/pdf.png")}/> : <Image src={require("./../assets/word.png")}/>}
                                            </span>
                                            <a href={config.get('apiUrl')+'files/'+file.uuid+"."+file.file_type} target="_blank">{file.name}</a>
                                        </Col>
                                    </Row>
                                )
                            })}
                            
                        </Col>
                
                    </Row>
                    :
                    ''
                    }

                    <Row className="object-content-padding shadow">
                    <Col>
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Tehinguinfo</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped responsive>
                                    <tbody>
                                    {this.state.contract.map((item, idx) => {
                                        return (<tr>
                                            <td className="lato-bold size-16">{item}</td>
                                            <td className="lato size-16">{this.state.contractData[idx]}</td>
                                        </tr>)
                                        
                                    })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    </Row>


                </Container>
            </Row>


            </Container>
          </Container>

    }

}