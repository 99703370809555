import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import { connect } from 'react-redux'
import TableItem from '../components/TableItem';
import MyActionsMenu from '../components/MyActionsMenu';

class MySubscriptions extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            subscriptions: [],
            notifications: []
        }

        this.getMySubscriptions()

    }

    getMySubscriptions = () => {
        fetch(config.get('apiUrl')+'mySubscriptions',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(json => {
                var mySubscriptions = []
                json.subscriptions.map((subscription) => 
                {
                    mySubscriptions.push({
                        'uuid':subscription.uuid,
                        'id':subscription.id,
                        'type_id':subscription.objectType.id,
                        'status':subscription.status.uuid,
                        'email':subscription.subscriptions[0].email,
                        'sms':subscription.subscriptions[0].sms,
                        'content':[subscription.title, subscription.cadastres[0].cadastral_id,subscription.start_price,subscription.status.title ]
                    })
                })
                this.setState({
                    subscriptions: mySubscriptions
                })
            })
    }

    updatePermissions = (status, entity, id) => {

        var requestBody = {
            id: entity.id,
            type: entity.type_id
        }
        if (id === 'email'){
            requestBody.email = status
        }
        else {
            requestBody.sms = status
        }
        console.log(requestBody)
        fetch(config.get('apiUrl')+'subscription',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: JSON.stringify(requestBody)
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
        })
        
       
    }

  

    render() {


        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Tere, {this.props.user.first_name.toString()}</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">

                <Container fluid>
                    <Row>
                <Container fluid>
                    <MyActionsMenu step={5} type={1}/>
                </Container>
                
                <Container className="account shadow" fluid>
                <Row className="my-actions-section">
                        <Container>
                            <Row>
                                <Col className="lato size-25 highlight-color">Minu teavitused</Col>
                            </Row>
                            {this.state.notifications.length === 0 ?  
                            <Row><Col className="informative-text">Teavitused puuduvad</Col></Row> : 
                            <Row><Col>
                                <TableItem header={['Nimi','Katastritunnus','Hetke hind','Staatus']} entities={this.state.notifications} entityType="oksjon"/>
                            </Col></Row>
                            }
                            
                        </Container>
                    </Row>
                    <Row className="my-actions-section">
                        <Container>
                            <Row>
                                <Col className="lato size-25 highlight-color">Minu lemmikud</Col>
                            </Row>
                            {this.state.subscriptions.length === 0 ?  
                            <Row><Col className="informative-text">Lemmikud puuduvad</Col></Row> : 
                            <Row><Col>
                                <TableItem header={['Nimi','Katastritunnus','Hetke hind','Staatus', 'Email','SMS','']} entities={this.state.subscriptions} subscription={true} link={false} subscriptionFeedback={this.updatePermissions} entityType="oksjon"/>
                            </Col></Row>
                            }
                            
                        </Container>
                    </Row>
                </Container>


                </Row>
                </Container>
                </Row>
                </Container>

     
        </Container>

}


}

function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(MySubscriptions)
