import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Link} from 'react-router-dom'
import ColorBar from '../components/ColorBar';
import {ReactComponent as Illustration1} from './../assets/illustration1_1.svg'
import {ReactComponent as Illustration2} from './../assets/illustration2_2.svg'
import {ReactComponent as Illustration3} from './../assets/illustration3_3.svg'
import {ReactComponent as Illustration4} from './../assets/illustration4_4.svg'


export default class OurPage extends React.Component {

    render() {
        return <Container className="page-container" fluid> 
        <Container className="sub-top-background-image" style={{backgroundImage:`url(${require("./../assets/meiekeskkonnast.svg")})`}} fluid>
        <Row>
            <Col>
                <Row className="lato-black size-65 white-color price-calculator-page-title">Meie keskkonnast</Row>
            </Col>
        </Row>
        </Container>
        <Container className="page-top-box shadow">
            <Row className="our-page-menu shadow">
                <Col className="types-menu">
                    <ul>
                        <li><Link to="/meiekeskkonnast">Metsa- või põllumaa müük</Link></li>
                        <li><Link to="/meiekeskkonnast">Raieõiguse müük</Link></li>
                        <li><Link to="/meiekeskkonnast">Objektide ostmine</Link></li>
                        <li><Link to="/meiekeskkonnast">Põllumaa rentimine</Link></li>
                        <li><Link to="/meiekeskkonnast">Metsamajanduskava koostamine</Link></li>
                        <li><Link to="/meiekeskkonnast">Metsaistikud</Link></li>
                        <li><Link to="/meiekeskkonnast">Hooldusraied</Link></li>
                    </ul>
                </Col>
            </Row>
            <Row className="our-page-intro">
                <Col className="lato-black size-30">Lorem ipsum dolor sit amet</Col>
            </Row>
            <Row className="our-page-intro">
                <Col className="lato-light size-20">Lorem ipsum dolor sit amet, odio volutpat qui eu, per ei ferri melius, qui an stet duis. Vix cu suas iudico noluisse, nec an saepe noster nostrud. Eu pri diceret gubergren efficiantur, per debet cetero dignissim eu. His te inimicus scripserit, malis augue copiosae vim te.</Col>
            </Row>

        </Container>
        <Container className="our-page first-section">
           
            <Row className="intro-text">
                <Col>
                    <Row>
                        <Col className="lato-black size-30">Kuidas toimub metsa- või põllumaa enampakkumisega müük meie keskkonnas?</Col>
                    </Row>
                    <Row>
                        <Col><ColorBar color="#2D250C" start={true}/><ColorBar color="#5D752E"/><ColorBar color="#F4C478"/><ColorBar color="#FFE8A6" end={true}/></Col>
                    </Row>
                    <Row>
                        <Col className="lato-light size-16">Lorem ipsum dolor sit amet, odio volutpat qui eu, per ei ferri melius, qui an stet duis. Vix cu suas iudico noluisse, nec an saepe noster nostrud. Eu pri diceret gubergren efficiantur, per debet cetero dignissim eu. His te inimicus scripserit, malis augue copiosae vim te.</Col>
                    </Row>
                </Col>
            </Row>
            <Illustration1/>
        </Container>
        <Container className="general-background-image" style={{height:"800px",backgroundImage:`url(${require("./../assets/general_background.svg")})`}} fluid>
            <Container className="our-page second-section">
                
                <Row className="intro-text">
                    <Col>
                        <Row>
                            <Col className="lato-black size-30">Kuidas toimub raieõiguse müük läbi enampakkumise?</Col>
                        </Row>
                        <Row>
                            <Col><ColorBar color="#2D250C" start={true}/><ColorBar color="#5D752E"/><ColorBar color="#F4C478"/><ColorBar color="#FFE8A6" end={true}/></Col>
                        </Row>
                        <Row>
                            <Col className="lato-light size-16">Lorem ipsum dolor sit amet, odio volutpat qui eu, per ei ferri melius, qui an stet duis. Vix cu suas iudico noluisse, nec an saepe noster nostrud. Eu pri diceret gubergren efficiantur, per debet cetero dignissim eu. His te inimicus scripserit, malis augue copiosae vim te.</Col>
                        </Row>
                    </Col>
                </Row>
                <Illustration2/>
            </Container>
        </Container>
        <Container className="our-page third-section">
            
                <Row className="intro-text">
                    <Col>
                        <Row>
                            <Col className="lato-black size-30">Maahange.ee portaalist objektide ostmine ja majandamine</Col>
                        </Row>
                        <Row>
                            <Col><ColorBar color="#2D250C" start={true}/><ColorBar color="#5D752E"/><ColorBar color="#F4C478"/><ColorBar color="#FFE8A6" end={true}/></Col>
                        </Row>
                        <Row>
                            <Col className="lato-light size-16">Lorem ipsum dolor sit amet, odio volutpat qui eu, per ei ferri melius, qui an stet duis. Vix cu suas iudico noluisse, nec an saepe noster nostrud. Eu pri diceret gubergren efficiantur, per debet cetero dignissim eu. His te inimicus scripserit, malis augue copiosae vim te.</Col>
                        </Row>
                    </Col>
                </Row>
                <Illustration3/>
            </Container>
        <Container className="general-background-image" style={{height:"950px",backgroundImage:`url(${require("./../assets/general_background.svg")})`}} fluid>
            <Container className="our-page fourth-section">
                
                <Row className="intro-text">
                    <Col>
                        <Row>
                            <Col className="lato-black size-30">Põllumaa väljarentimine kasutades Maahange portaali</Col>
                        </Row>
                        <Row>
                            <Col><ColorBar color="#2D250C" start={true}/><ColorBar color="#5D752E"/><ColorBar color="#F4C478"/><ColorBar color="#FFE8A6" end={true}/></Col>
                        </Row>
                        <Row>
                            <Col className="lato-light size-16">Lorem ipsum dolor sit amet, odio volutpat qui eu, per ei ferri melius, qui an stet duis. Vix cu suas iudico noluisse, nec an saepe noster nostrud. Eu pri diceret gubergren efficiantur, per debet cetero dignissim eu. His te inimicus scripserit, malis augue copiosae vim te.</Col>
                        </Row>
                        
                    </Col>
                </Row>
                <Illustration4/>
            </Container>
        </Container>
        </Container>;
    }
}
        