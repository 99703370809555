import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import { connect } from 'react-redux'
import TableItem from '../components/TableItem';
import MyActionsMenu from '../components/MyActionsMenu';
import RoundButton from '../components/RoundButton';
import CustomLink from '../components/CustomLink';

class MyAuctions extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            auctions: [],
        }
        this.getMyAuctions()

    }

    getMyAuctions = () => {
        fetch(config.get('apiUrl')+'myAuctions',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(json => {
                var myAuctions = []
                json.auctions.map((auction) => 
                {
                    myAuctions.push({
                        'uuid':auction.uuid,
                        'status':auction.status.id,
                        'content':[auction.title, auction.cadastres[0].cadastral_id,auction.current_price,auction.status.title ]
                    })

                })
                this.setState({
                    auctions: myAuctions
                })
            })
    }

  

    render() {


        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Tere, {this.props.user.first_name.toString()}</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">

                <Container fluid>
                    <Row>
                <Container fluid>
                    <MyActionsMenu step={2} type={1}/>
                </Container>
                
                <Container className="account shadow" fluid>
                <Row className="my-actions-section">
                            <Container>
                                <Row>
                                    <Col className="lato size-25 highlight-color">Sinu oksjonid</Col>
                                </Row>
                                {this.state.auctions.length === 0 ?  
                                <Row><Col className="informative-text">Oksjonid puuduvad</Col></Row> : 
                                <Row><Col>
                                    <TableItem header={['Nimi','Katastritunnus','Alghind','Staatus']} entities={this.state.auctions} entityType="oksjon"/>
                                </Col></Row>
                                }
                                <Row className="right">
                                    <Col>
                                    <CustomLink
                                        tag='span'
                                        target={false}
                                        to="/lisaobjekt"
                                        >
                                            <RoundButton title="Lisa uus" color="button-white" size="size-14"/>
                                    </CustomLink>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                </Container>
                </Row>
                </Container>
                </Row>
                </Container>

     
        </Container>

}


}

function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(MyAuctions)
