import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


export default class AddObjectProgress extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            items: ['Üldised andmed','Ülevaade','Kinnitamine'],
            activeItem: this.props.step
        }

    }


    render() {

      return <Col md="12" lg="12" className="object-progress">
                <ul className="object-progress-bar">
                   {this.state.items.map((item, index) => <li className={this.state.activeItem >= index ? "active" : 'none'}>{item}</li>)}
                </ul>
              </Col>

     ;
    }
  }