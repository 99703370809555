import React from 'react';
import {ReactComponent as Partner1} from './../assets/partner1.svg'
import {ReactComponent as Partner2} from './../assets/partner2.svg'
import {ReactComponent as Partner3} from './../assets/partner3.svg'
import {ReactComponent as Partner4} from './../assets/partner4.svg'
import { motion } from 'framer-motion'

export default class PartnerItem extends React.Component {

    constructor(props){
        super(props)

        let images = {
            1: <Partner1/>,
            2: <Partner2/>,
            3: <Partner3/>,
            4: <Partner4/>,
        }
        let titles = {
            1: "999+",
            2: "10+",
            3: "45%",
            4: "Klienditugi"
        }
        let subTitles = {
            1: "potentsiaalset ostjat",
            2: "aastat kogemust",
            3: "rohkem tulu",
            4: ""
        }
        let itemContents = {
            1: "Meie keskkonnas saavad pakkumist esitada üle 1000 ettevõtte ja eraisiku.",
            2: "Meie haldurite kogemus, vilumus ja täpsus aitavad Teid igal sammul.",
            3: "Maahange on Eesti kõige soodaim vahendus-tasuga portaal. Meie keskkonnas müües teenid oma kinnistult keskmiselt 45% kõrgemat tulu.",
            4: "Meie klienditugi on valmis teenindama Teid TASUTA!"
        }
        let colors = {
            1: "#2D250C",
            2: "#5D752E",
            3: "#F4C478",
            4: "#FFE8A6"
        }

        this.state = {
            img: images[this.props.type],
            title: titles[this.props.type],
            subTitle: subTitles[this.props.type],
            itemContent: itemContents[this.props.type],
            color: colors[this.props.type]
        }


    }

    render() {
      return <div className="partner-item">
                <div className="partner-item-bar" style={{backgroundColor: this.state.color}}></div>
                <motion.div whileHover={{ scale: 1.2 }} className="partner-item-badge">
                    {this.state.img}
                </motion.div>
                <div className="partner-item-badge-background"></div>
                <div className="partner-item-title lato-black">{this.state.title}</div>
                <div className="partner-item-subtitle lato-bold">{this.state.subTitle}</div>
                <div className="partner-item-content lato-light">{this.state.itemContent}</div>
             </div>;
      ;
    }
  }