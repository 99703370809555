import React, { useState, memo } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormImageCheckbox from '../components/FormImageCheckbox';
import FormTextField from '../components/FormTextField';
import FormTextFieldDouble from '../components/FormTextFieldDouble';
import RoundButton from '../components/RoundButton';
import FormSelect from '../components/FormSelect';
import { useForm, Controller } from 'react-hook-form'
import config from 'react-global-configuration';

export const SearchForm = memo(props => {
    
    const { handleSubmit, control } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [county, setCounty] = useState('')
    const [counties, setCounties] = useState([])
    const [municipalities, setMunicipalities] = useState([])
    const [countySuggestions, setCountySuggestions] = useState([])
    const [municipality, setMunicipality] = useState('')
    const [municipalitySuggestions, setMunicipalitySuggestions] = useState([])
    const [price, setPrice] = useState({})
    const [area, setArea] = useState({})
    const [address, setAddress] = useState('')
    const [typeAll, setTypeAll] = useState(true)
    const [typeLog, setTypeLog] = useState(true)
    const [typeField, setTypeField] = useState(true)
    const [typeEstate, setTypeEstate] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [typeString, setTypeString] = useState('')
    const [searchLimits, setSearchLimits] = useState(false)
    const [sortingOptions, setSortingOptions] = useState([
        {
            value: 1,
            label: "Uuemad eespool"
        },
        {
            value:2,
            label: "Vanemad eespool"
        }
        ])
    const [sorting, setSorting] = useState(1)

    if (typeString === ''){
        switch (props.type) {
            case 1:
                setTypeString('Oksjon')
                break;
            case 2:
                setTypeString('Hange')
                break;
            case 3:
                setTypeString('Kuulutus')
                break;
            default:
                setTypeString('Oksjon')
                break;
        }
    }

    if (counties.length === 0){
        fetch(config.get('apiUrl')+'counties')
            .then(res=>res.json())
            .then((data)=> {
                setCounties(data.counties)
            })
    }
    if (municipalities.length === 0){
        fetch(config.get('apiUrl')+'municipalities')
        .then(res=>res.json())
        .then((data)=> {
            setMunicipalities(data.municipalities)
        })
    }

    if (!searchLimits){
        fetch(config.get('apiUrl')+'searchLimits/'+ props.type)
        .then(res=>res.json())
        .then((data)=> {
            setSearchLimits(true)
            setPrice({
                id1: data.minPrice,
                id2: data.maxPrice
            })
            setArea({
                id1: data.minArea,
                id2: data.maxArea
            })
        })
    }

    const onSubmit = values => {

        if (values.price.id1 === undefined){
            values.price = price
        }
        if (values.area.id1 === undefined){
            values.area = area
        }
        values.typeAll = typeAll
        values.typeEstate = typeEstate
        values.typeField = typeField
        values.typeLog = typeLog
        props.parentCallback(values)
    }


    const handleCountyChange = selectedOption => {
        setCounty(selectedOption)
      };

    const loadCountySuggestions = (value) => {
        
        const inputValue = value.value.trim().toLowerCase();
        const inputLength = inputValue.length;
        setCountySuggestions(inputLength === 0 ? [] : counties.filter(county => 
            county.name.toLowerCase().slice(0, inputLength) === inputValue
        ));
    }

    const emptyCountySuggestions = () => {
        setCountySuggestions([])
      };

    const loadMunicipalitySuggestions = (value) => {
        
        const inputValue = value.value.trim().toLowerCase();
        const inputLength = inputValue.length;
        setMunicipalitySuggestions(inputLength === 0 ? [] : municipalities.filter(municipality => 
            municipality.name.toLowerCase().slice(0, inputLength) === inputValue
        ));
    }
    const emptyMunicipalitySuggestions = () => {
        setMunicipalitySuggestions([])
      };

    return (
        <form className="search-form" onSubmit={handleSubmit(onSubmit)}>
            <Container className="page-top-box shadow">
                <Row className="our-page-menu shadow">
                    <Col>
                        
                        <Controller 
                            render={({ onChange }) => (
                                <FormImageCheckbox 
                                    label="Kinnistud" 
                                    type="estate" 
                                    checked={typeEstate}
                                    onChange={(value) => { 
                                        if (value) {
                                            setTypeEstate(true)
                                        }
                                        else {
                                            setTypeEstate(false)
                                        }
                                    }}
                                    checkmark="checkmark"/>
                            )} 
                            name="typeEstate" 
                            control={control}
                        />
                        <Controller 
                            render={({ onChange }) => (
                                <FormImageCheckbox 
                                    label="Raieõigus" 
                                    type="logs" 
                                    checked={typeLog}
                                    onChange={(value) => { 
                                        if (value) {
                                            setTypeLog(true)
                                        }
                                        else {
                                            setTypeLog(false)
                                        }
                                    }}
                                    checkmark="checkmark"/>
                            )} 
                            name="typeLog"
                            control={control}
                        />
                        <Controller 
                            render={({ onChange }) => (
                                <FormImageCheckbox 
                                    label="Põllumaa rent" 
                                    type="field"
                                    onChange={(value) => {
                                        if (value) {
                                            setTypeField(true)
                                        }
                                        else {
                                            setTypeField(false)
                                        }
                                    }}
                                    checked={typeField} 
                                    checkmark="checkmark"/>
                            )} 
                            name="typeField" 
                            control={control}
                        />
                        <Controller 
                            render={({ onChange }) => (
                                <FormImageCheckbox 
                                    label="Kõik" 
                                    type="all"
                                    onChange={(value) => { 
                                        if (value) {
                                            setTypeAll(true)
                                            setTypeField(true)
                                            setTypeEstate(true)
                                            setTypeLog(true)
                                        }
                                        else {
                                            setTypeAll(false)
                                            setTypeField(false)
                                            setTypeEstate(false)
                                            setTypeLog(false)
                                        }
                                    }
                                    }
                                    checked={typeAll} 
                                    checkmark="checkmark"/>
                            )} 
                            name="typeAll" 
                            control={control}
                        />
                    </Col>
                </Row>
                <Row className="our-page-intro">
                    <Col lg="3">
                                <Controller 
                                render={({ onChange }) => (
                                    <FormSelect
                                    options={counties}
                                    name="county"
                                    onChange={(value) => {
                                        setCounty(value)
                                        return (value)
                                    }}
                                    label="Maakond"
                                    placeholder="Kõik"
                                    isClearable={true}
                                    />
                                )} 
                                name="county"
                                control={control}
                            />
                        </Col>
                    <Col lg="3">
                        <Controller 
                                render={({ onChange }) => (
                                    <FormSelect
                                    options={municipalities}
                                    name="municipality"
                                    placeholder="Kõik"
                                    label="Vald"
                                    onChange={(value) => {
                                        setMunicipality(value)
                                        return (value)
                                    }}
                                    isClearable={true}
                                    />
                                
                                )} 
                                name="municipality"
                                control={control}
                            />
                        </Col>
                    <Col lg="3">
                        <Controller 
                                    render={({ onChange }) => (
                                        <FormTextFieldDouble 
                                            id="price1" 
                                            id2="price2" 
                                            unit="€" 
                                            label="Hinnavahemik"
                                            onChange={(value) => {
                                                setPrice(value)
                                                return (value)
                                            }}
                                            defaults={price} 
                                            labelVisible={true}/>
                                    )} 
                                    name="price"
                                    defaultValue={price}
                                    control={control}
                        />
                        </Col>
                    <Col lg="3">
                        <Controller 
                                    render={({ onChange }) => (
                                        <FormTextFieldDouble 
                                            id="area1" 
                                            id2="area2" 
                                            unit="ha" 
                                            label="Pindala"
                                            onChange={(value) => {
                                                setArea(value)
                                                return (value)
                                            }}
                                            defaults={area} 
                                            labelVisible={true}/>
                                    )} 
                                    name="area"
                                    defaultValue={area}
                                    control={control}
                        />
                        </Col>
                </Row>
                <Row className="our-page-intro">
                    <Col lg="3">
                        <Controller 
                                    render={({ onChange }) => (
                                        <FormTextField 
                                            id="type" 
                                            disabled={true}
                                            label="Tehingu liik"
                                            value={typeString}
                                            labelVisible={true}/>
                                    )} 
                                    name="type"
                                    defaultValue={typeString}
                                    control={control}
                        />
                        </Col>
                        
                    <Col lg="7">
                        <Controller 
                                    render={({ onChange }) => (
                                        <FormTextField 
                                            id="searchTerm" 
                                            label="Maakond" 
                                            labelVisible={false}
                                            onChange={(value) => {
                                                setSearchTerm(value)
                                                return (value)
                                            }}
                                            value={searchTerm}/>
                                    )} 
                                    name="searchTerm"
                                    placeholder="Otsi katastritunnuse / aadressi / kohanime järgi"
                                    control={control}
                        />
                    </Col>
                    <Col lg="2" className="search-button"><RoundButton title="Otsi" color="button-green" size="size-18"/></Col>
                </Row>
            </Container>
            <Container className="search-results-header">
                <Row>
                    <Col className="lato size-18 left d-flex align-items-center" lg="6">Otsingule leiti {props.searchCount} tulemust</Col>
                    <Col className="lato size-18 right d-flex align-items-center justify-content-end" lg="6"><b>Järjesta:</b> 
                    <Controller 
                                render={({ onChange }) => (
                                    <FormSelect
                                    options={sortingOptions}
                                    name="sorting"
                                    onChange={(value) => {
                                        setSorting(value)
                                        return (value)
                                    }}
                                    isClearable={false}
                                    defaultValue={{value:1, label:"Uuemad eespool"}}
                                    />
                                )} 
                                name="sorting"
                                control={control}
                                />
                    </Col>
                </Row>

            </Container>
        </form>
    )
})