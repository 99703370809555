import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

export default class ReferenceItem extends React.Component {


    render() {
      return <Container className="reference-item">
                <Row>
                    <Col lg="2" className="d-none d-lg-block"></Col>
                    <Col sm="12" md="12" lg="3" className="reference-image-container"><Image className="reference-image" src={require('./../assets/testimage.jpg')} roundedCircle/></Col>
                    <Col sm="12" lg="5">
                        <Row>
                            <Col xs={{offset:2}} className="lato-bold size-20 highlight-color">{this.props.name}</Col>
                        </Row> 
                        <Row>
                            <Col xs={{offset:2}}className="lato size-16">{this.props.occupation}</Col>
                        </Row> 
                        <Row>
                            <Col xs={{span:8, offset:2}} lg="12" className="lato-light size-16 reference-content">{this.props.reference}</Col>
                        </Row> 
                    </Col>
                    <Col lg="2" className="d-none d-lg-block"></Col>
                </Row> 
             </Container>;
      ;
    }
  }