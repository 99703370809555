import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import TableItem from '../components/TableItem';
import MyActionsMenu from '../components/MyActionsMenu';
import RoundButton from '../components/RoundButton';
import CustomLink from '../components/CustomLink';

class MyActions extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            bids: [],
            auctions: [],
            procurements: [],
            classifieds: [],
            subscriptions: []
        }

        this.getMyAuctions()
        this.getMyProcurements()
        this.getMyBids()
        this.getMyClassifieds()
        this.getMySubscriptions()

    }

    getMyBids = () => {
        fetch(config.get('apiUrl')+'myBids',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(json => {
                var myBids = []
                json.bids.map((bid) => 
                {
                    myBids.push({
                        'uuid':bid.auction.uuid,
                        'content':[bid.auction.title, bid.auction.cadastres[0].cadastral_id,bid.max_price,bid.auction.status.title ]
                    })

                })
                this.setState({
                    bids: myBids
                })
            })
    }

    getMyAuctions = () => {
        fetch(config.get('apiUrl')+'myAuctions',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(json => {

                var myAuctions = []
                json.auctions.map((auction) => 
                {
                    myAuctions.push({
                        'uuid':auction.uuid,
                        'status':auction.status.id,
                        'content':[auction.title, auction.cadastres[0].cadastral_id,auction.current_price,auction.status.title ]
                    })

                })
                this.setState({
                    auctions: myAuctions
                })
            })
    }

    getMyProcurements = () => {
        fetch(config.get('apiUrl')+'myProcurements',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(json => {
                var myProcurements = []
                json.procurements.map((procurement) => 
                {
                    myProcurements.push({
                        'uuid':procurement.uuid,
                        'status':procurement.status.id,
                        'content':[procurement.title, procurement.cadastres[0].cadastral_id,procurement.average_price,procurement.status.title ]
                    })
                })
                this.setState({
                    procurements: myProcurements
                })
            })
    }

    getMyClassifieds = () => {
        fetch(config.get('apiUrl')+'myClassifieds',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(json => {
                var myClassifieds = []
                json.classifieds.map((classified) => 
                {
                    myClassifieds.push({
                        'uuid':classified.uuid,
                        'status':classified.status.id,
                        'content':[classified.title, classified.cadastres[0].cadastral_id,classified.start_price,classified.status.title ]
                    })
                })
                this.setState({
                    classifieds: myClassifieds
                })
            })
    }

    getMySubscriptions = () => {
        fetch(config.get('apiUrl')+'mySubscriptions',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(json => {
                var mySubscriptions = []
                json.subscriptions.map((subscription) => 
                {
                    mySubscriptions.push({
                        'uuid':subscription.uuid,
                        'status':subscription.status.uuid,
                        'content':[subscription.title, subscription.cadastres[0].cadastral_id,subscription.start_price,subscription.status.title ]
                    })
                })
                this.setState({
                    subscriptions: mySubscriptions
                })
            })
    }

    render() {

        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Tere, {this.props.user.first_name.toString()}</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">

                <Container fluid>
                    <Row>
                <Container fluid>
                    <MyActionsMenu step={0} type={1}/>
                </Container>

                <Container fluid>
                    <Row>
                    <Col md="12" lg="6">
                        <Row className="my-actions-section">
                            <Container>
                                <Row>
                                    <Col className="lato size-25 highlight-color">Sinu oksjonid</Col>
                                </Row>
                                {this.state.auctions.length === 0 ?  
                                <Row><Col className="informative-text">Oksjonid puuduvad</Col></Row> : 
                                <Row><Col>
                                    <TableItem header={['Nimi','Katastritunnus','Alghind','Staatus']} entities={this.state.auctions} entityType="oksjon"/>
                                </Col></Row>
                                }
                                <Row className="right">
                                    <Col>
                                    {this.state.auctions.length === 0 ?  '' :
                                        <CustomLink
                                            tag='span'
                                            target={false}
                                            to="/minuoksjonid"
                                            >
                                                <RoundButton title="Vaata kõiki" color="button-white" size="size-14"/>
                                        </CustomLink>
                                    }
                                    <CustomLink
                                        tag='span'
                                        target={false}
                                        to="/lisaobjekt"
                                        >
                                            <RoundButton title="Lisa uus" color="button-white" size="size-14"/>
                                    </CustomLink>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                        <Row className="my-actions-section">
                        <Container>
                            <Row>
                                <Col className="lato size-25 highlight-color">Sinu hanked</Col>
                            </Row>
                            {this.state.procurements.length === 0 ?  
                            <Row><Col className="informative-text">Hanked puuduvad</Col></Row> : 
                            <Row><Col>
                                <TableItem header={['Nimi','Katastritunnus','Keskmine hind','Staatus']} entities={this.state.procurements} entityType="hange"/>
                            </Col></Row>
                            }
                            <Row className="right">
                                <Col>
                                {this.state.procurements.length === 0 ?  '' :
                                    <CustomLink
                                        tag='span'
                                        target={false}
                                        to="/minuhanked"
                                        >
                                            <RoundButton title="Vaata kõiki" color="button-white" size="size-14"/>
                                    </CustomLink>
                                }
                                <CustomLink
                                        tag='span'
                                        target={false}
                                        to="/lisaobjekt"
                                        >
                                            <RoundButton title="Lisa uus" color="button-white" size="size-14"/>
                                </CustomLink>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                    <Row className="my-actions-section">
                        <Container>
                            <Row>
                                <Col className="lato size-25 highlight-color">Sinu kuulutused</Col>
                            </Row>
                            {this.state.classifieds.length === 0 ?  
                            <Row><Col className="informative-text">Kuulutused puuduvad</Col></Row> : 
                            <Row><Col>
                                <TableItem header={['Nimi','Katastritunnus','Hind','Staatus']} entities={this.state.classifieds} entityType="kuulutus"/>
                            </Col></Row>
                            }
                            <Row className="right">
                                <Col>
                                {this.state.classifieds.length === 0 ?  '' :
                                    <CustomLink
                                        tag='span'
                                        target={false}
                                        to="/minukuulutused"
                                        >
                                            <RoundButton title="Vaata kõiki" color="button-white" size="size-14"/>
                                    </CustomLink>
                                }
                                <CustomLink
                                        tag='span'
                                        target={false}
                                        to="/lisaobjekt"
                                        >
                                            <RoundButton title="Lisa uus" color="button-white" size="size-14"/>
                                </CustomLink>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                      
                    </Col>
                    <Col md="12" lg="6">
                    <Row className="my-actions-section">
                        <Container>
                            <Row>
                                <Col className="lato size-25 highlight-color">Lemmikud</Col>
                            </Row>
                            {this.state.subscriptions.length === 0 ?  
                            <Row><Col className="informative-text">Lemmikud puuduvad</Col></Row> : 
                            <Row><Col>
                                <TableItem header={['Nimi','Katastritunnus','Hetke hind','Staatus']} entities={this.state.subscriptions} entityType="oksjon"/>
                            </Col></Row>
                            }
                            <Row className="right">
                                <Col>
                                {this.state.subscriptions.length === 0 ?  '' :
                                    <CustomLink
                                        tag='span'
                                        target={false}
                                        to="/jälgin"
                                        >
                                            <RoundButton title="Vaata kõiki" color="button-white" size="size-14"/>
                                    </CustomLink>
                                }
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                    <Row className="my-actions-section">
                            <Container>
                                <Row>
                                    <Col className="lato size-25 highlight-color">Viimased pakkumised</Col>
                                </Row>
                                {this.state.bids.length === 0 ?  
                                <Row><Col className="informative-text">Pakkumised puuduvad</Col></Row> : 
                                <Row><Col>
                                  <TableItem header={['Nimi','Katastritunnus','Minu pakkumine','Staatus']} entities={this.state.bids} entityType="oksjon"/>
                                </Col></Row>
                                }
                                <Row className="right">
                                    <Col>
                                    {this.state.bids.length === 0 ?  '' :
                                        <CustomLink
                                            tag='span'
                                            target={false}
                                            to="/minupakkumised"
                                            >
                                                <RoundButton title="Vaata kõiki" color="button-white" size="size-14"/>
                                        </CustomLink>
                                    }
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                    </Col>
                    </Row>
                </Container>


                </Row>
                </Container>
                </Row>
                </Container>
        </Container>

}


}

function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(MyActions)
