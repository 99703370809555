import React from 'react';
import CheckboxIcon from './CheckboxIcon';


export default class FormImageCheckbox extends React.Component {

    constructor(props){
        super(props)

        var checked = this.props.checked

        this.state = {
            checked: checked
        }

    }

    handleChange = () => {
        if (this.state.checked === "checked") {
            this.setState({checked: ""})
            this.props.onChange(false)
        }
        else {
            this.setState({checked:"checked"})
            this.props.onChange(true)
        }
    }

    componentWillReceiveProps(nextProps){
        var checked = ""
        if (nextProps.checked){
            checked = "checked"
        } 
        this.setState({
            checked: checked
        })
    }

    render() {
      return <div className="checkbox form-image-checkbox">
                <label className="checkbox-container lato-black size-18 highlight-color"><CheckboxIcon type={this.props.type}/>{this.props.label}
                <input type="checkbox" checked={this.state.checked} onClick={this.handleChange}/>
                <span className={this.props.checkmark}></span>
                </label>
            </div>;
    }
  }