import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const CustomLink = ({ history, to, onClick, tag: Tag,staticContext, target, className, propagate, ...rest }) => (
    <Tag
        {...rest}
        className={className}
        onClick={(event) => {
           
            onClick(event);
            if (target){
                window.open(to, "_blank")
            }
            else {
                history.push(to)
            }
            if (!propagate){
                event.stopPropagation()
            }
        }}
    />
);

CustomLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    onClick: PropTypes.func,
};
CustomLink.defaultProps = {
    onClick: () => {}
};
export default withRouter(CustomLink);