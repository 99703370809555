import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PacketFeatures from '../components/PacketFeatures';



export default class MapApplication extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            features: [
                'Maa-ameti kaardid',
                'Ettevõtete kaardikiht',
                'Metsaregister',
                'PRIA',
                'Kuulutused',
                'Google otselülitus',
                'Prindi funktsioon',
                'Mõõtetööriistad',
                'Ajalugu',
                'Multifunktsionaalne otsing',
                'Kommentaari võimalus',
                'Kõrgusmudel',
                'Tehnovõrgud',
                'Kaitsealad',
                'Vanad vallad',
                'Riigimaad sh lepingud',
                'Rail Balticu trass'
            ]
        }
      }

  

    render() {
        return <Container className="page-container" fluid> 
        <Container className="sub-top-background-image" style={{backgroundImage:`url(${require("./../assets/kaardirakendus.svg")})`}} fluid>
        <Row>
            <Col>
                <Row className="lato-black size-65 white-color price-calculator-page-title">Kaardirakendus</Row>
            </Col>
        </Row>
        </Container>
        <Container className="page-top-box shadow">
            <Row className="our-page-intro">
                <Col className="lato-black size-36">Hea maahange.ee portaali kasutaja!</Col>
            </Row>
            <Row className="our-page-intro">
                <Col className="lato-light size-26">Võttes arvesse meie klientide soove ja vajadusi, oleme kokku pannud kolm erinevat kaardirakenduse paketti. Nii on võimalik valida Teil enda vajadustest lähtuvalt parim võimalik töövahend. Põhipaketti aitab Sul valida ühekordse pileti ostmine, mida saad kasutada iga paketi kohta eraldi. Head kasutamist!</Col>
            </Row>

        </Container>

        <Container className="packet-results">
            <Row>
                <Col lg="4"><PacketFeatures title="start" price="9.99" color="start-color" arrowColor="horizontal-arrow-line-start" threshold={10} features={this.state.features}/></Col>
                <Col lg="4"><PacketFeatures title="standard" price="19.99" color="standard-color" arrowColor="horizontal-arrow-line-standard" threshold={12} features={this.state.features}/></Col>
                <Col lg="4"><PacketFeatures title="pro" price="49.99" color="pro-color" arrowColor="horizontal-arrow-line-pro" threshold={20} features={this.state.features}/></Col>
            </Row>


        </Container>
    
        </Container>
    }
}
        