import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TypesMenu from '../components/TypesMenu';
import SubTypesMenu from '../components/SubTypesMenu';
import ObjectMap from '../components/ObjectMap';
import config from 'react-global-configuration';
import ListItem from '../components/ListItem';
import RoundButton from '../components/RoundButton';
import PartnerItem from '../components/PartnerItem';
import PriceCalculatorForm from '../components/PriceCalculatorForm';
import MapApplication from '../components/MapApplication';
import ReferenceCarousel from '../components/ReferenceCarousel';
import CustomerCarousel from '../components/CustomerCarousel';
import {useHistory} from 'react-router-dom'
import Typed from 'typed.js'



function ListLinkItem(data){
    console.log(data)
    const history = useHistory()
    var price = 0
    var link = ""
    switch (data.data.objectType.id){
        case 1:
            price = data.data.current_price
            link = "/oksjon/"
            break
        case 2:
            link = "/hange/"
            break
        case 3:
            price = data.data.start_price
            link = "/kuulutus/"
            break
        default:
            price = data.data.current_price
            link = "/oksjon/"
            break

    }

    return(
        <ListItem 
            title={data.data.title}
            type={data.data.transaction_type} 
            image={data.data.cadastres[0].image} 
            location={data.data.cadastres[0].location.address + ", " + data.data.cadastres[0].location.settlement_unit + ", " + data.data.cadastres[0].location.municipality.name + ", " + data.data.cadastres[0].location.municipality.county.name}
            cadastrenumber={data.data.cadastres[0].cadastral_id} 
            area={Math.round((data.data.total_area/1000 + Number.EPSILON) * 100) / 100}
            favourite={data.data.subscriptionable_id} 
            time={data.data.timeInSeconds}
            status={data.data.status_id}
            objectType={data.data.objectType.id}
            onClick={() => {history.push(link + data.data.uuid)}} 
            price={price}/>)
    

  }

export default class Home extends React.Component {

    
    constructor(props){
        super(props)
        this.state = {
            objects: [],
            objectCount: [],
            objectCounties: [],
            activeMenuItem: 0,
            noObjectsText: 'oksjonit',
            objectTitle: 'oksjonid',
            viewObjectsText: 'Näita kõiki oksjoneid'
        }

        this.getObjectCount(1)
        this.getObjects(1)
        this.getObjectCounties(1)
        
        

    }

    componentDidMount(){
        const typedOptions = {
            strings: [
                '<span class="lato-black">Leia endale huvipakkuv objekt</span><br/><span class="lato-light">kõige soodsama tehingutasuga turul</span>'
            ],
            typeSpeed:50,
            fadeOut:true,
            fadeOutDelay:500,
            loop:true,
            loopCount: Infinity
        }

        this.typed = new Typed(this.el,typedOptions)

    }

    getObjectCount = (type) => {
        fetch(config.get('apiUrl')+'objectCount/'+type)
            .then(res=>res.json())
            .then((data)=> {
            this.setState({objectCount:data})
            })
    }


    getObjects = (type) => {
        var headers = {
            'Content-Type' : 'application/json'
        }
        if (localStorage.getItem("token")){
            headers.Authorization = 'Bearer '+ localStorage.getItem("token")
        }
        fetch(config.get('apiUrl')+'objectList/'+type,{headers})
        .then(res=>res.json())
        .then((data)=> {
          this.setState({objects:data})
        })
    } 

    getObjectCounties = (type) => {
        fetch(config.get('apiUrl')+'objectCounty/'+type)
        .then(res=>res.json())
        .then((data)=> {
          this.setState({objectCounties:data})
        })
    }


    handleMenuItemSelection = (idx) => {
        console.log(idx)
        switch(idx){
            case 0:
                this.getObjectCount(1)
                this.getObjectCounties(1)
                this.getObjects(1)
                this.setState({
                    activeMenuItem: idx,
                    noObjectsText: 'oksjonit',
                    objectTitle: 'oksjonid',
                    viewObjectsText: 'Näita kõiki oksjoneid'
                })
                break;
            case 1:
                this.getObjectCount(2)
                this.getObjectCounties(2)
                this.getObjects(2)
                this.setState({
                    activeMenuItem: idx,
                    noObjectsText: 'hanget',
                    objectTitle: 'hanked',
                    viewObjectsText: 'Näita kõiki hankeid'
                })
                break;
            case 2:
                this.getObjectCount(3)
                this.getObjectCounties(3)
                this.getObjects(3)
                this.setState({
                    activeMenuItem: idx,
                    noObjectsText: 'kuulutust',
                    objectTitle: 'kuulutused',
                    viewObjectsText: 'Näita kõiki kuulutusi'
                })
                break;
            default:
                this.getObjectCount(1)
                this.getObjectCounties(1)
                this.getObjects(1)
                this.setState({
                    activeMenuItem: idx,
                    noObjectsText: 'oksjonit',
                    objectTitle: 'oksjonid',
                    viewObjectsText: 'Näita kõiki oksjoneid'
                })
                break;
        }
    }

  

    render() {

        return <Container className="page-container" fluid> 
            <Container className="front-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/home_back.svg")})`}} fluid>
            <Row className="home-leading-space">
                <Col lg="7">
                    <Row className="size-45 left">
                        <div className="typed-content">
                            <span
                                ref={(el) => { this.el = el; }}
                            />
                    </div>
                    </Row>
                    
                </Col>
            </Row>
            <Row className="home-leading-space">
                <Col lg="7">
                    <TypesMenu activeItem={this.state.activeMenuItem} onMenuItemSelect={this.handleMenuItemSelection}/>
                </Col>
            </Row>
            <Row className="home-leading-space">
                <Col lg="7">
                    <SubTypesMenu count={this.state.objectCount}/>
                </Col>
            </Row>
            <Row>
                <Col lg="7">
                    <ObjectMap counties={this.state.objectCounties}/>
                </Col>
            </Row>
        </Container>
        <Container className="content-container">
            <Row>
                <Col className="lato-black size-40 center sub-title">Populaarsemad {this.state.objectTitle}</Col>
            </Row>
            <Row className="content-row">
                <TypesMenu activeItem={this.state.activeMenuItem} onMenuItemSelect={this.handleMenuItemSelection}/>
            </Row>
            <Row>
                <Col><hr className="hr-separator"/></Col>
            </Row>
            <Row>
                {this.state.objects.length > 0 ?
                <Col className="center">
                    {this.state.objects.map((object) => 
                            <ListLinkItem data={object}/>
                        )}
                </Col>
                :
                <Col>
                    <Row>Hetkel pole ühtegi aktiivset {this.state.noObjectsText}</Row>
                </Col>
            }
            </Row>
            <Row style={{marginTop:"70px"}}>
                <Col className="center"><RoundButton title={this.state.viewObjectsText} color="button-green" size="size-16"/></Col>
            </Row>
        </Container>
        <Container className="content-container">
            <Row>
                <Col className="lato-black size-40 center">Miks valida partneriks Maahange?</Col>
            </Row>
            <Row className="content-row">
                <Col><div className="partner-item-group"><PartnerItem type="1"/><PartnerItem type="2"/><PartnerItem type="3"/><PartnerItem type="4"/></div></Col>
            </Row>
        </Container>
        <Container className="general-background-image" style={{backgroundImage:`url(${require("./../assets/general_background.svg")})`}} fluid>
            <Container>
                <Row>
                    <Col className="lato-black size-40 section-title">Abiks metsa- või põllumaa müümisel</Col>
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="8"><PriceCalculatorForm area1="0ha" area2="0ha" price="0€"/></Col>
                    <Col sm="12" md="12" lg="4"><MapApplication/></Col>
                </Row>
            </Container>
        </Container>
        <Container className="content-container">
            <Row>
                <Col className="lato-black size-40 center">Kliendid meist</Col>
            </Row>
            <Row>
                <Col><ReferenceCarousel/></Col>
            </Row>
        </Container>
        <Container className="client-background-image" style={{paddingBottom:"100px",backgroundImage:`url(${require("./../assets/our_client_background.svg")})`}} fluid>
                <Row>
                    <Col className="lato-black size-40 our-customers">Meie koostööpartnerid</Col>
                </Row>
                <Row>
                    <Col><CustomerCarousel/></Col>
                </Row>
        </Container>
        </Container>
        ;
        
      }
}