import React from 'react';
import {ReactComponent as Loudspeaker} from './../assets/notification.svg'

export default class Notifications extends React.Component {
    render() {
      return <div className="notification-container clickable"> 
            <Loudspeaker/>
             {this.props.count ? <span className="notification-count">{this.props.count}</span> : ''}
             </div>;
      ;
    }
  }