import React from 'react';
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import {ReactComponent as Share} from './../assets/share.svg'
import CustomLink from '../components/CustomLink';
import ShareModal from './ShareModal';

export default class ShareButton extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            modalVisible: false,
        }
    }

    share = () => {
        this.setState({
            modalVisible: true
        })
    }

    handleModalData = (status) => {
        this.setState({
            modalVisible:status
        })
    }

    render() {
        return <span><CustomLink
            className="clickable ml-auto header-share lato-bold size-22"
            tag='span'
            onClick={this.share}
            
            >
               <Share/> &nbsp;<span>Jaga sõbrale</span>  
        </CustomLink>

        <ShareModal
                show={this.state.modalVisible}
                onHide={() => this.setState({modalVisible:false})}
                parentCallback={this.handleModalData}
                id={this.props.id}
                type={this.props.type}
            />
        </span>
    }

}