import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from './../assets/loadingAnimation.json'


export default class LoadingAnimation extends React.Component {

    render() {
        const buttonStyle = {
          display: 'block',
          margin: '10px auto'
        };
    
        const defaultOptions = {
          loop: true,
          autoplay: true, 
          animationData: animationData.default,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };
    
        return <div className={this.props.style}>
                    <div className="loading-animation">
                        <Lottie options={defaultOptions}
                                height={100}
                                width={100}/>
                    </div>
              </div>
      }
}