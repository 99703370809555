import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Accordion from 'react-bootstrap/Accordion'
import Nav from 'react-bootstrap/Nav'
import Card from 'react-bootstrap/Card'


export default class Faq extends React.Component {
    constructor(props){
        super(props)

        let categories = ["Registreerimine","Konto haldamine","Raieõigus","Põllumaa rent","Metsa müük"]
        let questions = [
            "Kuidas saan registreeruda Maahange.ee keskkonda?",
            "Kas registreeruda on võimalik läbi panga lingi?",
            "Millised isikutuvastamise võimalused registreerimisel on ?",
            "Kas saan registreeruda ka ilma isikutuvastuseta?"
        ]

        this.state = {
            cat: categories,
            quest: questions
        }
      }

  

    render() {
        return <Container className="page-container price-calculator-page" fluid> 
        <Container className="sub-top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/kkk-back.svg")})`}} fluid>
        <Row>
            <Col>
                <Row className="lato-black size-65 white-color price-calculator-page-title">KKK</Row>
            </Col>
        </Row>
        </Container>
        <Container>
            <Row>
                <Col className="lato-black size-40 faq-title">Korduma kippuvad küsimused</Col>
            </Row>
            <Row>
                <Col>
                <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                    <Row>
                        <Col lg={4}>
                        <Nav variant="pills" className="flex-column lato size-24 default-color left shadow">
                        { this.state.cat.map((menu, index) => 
                            <Nav.Item>
                            <Nav.Link eventKey={index}>{menu}</Nav.Link>
                            </Nav.Item>

                        )}

                        </Nav>
                        </Col>
                        <Col lg={8}>
                        <Tab.Content>
                            <Tab.Pane eventKey="0" className="lato-light size-20 left">
                                <Accordion >
                                { this.state.quest.map((menu, index) => 
                                    <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey={index}>
                                    {menu}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={index}>
                                    <Card.Body>Some info</Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                )}
                                </Accordion>
                            </Tab.Pane>
                            <Tab.Pane eventKey="1">
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                            </Tab.Pane>
                            <Tab.Pane eventKey="3">
                            </Tab.Pane>
                            <Tab.Pane eventKey="4">
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                    </Tab.Container>
                </Col>
            </Row>
        </Container>
        </Container>
    }
}