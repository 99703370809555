import React, { useState, memo } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormTextField from '../components/FormTextField';
import FormSelect from '../components/FormSelect';
import RoundButton from '../components/RoundButton';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import config from 'react-global-configuration';
import { useForm, Controller } from 'react-hook-form'


const NewCompany = memo( props => {
    const { handleSubmit, errors, control, register, setError, watch } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [title, setTitle] = useState('')
    const [regNumber, setRegNumber] = useState('')
    const [kmkr, setKmkr] = useState('')
    const [positions, setPositions] = useState([{value:1,label:"Juhatuse liige"},{value:2,label:"Volitatud isik"}])
    const [position, setPosition] = useState('')
    const [mandate, setMandate] = useState('')
    const [chooseFile, setChooseFile] = useState('Vali fail')


    const onSubmit = values => {

        const formData = new FormData()

        for(const name in values){
            if (name === "mandate"){
                formData.append("mandate",mandate)
            }
            else if (name === "position") {
                formData.append(name, values[name].value)
            }
            else {
                formData.append(name, values[name])
            }
        }
        fetch(config.get('apiUrl')+"company",{
            method: 'POST',
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: formData
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            props.parentCallback(data)
        })
    }

    const handleChange = (e) => {
        setChooseFile(e.target.files[0].name)
        setMandate(e.target.files[0])
        console.log(mandate)
    }

    return  <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg="4" className="lato size-18">Nimi</Col>
                            <Col lg="8">
                            <Controller 
                                    render={({ onChange }) => (
                                        <FormTextField 
                                            id="title" 
                                            label="Nimi"
                                            onChange={(value) => {
                                                setTitle(value)
                                                return value
                                            }} 
                                            value={title}/>
                                    )} 
                                    name="title"
                                    rules={{
                                        required:"Ettevõte nime sisestamine on kohustuslik",
                                    }} 
                                    control={control}
                                />
                            </Col>
                        </Row>
                        {errors.title && (
                                <Row><Col className="error" lg={{offset:4}}>{errors.title.message}</Col></Row>
                                )}
                        <Row>
                            <Col lg="4" className="lato size-18">Registrikood</Col>
                            <Col lg="8">
                            <Controller 
                                    render={({ onChange }) => (
                                        <FormTextField 
                                            id="regNumber" 
                                            label="Registrikood"
                                            onChange={(value) => {
                                                setRegNumber(value)
                                                return value
                                            }} 
                                            value={regNumber}/>
                                    )} 
                                    name="regNumber"
                                    rules={{
                                        required:"Registrikoodi sisestamine on kohustuslik",
                                    }} 
                                    control={control}
                                />
                            </Col>
                        </Row>
                        {errors.regNumber && (
                                 <Row><Col className="error" lg={{offset:4}}>{errors.regNumber.message}</Col></Row>
                                )}
                        <Row>
                            <Col lg="4" className="lato size-18">Kmkr</Col>
                            <Col lg="8">
                            <Controller 
                                    render={({ onChange }) => (
                                        <FormTextField 
                                            id="kmkr"
                                            value={kmkr}
                                            onChange={(value) => {
                                                setKmkr(value)
                                                return value
                                            }}
                                            label="KMKR"/>
                                    )} 
                                    name="kmkr"
                                    control={control}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" className="lato size-18">Amet</Col>
                            <Col lg="8">
                                <Controller 
                                render={({ onChange }) => (
                                    <FormSelect
                                    options={positions}
                                    name="position"
                                    onChange={(value) => {setPosition({value:value});return value}}
                                    isClearable={false}
                                    placeholder="Vali amet"
                                    />
                                )} 
                                name="position"
                                rules={{
                                    required:"Ameti valimine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            </Col>
                        </Row>
                        {errors.position && (
                                <Row><Col className="error" lg={{offset:4}}>{errors.position.message}</Col></Row>
                                )}
                        {position.value === 2 ? 
                        <Row>
                            <Col lg="4" className="lato size-18">Volitus</Col>
                            <Col id="hide" lg="8">
                                <label for="mandate">{chooseFile}</label>
                                <input ref={register({required:"Volituse lisamine on kohustuslik"})} name="mandate" id="mandate" type="file" onChange={handleChange}/>


                            </Col>
                        </Row>
                        :
                        ''
                        }
                        {errors.mandate && (
                                <Row><Col className="error" lg={{offset:4}}>{errors.mandate.message}</Col></Row>
                                )}
                        <Row>
                        <Col lg={{offset:4}}>
                            <RoundButton title="Lisa ettevõte" type="submit" color="button-green" size="size-18"/>
                        </Col>
                        </Row>
        </form>
}
)

class AddCompany extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            companyCreated: false,
            user: {},
            loginError: "",
        }
        if (localStorage.getItem("token")){
            console.log(localStorage.getItem("token"))
            this.getUser()
        }

    }

    getUser = () => {
        fetch(config.get('apiUrl')+'user',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then((json) => {

                this.setState({
                    user: json.user
                })
            })
    }

 

    validationCallback = (company) => {
       
            this.setState({
                companyCreated: true
            })

    }


    render() {

        if (!localStorage.getItem("token")){
                return <Redirect to={{
                    pathname: "/logisisse",
                  }}/>
        }

        if (this.state.companyCreated){
            return <Redirect to={{
                pathname: "/minukonto",
              }}/>
    }

        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Tere, {this.props.user.first_name}</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">

                        <Container fluid>
                            <Row>
                        
                        <Container className="account shadow" fluid>
                            <Row>
                                <Col className="lato-bold size-30 highlight-color sub-title">Lisa ettevõte</Col>
                            </Row>
                            <NewCompany parentCallback={this.validationCallback} user={this.state.user}/>
                        </Container>

                        </Row>
                        </Container>
 
                
            </Row>

            </Container>
          </Container>

    }
}


function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(AddCompany)
