import React from 'react';
import Info from '../assets/info.svg'
import Cross from '../assets/cross.svg'
import Tick from '../assets/tick.svg'
import Start from '../assets/start.svg'
import Standard from '../assets/standard.svg'
import Pro from '../assets/pro.svg'
import RoundButton from './RoundButton'
import { motion } from 'framer-motion'

export default class PacketFeatures extends React.Component {

    constructor(props){
        super(props)

        var iconSrc = "";
        switch(this.props.color){
            case "start-color":
                iconSrc = Start
                break;
            case "standard-color":
                iconSrc = Standard
                break;
            case "pro-color":
                iconSrc = Pro
                break
            default:
                iconSrc = Start
                break;
        }

        this.state = {
            iconSrc: iconSrc
        }
    }

    render() {
      return <motion.div whileHover={{ scale: 1.1 }}><div className="packet-logo" style={{backgroundImage:`url(${this.state.iconSrc})`}}></div>
            <div className={`packet-header ${this.props.color}`}>
                {this.props.title}
            </div>
            <div className={`${this.props.arrowColor}`}></div>
            <div className="packet-frame">
            <div className="packet-price size-33">{this.props.price}€+km/<span className="size-20">1 kuu</span></div>
            <div className="packet-features">
                <ul>
                    {this.props.features.map((feature, index) => <li>{feature}{this.props.threshold >= index ? <img src={Tick}/> : <img src={Cross}/>}<img src={Info}/></li>
                            )}
                </ul>
            </div>
        </div>
        <div className="packet-actions">
            <RoundButton title="Ühekordne pilet" color={this.props.color} size="size-18"/>
            <RoundButton title="Telli" color={this.props.color} size="size-18"/>
        </div>
        </motion.div> 
    }
  }