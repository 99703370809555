import React from 'react';
import { Link } from 'react-router-dom'

export default class FormCheckbox extends React.Component {

    constructor(props){
        super(props)


        this.state = {
            checked: this.props.checked
        }
    }

    componentWillReceiveProps(newProps){

        

        if (this.props.type === 'radio'){
            var checked = ""

            if (newProps.current === newProps.id){
            checked = "checked"
            }

            this.setState({
                checked: checked
            })
        }
    }

    handleChange = () => {
        if (this.state.checked === 1) {
            this.setState({checked: 0})
            this.props.onChange(false, this.props.entity, this.props.id)
        }
        else {
            this.setState({checked:1})
            this.props.onChange(true, this.props.entity, this.props.id)
        }
        
    }

    modalCall = () => {
        this.props.labelLink()
    }

    render() {
      const {value, onChangeValue} = this.props
      return <div className="checkbox">
                <label className={`checkbox-container ${this.props.className}`}>{this.props.hideLabel ? '' : this.props.label} {this.props.labelLinkText ? <Link onClick={() => {this.modalCall()}}>{this.props.labelLinkText}</Link> : ''}
                    <input type={this.props.type} id={this.props.id} name={this.props.name} value={this.props.name} checked={this.state.checked} onChange={this.handleChange}/>
                    <span className={this.props.checkmark}></span>
                </label>
              
            </div>;
    }
  }