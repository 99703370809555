import React, { useState }  from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { Link } from 'react-router-dom';
import PinModal from '../components/PinModal'
import { MobileIdAuthentication } from '../components/MobileIdAuthentication'
import { SmartIdAuthentication } from '../components/SmartIdAuthentication'
import { EmailLogin } from '../components/EmailLogin'
import RoundButton from '../components/RoundButton';
import CustomLink from '../components/CustomLink';
import { Redirect } from 'react-router-dom';
import {ReactComponent as IdCard} from './../assets/id_kaart.svg'
import {ReactComponent as MobileId} from './../assets/mobiil_id.svg'
import {ReactComponent as SmartId} from './../assets/smart_id.svg'
import { IdCardAuthentication } from '../components/IdCardAuthentication';

export default class Login extends React.Component {

    constructor(props){
        super(props)

        var registrationSuccess = false
        if (this.props.location.state != null && this.props.location.state.registrationSuccess === true){
            registrationSuccess = true
        }

        this.state = {
            registrationSuccess: registrationSuccess,
            loginSuccess: false,
            modal: false,
            modalTitle: "",
            verificationCode: "",
            loginError: "",
            authenticationHash: "",
            idCardError:""
        }

    }

    callback = (newStatus) => {
        this.setState({
            loginSuccessful: newStatus
        })
    }

    idCardCallback = (values) => {
        this.setState({
            idCardError: values.loginError,
            loginSuccessful: values.userVerified 
        })
    }

    firstAuthenticationHandler = (values) => {

        this.setState({
            modal:values.modal,
            modalTitle: values.modalTitle,
            verificationCode: values.verificationCode,
            loginError: values.loginError,
            authenticationHash: values.authenticationHash
        })
    }

    secondAuthenticationHandler = (values) => {

        this.setState({
            modal:values.modal,
            modalTitle: values.modalTitle,
            verificationCode: values.verificationCode,
            loginError: values.loginError,
            loginSuccessful: values.userVerified
        })
    }


    render() {

        if (this.state.loginSuccessful) {
            return <Redirect to={{
                pathname: "/minukonto",
                state: { loginSuccess: true }
              }}/>
        }


        return <Container className="page-container login" fluid> 
            <Container className="top-background-image" style={{height:"auto",float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Logi sisse</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">
                <Container className="box-content-padding" fluid>
                    {this.state.registrationSuccess === true ?
                    <Row>
                        <Col>
                            <Row>Täname registreerumast! Enne võimalust portaali kasutada, peab meie haldur aktiveerima teie konto. Meie haldur võtab Teiega ühendust juba 1 tööpäeva jooksul, et selgitada portaali toimimist ja aidata Teil Maahange.ee lehelt võtta maksimum!</Row>
                            <Row>Enne portaali kasutamist peate ka tuvastama oma isiku ID-kaardi, Mobiil-ID või Smart-ID vahendusel ning kinnitama oma e-posti aadressi ja telefoninumbri. Seda saate teha portaali sisse logides.</Row>
                        </Col>
                    </Row>
                    : '' }
                <Row className="justify-content-center">
                <Col lg="9">
                <Tabs className="justify-content-center" defaultActiveKey="email" id="uncontrolled-tab-example">
                    <Tab eventKey="email" title="Email" >
                        <Row className="justify-content-center">
                        <Col lg="9">
                            <EmailLogin parentCallback={this.callback}/>
                        </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="mobileid" title={<MobileId className="login-icon"/>}>
                        <Row className="justify-content-center">
                        <Col lg="9">
                        <MobileIdAuthentication login={true} firstCallback={this.firstAuthenticationHandler} secondCallback={this.secondAuthenticationHandler}/>
                        </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="idcard" title={<IdCard className="login-icon"/>}>
                        <Row className="justify-content-center">
                        <Col lg="9">
                        <IdCardAuthentication login={true} loginCallback={this.idCardCallback}/>
                        </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="smartid" title={<SmartId className="login-icon"/>}>
                        <Row className="justify-content-center">
                        <Col lg="9">
                        <SmartIdAuthentication login={true} firstCallback={this.firstAuthenticationHandler} secondCallback={this.secondAuthenticationHandler}/>
                        </Col>
                        </Row>
                    </Tab>
                    </Tabs>
                
                </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg="9" className="lato size-18 justify-content-center register-user">
                    <Link to="/registreeru">Registreeru kasutajaks</Link>
                    </Col>
                </Row>
                </Container>
            </Row>

            <PinModal 
                show={this.state.modal}
                onHide={() => this.setState({modal:false})}
                title={this.state.modalTitle}
                verificationcode={this.state.verificationCode}
                errormessage={this.state.loginError}
            />
            
        </Container>
        </Container>
        }
}

