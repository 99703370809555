import React from 'react';
import Logo from './Logo.js'
import {Navbar, Nav, Dropdown} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {Link} from 'react-router-dom'
import {throttle} from 'lodash'
import TopSubMenu from './TopSubMenu';
import './../assets/style.css'

const delay = ms => new Promise(res => setTimeout(res, ms));

export default class TopMenu extends React.Component {

    constructor(props){
        super(props)
        this.nav = React.createRef()

        this.resize = throttle(this.resize.bind(this), 200)

        this.state = {
            menuItems: [
                'Oksjonid','Hanked','Kuulutused','Kaardirakendus','Hinnakalkulaatorid','Meie keskkonnast','KKK'
            ],
            dropdownItems: [],
            subMenuItems: [
                'Lisa objekt','Minu konto', 'Minu teavitused','Minu tegevused','Logi välja'
            ],
            activeItem: -1,
            visible: "d-none",
            subMenuVisibility: "",
            subMenu: false
        }

        
    }

  resize = () => {
      this.autoCollapse(); 

  }

  
  componentDidMount() {
    if (this.nav.current.scrollWidth > window.innerWidth){
        this.autoCollapse()
    }
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  removeArrayElement = (myArray, itemName) => {
    this.setState({
      [myArray]: this.state[myArray].filter(item => item !== itemName)
    })
  }

  removeArrayElements = (myArray, items) => {
    this.setState({
      [myArray]: this.state[myArray].filter(item => !items.includes(item))
    })
  }

  addArrayElementToTheEnd = (myArray, element) => {
    this.setState(prevState => ({
        [myArray]: [...prevState[myArray], element]
    }))
  }

  addArrayElementsToTheEnd = (myArray, newArray) => {
    this.setState(prevState => ({
        [myArray]: [...prevState[myArray], ...newArray]
    }))
  }

  addArrayElementToTheFront = (myArray, element) => {
    this.setState(prevState => ({
        [myArray]: [element, ...prevState[myArray]]
    }))
  }



  autoCollapse = async () => {
    console.log(window.innerWidth)
        if (this.nav.current.scrollWidth > window.innerWidth){
            while(this.nav.current.scrollWidth > window.innerWidth && this.state.menuItems.length > 0){
                this.addArrayElementToTheFront("dropdownItems",this.state.menuItems[this.state.menuItems.length-1])
                this.removeArrayElement("menuItems",this.state.menuItems[this.state.menuItems.length-1])
                this.setState({
                    visible: ""
                })
                await delay(200);
            }
        }
        else {
            if (this.state.dropdownItems.length > 0){
                this.addArrayElementToTheEnd("menuItems",this.state.dropdownItems[0])
                this.removeArrayElement("dropdownItems",this.state.dropdownItems[0])
                if (this.state.dropdownItems.length === 0){
                    this.setState({
                        visible: "d-none" 
                    })
                }
                if (this.nav.current.scrollWidth > window.innerWidth){
                    this.autoCollapse()
                }
            }
        }

        if (window.innerWidth < 1000 && !this.state.subMenu){
            console.log("lsame")
            console.log(this.state.subMenuItems)
            this.addArrayElementsToTheEnd("dropdownItems",this.state.subMenuItems)
            this.setState({
                subMenu: true,
                subMenuVisibility:"d-none"
            })
        }
        else if (window.innerWidth > 1000 && this.state.subMenu){
            this.removeArrayElements("dropdownItems",this.state.subMenuItems)
            this.setState({
                subMenu: false,
                subMenuVisibility:""
            })
        }
    }

    onMenuItemClick = (idx) => {
        this.setState({activeItem: idx})
    }


    render() {
        console.log(this.state.dropdownItems)
      return  <Container fluid className="header">
      <Navbar ref={this.nav}>
      <Navbar.Brand as={Link} to="/"><Logo/></Navbar.Brand>
      
      <Navbar.Collapse className="topMenu" id="basic-navbar-nav">
      
        <Nav className="topMenuContainer">
            { this.state.menuItems.map((menu, index) => 
                <Nav.Link
                as={Link} to={"/"+ menu.toLowerCase().replace(/\s+/g,'')} 
                className={this.state.activeItem === index ? 'topMenuItemSelected' : ''}
                onClick={this.onMenuItemClick.bind(null, index)} 
                bsPrefix='navlink'
                key={index}>{menu}</Nav.Link>
            )}
                  </Nav>
        <Dropdown>
        <Dropdown.Toggle alignRight id="navbarDropdownMenu"  className={this.state.visible}>
            <span className="navbar-toggler-icon"></span>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight={true}>
            {this.state.dropdownItems.map((menu, index) => 
                <span>
                {menu === 'Lisa objekt' ? 
                <span>
                <Dropdown.Divider />
                <Dropdown.Item 
                    as={Link} to={"/"+ menu.toLowerCase().replace(/\s+/g,'')} 
                    key={index}
                >{menu}</Dropdown.Item>
                <Dropdown.Divider />
                </span>
                :
                <Dropdown.Item 
                    as={Link} to={"/"+ menu.toLowerCase().replace(/\s+/g,'')} 
                    key={index}
                >{menu}</Dropdown.Item>
                }
                </span>
            )}
         </Dropdown.Menu>
         </Dropdown>
      </Navbar.Collapse>
      
    </Navbar>

    <TopSubMenu count={this.props.count} visibility={this.state.subMenuVisibility}/>
    </Container>
    }
  }