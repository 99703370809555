import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import {Link, withRouter} from 'react-router-dom'
import RoundButton from '../components/RoundButton';
import Rater from 'react-rater'
import FeedbackItem from '../components/FeedbackItem';
import LoadingAnimation from '../components/LoadingAnimation';


class Portfolio extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            references: [],
            token: localStorage.getItem("token"),
            totalAverage: 0,
            qualityAverage: 0,
            communicationAverage: 0,
            timeAverage: 0

        }

        this.getFeedback(this.props.match.params.uuid)
        

    }

  
    returnToProcurement = () => {
        this.props.history.push("../hange/"+ this.state.object.procurement.uuid)
    }

  
    getFeedback = (uuid) => {
        var header = {}
        if (localStorage.getItem("token")){
            header = {headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            }
        }
        fetch(config.get('apiUrl')+'feedback/'+ uuid, header)
        .then(res=>res.json())
        .then((data)=> {

            this.setState({
                references: data.references,
                totalAverage: data.totalAverage,
                qualityAverage: data.qualityAverage,
                communicationAverage: data.communicationAverage,
                timeAverage: data.timeAverage
                })

        })
    }


    render() {

        if (this.state.references == null) {
            return <LoadingAnimation style="page-loading-animation"/>
        }


        return <Container className="page-container object-page" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="back-link lato size-16">
                <Col>
                    <Link onClick={this.props.history.goBack}>&larr; Tagasi hankele</Link>
                </Col>
            </Row>
            <Row className="page-title">
                <Col lg="9">
                    <Row className="lato-black size-40">Portfoolio</Row>
                    <Row className="lato size-20">Keskmine hinnang</Row>
                </Col>
           
            </Row>
            <Row className="white-entry-box shadow">
                <Container fluid>
                <Row className="shadow">
                    <Col lg="3" className="total-average">
                        <Row className="item-table-header lato-bold size-30">{this.state.totalAverage}</Row>
                    </Col>
                    <Col className="item-average" lg="3">
                        <Row className="lato size-16">Kvaliteet</Row>
                        <Row><Rater total={5} rating={this.state.qualityAverage} interactive={false} /></Row>
                    </Col>
                    <Col className="item-average" lg="3">
                        <Row className="lato size-16">Suhtlus</Row>
                        <Row><Rater total={5} rating={this.state.communicationAverage} interactive={false} /></Row>
                    </Col>
                    <Col className="item-average" lg="3">
                        <Row className="lato size-16">Kiirus</Row>
                        <Row><Rater total={5} rating={this.state.timeAverage} interactive={false} /></Row>
                    </Col>
                    
                    

                </Row>
                <Row className="shadow">
                    <Col>
                        <Row className="object-content-padding">
                            <Col className="lato-bold size-20 highlight-color sub-title">Antud hinnangud ({this.state.references.length})</Col>
                        </Row>
                        <Row>
                            <Col>
                            {this.state.references.map((reference,idx) => 
                                <FeedbackItem feedback={reference} feedbackAverage={Math.round(((reference.quality_rate + reference.communication_rate + reference.time_rate)/3)*10)/10} images={reference.files}/>
                            )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center single-row-button">
                                <RoundButton title="Tagasi" color="button-white" size="size-16" onClick={()=>{this.props.history.goBack()}}/>
                            </Col>
                        </Row>
                  
                    </Col>
                </Row>

                </Container>
            </Row>


            </Container>
          </Container>

    }

}


export default withRouter(Portfolio)