import React, {useState} from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import RoundButton from '../components/RoundButton';
import Modal from 'react-bootstrap/Modal'
import FormTextFieldFormatted from './FormTextFieldFormatted';
import config from 'react-global-configuration';


export default function ConfirmationModal(props) {


    const [amount, setAmount] = useState(0)
    const [error, setError] = useState(0)
    const [success, setSuccess] = useState(false)

    const closeModal = (status) => {
        setError("")
        setSuccess(false)
        props.parentCallback(status)
    }

    const makeOffer = () => {
        setError('')
        if (amount === 0 && !props.buyNowPrice) {
            setError("Sisestage palun pakkumise summa")
        }
        else {

            var myBid = {
                auctionId: props.auctionId,
                price: amount,
                bidType: props.buyNowPrice ? 3 : 2
            }
            fetch(config.get('apiUrl')+'bid',{
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    Authorization: 'Bearer '+ localStorage.getItem("token")
                },
                body: JSON.stringify(myBid)
            })
            .then(res=>res.json())
            .then((data)=> {
                if (data.error){
                    setError(error)
                }
                else {
                    setSuccess(true)
                }
            })
        }
    }


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.infoText ? <Row><Col className="lato size-16">{props.infoText}</Col></Row> : ''}
        {error ? <Row><Col className="error-message">{error}</Col></Row> : ''}
        {success ? 
            <Container>
                 <Row>
                    <Col className="center lato-bold size-16">
                        Täname pakkumise eest!
                    </Col>
                </Row>
                <Row>
                    <Col className="center single-row-button">
                        <RoundButton title="Sulge" color="button-white" size="size-16" onClick={()=>{closeModal(true)}}/>
                    </Col>
                </Row>
            </Container> : 
            <Container>
                {props.buyNowPrice ? 
                    <Row>
                        <Col lg={4}>
                            "Osta kohe" hind
                        </Col>
                        <Col lg={8}>
                            {props.buyNowPrice} €
                        </Col>
                    </Row>
                    : ''}
                {props.valueEntry === true ? 
                    <Row>
                        <Col lg={4} className="lato-bold size-16 ">
                            Teie pakkumine
                        </Col>
                        <Col lg={8}>
                            <FormTextFieldFormatted id="amount" suffix="€" labelVisible={false} value={amount} onChange={(value)=>setAmount(value)}/>
                        </Col>
                    </Row>
                    : ''
                }
                {props.confirmationButton === true ?
                    <Row>
                        <Col className="center single-row-button">
                            <RoundButton title="Loobu" color="button-white" size="size-16" onClick={()=>{closeModal(false)}}/>
                            <RoundButton title="Tee pakkumine" color="button-green" size="size-16" onClick={()=>{makeOffer()}}/>
                        </Col>
                    </Row>
                    :
                    ''
                }

            </Container>
        }
        
        </Modal.Body>
      </Modal>
    );
  }