import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { Table } from 'react-bootstrap';

export default class EstateDetails extends React.Component {


    constructor(props){
        super(props)

        
        this.state = {
            general: ["Kadastritunnus","Kinnistu nimi","Kinnistu aadress","Pindala","Maakasutus"],
            detail: [
                "Peapuuliigid",
                "Peale MMK koostamist teostatud tööd",
                "Kehtiv metsamajanduskava",
                "Kehtivad raieteatised",
                "Peale raieteatise võtmist teostatud raietööd",
                "Raiutav pindala",
                "Raiutav maht",
                "Puuliigid",
                "Raieliik",
                "Raie tähtaeg",
                "Väljaveo tähtaeg",
                "Ladustamise kohad",
                "Väljaveoteed",
                "Seemne- ja säilikpuud",
                "Raiutavad metsaeraldised"
            ],
            generalData: [
                this.props.cadastre.cadastral_id,
                this.props.cadastre.location.address,
                this.props.cadastre.location.settlement_unit + ", " + this.props.cadastre.location.municipality.name + ", " + this.props.cadastre.location.municipality.county.name,
                this.props.cadastre.area,
                this.props.cadastre.land_usage
            ],
            detailData: [
                this.props.cadastre.main_tree_species,
                this.props.cadastre.mmk_work === 0 ? "Ei" : "Jah",
                this.props.cadastre.mmk === 0 ? "Ei" : "Jah",
                this.props.cadastre.rt === 0 ? "Ei" : "Jah",
                this.props.cadastre.rt_work === 0 ? "Ei" : "Jah",
                this.props.cadastre.harvest_area,
                this.props.cadastre.harvest_volume,
                this.props.cadastre.tree_species,
                this.props.cadastre.harvest_type,
                this.props.cadastre.harvest_deadline,
                this.props.cadastre.clean_deadline,
                this.props.cadastre.stock_location,
                this.props.cadastre.clean_routes,
                this.props.cadastre.seed_trees,
                this.props.cadastre.harvest_exceptions
            ]
        }

    }


    render() {
      return <Row className="object-content-padding shadow">
      <Col lg="5">
          <Row>
              <Col className="lato-bold size-20 highlight-color sub-title">Kinnistu andmed ({this.props.idx}/{this.props.total})</Col>
          </Row>
          <Row>
              <Col>
                <Table striped responsive>
                    <tbody>
                    {this.state.general.map((item, idx) => {
                        return (<tr>
                            <td className="lato-bold size-16">{item}</td>
                            <td className="lato size-16">{this.state.generalData[idx]}</td>
                        </tr>)
                    })}
                    </tbody>
                </Table>
              </Col>
          </Row>
        
          <Row>
              <Col className="lato-bold size-16"><Image className="cadastre-image" src={this.props.cadastre.image}/></Col>
          </Row>
      </Col>
      <Col lg="1"></Col>
      <Col lg="6">
      <Row>
              <Col className="lato-bold size-20 highlight-color sub-title"></Col>
          </Row>
          <Row>
              <Col>
                <Table striped responsive>
                    <tbody>
                    {this.state.detail.map((item, idx) => {
                        return (<tr>
                            <td className="lato-bold size-16">{item}</td>
                            <td className="lato size-16">{this.state.detailData[idx]}</td>
                        </tr>)
                    })}
                    </tbody>
                </Table>
              </Col>
          </Row>
      </Col>
      </Row>
    }
  }