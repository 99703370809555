import React, { useState, memo } from 'react';
import { useForm, Controller } from 'react-hook-form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import hwcrypto from 'hwcrypto-js'
import config from 'react-global-configuration';
import RoundButton from '../components/RoundButton';
import { store } from '../store'

export const IdCardAuthentication = memo(props => {

    const { handleSubmit, errors, control } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [idCode, setIdCode] = useState('')
    const [loginError, setLoginError] = useState('')

    const  getUser = () =>{
        console.log("get user")
        return function(dispatch) {
            return fetch(config.get('apiUrl')+'user',{
                headers: {
                    Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            })
                .then(response => response.json())
                .then(json => {
                    dispatch({type:"STORE_USER",payload: json.user})
                })
        }
    }

    const hexToBase64 = (str) => {
        return btoa(String.fromCharCode.apply(null,
          str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
        );
      }
      
    const hexToPem = (s) => {
         var b = hexToBase64(s);
         var pem = b.match(/.{1,64}/g).join("\n");
         return "-----BEGIN CERTIFICATE-----\n" + pem + "\n-----END CERTIFICATE-----"; 
      }

    const authenticate = values => {

        if(props.login){
     

            hwcrypto.getCertificate({lang:'et'})
            .then(
            function (response) {
                var certPEM = hexToPem(response.hex);
                // var certDER = response.encoded;
                console.log("Sert loetud:\n");

                var headers = {
                    'Content-Type' : 'application/json'
                }

                let loginRequest = {
                    grant_type:"idCard",
                    client_id:"2",
                    client_secret:"LmoHbyXNYqTVGDawn04yRq1GWXwVbNBmRsFhSEXZ",
                    cert: certPEM
                }

                fetch(config.get('apiUrl')+'oauth/token',{
                    method: 'POST',
                    headers,
                    body: JSON.stringify(loginRequest)
                })
                .then(res=>res.json())
                .then((data)=> {
                    if (data.error) {
                            props.loginCallback({
                                loginError: data.message,
                                userVerified: false
                            })
                            setLoginError(data.message)
                    }
                    else {
                        localStorage.setItem("token",data.access_token)
                        store.dispatch(getUser())
                        props.loginCallback({
                            loginError: "",
                            userVerified: true
                        })
                        setLoginError('')
                    }
                })
            },
            function (err) {
                props.loginCallback({
                    loginError: "Serdi lugemine ebaõnnestus. Kontrolli, kas ID-kaart on lugejas. : " + err,
                    userVerified: false
                })
            }
            );
        }
        else {
            hwcrypto.getCertificate({lang:'et'})
            .then(
            function (response) {
                var certPEM = hexToPem(response.hex);

                var headers = {
                    'Content-Type' : 'application/json'
                }

                fetch(config.get('apiUrl')+'idCardAuthenticationRequest',{
                    method: 'POST',
                    headers,
                    body: JSON.stringify({cert: certPEM})
                })
                .then(res=>res.json())
                .then((data)=> {
                    console.log(data)
                })
            
            },
            function (err) {
                console.log("Serdi lugemine ebaõnnestus. Kontrolli, kas ID-kaart on lugejas. : "
                + err);
            }
            );
        }
    }

    return (
        <span>
        {loginError !== '' ?  <Row><Col className="error">{loginError}</Col></Row> : ''}

        <Row>
            <Col lg="3" className="lato size-18 right">ID&#8209;kaart</Col>
            <Col lg="9"><RoundButton icon="id-card" color="button-green" size="size-icon" onClick={()=>authenticate()}/></Col>
        </Row>

        </span>
    )
})