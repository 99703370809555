import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import NumberFormat from 'react-number-format';


export default class FormTextFieldDouble extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            id1: this.props.defaults.id1,
            id2: this.props.defaults.id2
        }
    }

    fieldChange = (value, name) => {
        var valuesToReturn = {}
        value= parseInt(value.replace(/€/,""))

        if (name === this.props.id){
            this.setState({
                id1: value
            })
            valuesToReturn = {
                id1: value,
                id2: this.state.id2
            }
        }
        else if (name === this.props.id2){
            this.setState({
                id2: value
            })
            valuesToReturn = {
                id1: this.state.id1,
                id2: value
            }
        }
        
        this.props.onChange(valuesToReturn)
    }

    componentWillReceiveProps(nextProps){

        if (this.state.id1 === undefined){
            this.setState({
                id1: nextProps.defaults.id1,
                id2: nextProps.defaults.id2
            })
        }
    }

    render() {
      return <span>
                {this.props.labelVisible ? <label className="lato-bold size-16">{this.props.label}</label> : <div className="form-label"></div>}    
                <InputGroup>
                <NumberFormat name={this.props.id} type="text" isNumericString={true} className="text-input-borderless-double" value={this.state.id1} suffix={this.props.unit} onChange={e => {
                    this.fieldChange(e.target.value,this.props.id);
                  }}/>
                
                 <span className="formFieldSeparator">-</span> 
                 <NumberFormat name={this.props.id2} type="text" isNumericString={true} className="text-input-borderless-double" value={this.state.id2} suffix={this.props.unit} onChange={e => {
                    this.fieldChange(e.target.value,this.props.id2);
                  }}/></InputGroup>
              </span>;
    }
  }