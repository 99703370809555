import React from 'react';
import CustomerItem from '../components/CustomerItem';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


export default class CustomerCarousel extends React.Component {

    constructor(props){
        super(props)

        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 1700 },
              items: 4,
            },
            desktop: {
              breakpoint: { max: 1700, min: 1350 },
              items: 3,
            },
            tablet: {
              breakpoint: { max: 1350, min: 950 },
              items: 2,
            },
            mobile: {
              breakpoint: { max: 950, min: 0 },
              items: 1,
            },
          };

        let itemList = [
            {logo:require("./../assets/prokapital.png")},
            {logo:require("./../assets/uusmaa.png")},
            {logo:require("./../assets/hausers.png")},
            {logo:require("./../assets/luminor.png")}
            
        ]

        this.state = {
            currentIndex: 0,
            items: itemList,
            responsive: responsive
        }

    }

    slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

    slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

    
    render() {

        const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
            const { carouselState: { currentSlide } } = rest;
            let slideClass = ''
            if (currentSlide === 0)  slideClass = 'disable'
            return (
              <div className="carousel-button-group"> 
                <span className={`customer-carousel-control-prev-icon ${slideClass}`} onClick={() => previous()} />
                <span className="customer-carousel-control-next-icon" onClick={() => next()} />
              </div>
            );
          };

      return <div className="customer-carousel">
          <Carousel
            infinite={true}
            centerMode={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup/>}
            containerClass="carousel-container"
            responsive={this.state.responsive}>
           {this.state.items.map((item, key) => {
               return (
                    <CustomerItem image={item.logo}></CustomerItem>
               )
           })
            }     
            </Carousel>

            </div>
               
      ;
    }
  }