import React from 'react';
import Select from 'react-select'

export default class FormSelect extends React.Component {

    constructor(props) {
        super(props)


        this.state = {
          options: props.options,
          value: this.props.defaultValue
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            options: newProps.options
        })
    }

    updateValue = (value) => {
        this.setState({
            value: value
        })
        this.props.onChange(value)
        
    }

    formatGroupLabel = (data) => (
        <div className="select-label lato size-16">
          <span>{data.label}</span>
        </div>
      );

    render() {

        return  <div className="form-group">
                {this.props.label ? <label className="lato-bold size-16 form-label">{this.props.label}</label> : ''}
                    <Select 
                        className='react-select-container'
                        classNamePrefix='react-select'
                        onChange={(this.updateValue)}
                        isClearable={this.props.isClearable}
                        isMulti={this.props.multiple}
                        defaultValue={this.props.defaultValue}
                        value={this.state.value}
                        options={this.state.options}
                        placeholder={this.props.placeholder}
                        formatGroupLabel={this.formatGroupLabel}
                        name={this.props.name} />
                </div>;
    }
  }