import React, { useState, memo } from 'react';
import { useForm, Controller } from 'react-hook-form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import hwcrypto from 'hwcrypto-js'
import config from 'react-global-configuration';
import RoundButton from '../components/RoundButton';
import { store } from '../store'

export const IdCardSign = memo(props => {

    const { handleSubmit, errors, control } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [idCode, setIdCode] = useState('')
    const [loginError, setLoginError] = useState('')

    const isEmpty = (str) => {
        return (!str || 0 === str.length);
    }

    const hexToBase64 = (str) => {
        return btoa(String.fromCharCode.apply(null,
          str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
        );
      }
      
    const hexToPem = (s) => {
         var b = hexToBase64(s);
         var pem = b.match(/.{1,64}/g).join("\n");
         return "-----BEGIN CERTIFICATE-----\n" + pem + "\n-----END CERTIFICATE-----"; 
      }

    const sign = values => {

        hwcrypto.getCertificate({lang:'et'})
        .then(
        function (response) {
            var cert = response
            var certPEM = hexToPem(response.hex)

            var headers = {
                'Content-Type' : 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem("token")
            }

            var signRequest = {
                uuid: props.objectId,
                objectType: props.objectType,
                cert: certPEM
            }

            fetch(config.get('apiUrl')+'idCardSignRequest',{
                method: 'POST',
                headers,
                body: JSON.stringify(signRequest)
            })
            .then(res=>res.json())
            .then((data)=> {
                console.log(data)

                var hashtype = 'SHA-256';
                var hash = data.dataToSign;
                let sessionId = data.sessionId

                hwcrypto.sign(
                    cert,
                    { type: hashtype, hex: hash },
                    { lang: 'et' }
                  )
                    .then(function (response) {
                      console.log("Moodustatud allkiri:\n" +
                        response.hex.match(/.{1,64}/g).join("\n"));
                        var signatureRequest = {
                            uuid: props.objectId,
                            objectType: props.objectType,
                            signature: response.hex,
                            sessionId: sessionId
                        }
        
                        fetch(config.get('apiUrl')+'idCardSignature',{
                            method: 'POST',
                            headers,
                            body: JSON.stringify(signatureRequest)
                        })
                        .then(res=>res.json())
                        .then((data)=> {
                            if (!isEmpty(data.error)){
                                props.firstCallback({
                                    loginError: data.message,
                                    userVerified: false 
                                })
                            }
                            else {
                                    props.firstCallback({
                                        loginError: "",
                                        userVerified: true
                                    })
                            }
                        })
                    }, function (err) {
                      console.log("Allkirjastamine ebaõnnestus: " + err);
                    });

                
        
        },
        function (err) {
            console.log("Serdi lugemine ebaõnnestus. Kontrolli, kas ID-kaart on lugejas. : "
            + err);
        }
        )
    })
}

    return (
        <span>
        {loginError !== '' ?  <Row><Col className="error">{loginError}</Col></Row> : ''}
            <RoundButton icon="id-card" color="button-green" size="size-icon" onClick={()=>sign()}/>


        </span>
    )
})