import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import RoundButton from '../components/RoundButton';
import moment from 'moment'
import { connect } from 'react-redux'
import Timer from '../components/Timer';
import EstateDetails from '../components/EstateDetails';
import EstateExternalDetails from '../components/EstateExternalDetails';
import SubscriptionButton from '../components/SubscriptionButton';
import LoadingAnimation from '../components/LoadingAnimation';
import ConfirmationModal from '../components/ConfirmationModal';
import Highlight from '../assets/highlight.svg'
import ShareButton from '../components/ShareButton';
import { Table } from 'react-bootstrap';


class Auction extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            object: null,
            bidPrice: null, 
            error: null, 
            bids: null,
            myBidLast: false,
            currentPrice: null,
            auctionStatus: null,
            endTime: null,
            subscription: false,
            modalVisible: false,
            modalTitle: "",
            modalInfoText: "",
            modalValueEntry: false,
            blindAuctionText: "Pimeoksjoni korral on teil võimalik pakkuda ka alghinnast madalamat hinda. Pimeoksjoni võitja on Enampakkuja, kes pakkus Oksjonil kõrgeima hinna. Enampakkuja on Pimeoksjoni võitja ka juhul, kui kõrgeim pakutud hind jääb alla Pimeoksjoni alghinna, kuid sellisel juhul on Müüjal üksnes õigus, aga mitte kohustus, võõrandada Oksjoni objekt Enampakkujale.",
            buyNowAuctionText: "„Osta kohe“ oksjoni võitja on Enampakkuja, kes pakkus „osta kohe“ hinna. Kui „osta kohe“ hinda ei paku Oksjoni jooksul ükski Enampakkuja, võidab Enampakkuja, kes pakkus kõrgeima hinna.",
            token: localStorage.getItem("token"),
            general: [
                "Oksjoni tüüp",
                "Tehingu liik",
                "Alghind",
                "Osta-kohe hind",
                "Algusaeg",
                "Lõppaeg"
            ],
            generalData: [],
            contract : [
                "Tehingu sõlmimise tähtaeg",
                "Tehingu sõlmimise koht",
                "Tehingu sõlmimisega seotud kulude tasumine"
            ],
            contractData : []
        }

        this.getAuction(this.props.match.params.uuid)
        this.getSubscription(this.props.match.params.uuid)
        this.getBids(this.props.match.params.uuid)

    }

    getAuction = (uuid) => {
        var header = {}
        if (localStorage.getItem("token")){
            header = {headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            }
        }
        fetch(config.get('apiUrl')+'auction/'+ uuid,header)
        .then(res=>res.json())
        .then((data)=> {
          
          this.setState({
              object: data.object,
              modalValueEntry: data.object.auction_type.id === 2 ? true: false,
              modalTitle: data.object.auction_type.id === 2 ? "Pimeoksjon" : "\"Osta kohe\" oksjon",
              modalInfoText: data.object.auction_type.id === 2 ? this.state.blindAuctionText : this.state.buyNowAuctionText, 
              bidPrice: data.object.current_price + data.object.step,
              currentPrice: data.object.current_price,
              auctionStatus: data.object.status_id,
              endTime: data.object.timeInSeconds,
              generalData: [
                data.object.auction_type.name,
                data.object.transaction_type.name,
                data.object.start_price + " €",
                data.object.buy_now_price + " €",
                moment(data.object.start_date).format("DD.MM.YYYY HH:mm:ss"),
                moment(data.object.end_date).format("DD.MM.YYYY HH:mm:ss")
              ],
              contractData: [
                data.object.contract_due_time + " päeva",
                (data.object.contract_locations.map(location=>location.city.name)).join(', '),
                data.object.contract_cost.name
              ]
            })
        })
    }

    getSubscription = (uuid) => {
        fetch(config.get('apiUrl')+'subscription/'+ uuid + '/1',
        {headers: {
            Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
        .then(res=>res.json())
        .then((data)=> {
          if (data.subscription){
            this.setState({
                subscription: data.subscription
              })
          }
          
        })
    }

    getBids = (uuid) => {
        var header = {}

        if (localStorage.getItem("token")){
            header = {headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            }
        }
        fetch(config.get('apiUrl')+'auction/'+ uuid + '/bids',header)
        .then(res=>res.json())
        .then((data)=> {
          var bidItems = []
            if (Object.keys(data).length > 0){

                Object.keys(data).forEach(key => {
                    bidItems.push({
                        created_at: moment(data[key].created_at).format("DD.MM.YYYY HH:mm:ss"),
                        price: data[key].price,
                        key:key
                    })
                  });
                
                if (data[0].myBid && (data[0].bid_type_id !== 2)){
                    this.setState({
                        myBidLast: true,
                        currentPrice: data[0].price,
                        auctionStatus: 4
                    })
                }

                this.setState({bids:bidItems})
            }
        })
    }

    increasePrice = () => {
        this.setState({
            bidPrice: this.state.bidPrice + this.state.object.step
        })
    }

    decreasePrice = () => {
        if ((this.state.bidPrice - this.state.object.step) > this.state.object.current_price){
            this.setState({
                bidPrice: this.state.bidPrice - this.state.object.step
            })
        }
    }

    handleModalData = (status) => {
        console.log(status)
        if (status == true) {
            this.getBids(this.props.match.params.uuid)
        }
        this.setState({
            modalVisible: false
        })
 
    }

   subscriptionCallback = (status) => {
    this.setState({
        subscription: status
    })
   }

    makeBid = () => {
        var myBid = {
            auctionId: this.state.object.uuid,
            price: this.state.bidPrice
        }
        fetch(config.get('apiUrl')+'bid',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ localStorage.getItem("token")
            },
            body: JSON.stringify(myBid)
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.error){
                this.setState({
                    error: data.error
                })
            }
            else {
                this.getBids(this.props.match.params.uuid)
                 this.setState({
                    currentPrice: data.bid.price
                })
            }
        })
    }

    render() {

        if (this.state.object == null) {
            return <LoadingAnimation style="page-loading-animation"/>
        }
        var disabledAuction = false
        if (this.props.user.id === this.state.object.user_id || this.state.endTime < 0 || this.state.auctionStatus !== 3){
            disabledAuction = true
        }

        const lblink = "https://xgis.maaamet.ee/ky/" + this.state.object.cadastres[0].cadastral_id
        const mrlink = "https://register.metsad.ee/#/?kataster=" + this.state.object.cadastres[0].cadastral_id

        return <Container className="page-container object-page" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="back-link lato size-16">
                <Col>
                    <Link to="/oksjonid">&larr; Tagasi oksjonite nimekirja</Link>
                </Col>
            </Row>
            <Row className="page-title">
                <Col lg="7">
                    <Row className="lato-black size-40">{this.state.object.title}</Row>
                    <Row className="lato size-20">{this.state.object.cadastres[0].location.settlement_unit}, 
                                        {this.state.object.cadastres[0].location.municipality.name}, 
                                        {this.state.object.cadastres[0].location.municipality.county.name}</Row>
                </Col>
                <Col lg="5" className="top-links">
                {this.state.token ? <ShareButton id={this.state.object.uuid} type="1"/> : ''}
                {this.state.token ? 
                    <SubscriptionButton id={this.state.object.id} type="1" subscription={this.state.subscription} subscribeCallback={this.subscriptionCallback}/>
                    :
                    ''
                }
                </Col>
            </Row>
            <Row className="white-entry-box shadow">
                <Container fluid>
                {this.state.error != null ? <Row className="error-message lato size-16"><Col>{this.state.error}</Col></Row> : ''}
                <Row className="our-page-menu shadow object-header">
                    <Col lg="3">
                        <Row className="item-table-header">{disabledAuction === true ? <span>Lõpphind</span> : <span>Hetke hind</span> }</Row>
                        <Row className="lato-bold size-20 highlight-color">{this.state.currentPrice} €</Row>
                    </Col>
                    <Col lg="3">
                        <Row className="item-table-header">Aega oksjoni lõpuni</Row>
                        <Row className="lato-bold size-20 highlight-color"><Timer time={this.state.endTime} type={1} status={this.state.object.status_id}/></Row>
                    </Col>
                    {this.state.myBidLast === false && disabledAuction === false ? 
                    <Col lg="3">
                        <Row className="item-table-header padding-left-15">Pakkumise suurus:</Row>
                        <Row className="bid-price lato-bold size-20 "><RoundButton title="&ndash;" color="button-green" size="size-16" onClick={this.decreasePrice} />{this.state.bidPrice} €<RoundButton title="+" color="button-green" size="size-16" onClick={this.increasePrice} /></Row>
                        <Row className="lato size-14 padding-left-15">Pakkumise samm: {this.state.object.step} €</Row>
                    </Col> : 
                    <Col lg="3" className="">
                        <Row className="item-table-header">{disabledAuction === true ? '' : 'Viimane pakkuja:'}</Row>
                    <Row className="lato-bold size-20 highlight-color">{disabledAuction === true ? '' : <span>Teie ({this.state.currentPrice}€)</span>}</Row>
                    </Col>
                    }
                    {this.state.myBidLast === false ?
                    <Col lg="3">
                        <Row className="offer-button">{disabledAuction === true ? '' : <RoundButton title="Tee pakkumine" color="button-green" size="size-16" onClick={this.makeBid} /> }</Row>
                        {this.state.object.auction_type.id === 2 && this.state.currentPrice === this.state.object.start_price ? <Row className="center lato size-16"><Link onClick={()=>{this.setState({modalVisible: true})}}>Tee oma pakkumine</Link></Row> : ''}
                        {this.state.object.auction_type.id === 3 ? <Row className="center lato size-16"><Link onClick={()=>{this.setState({modalVisible: true})}}>Paku "osta kohe" hind</Link></Row> : ''}

                    </Col> : ''
                    }
                    

                </Row>
                
                <Row className="object-content-padding shadow">
                    <Col lg="5">
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Oksjoni andmed</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped responsive>
                                    <tbody>
                                    {this.state.general.map((item, idx) => {
                                        if (this.state.object.auction_type.id !== 3 && idx === 3 ) { return ''}
                                        else {
                                        return (<tr>
                                            <td className="lato-bold size-16">{item}</td>
                                            <td className="lato size-16">{this.state.generalData[idx]}</td>
                                        </tr>)
                                        }
                                    })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="1"></Col>
                    <Col lg="6">
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Viimased pakkumised</Col>
                        </Row>
                        {this.state.bids !== null && this.state.object.auction_type_id === 2 && this.state.currentPrice === this.state.object.start_price ?
                        <Row>
                         <Col className="lato size-16">Pimeoksjonil näed alghinnast madalamate pakkumiste korral vaid enda suurimat pakkumist.</Col>
                        </Row> : '' }
                        {this.state.bids === null ?
                        <Row>
                         <Col className="lato size-16">Pakkumised puuduvad</Col>
                        </Row>
                        :
                        <Row>
                            <Col>
                                <Table striped responsive>
                                    <tbody>
                                    {this.state.bids.map((bid, idx) => {
                                        return (<tr>
                                            <td className="lato size-16">{bid.created_at}</td>
                                            <td className="lato size-16">{bid.price} €</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        }
                    </Col>
                    </Row>

                    {this.state.object.highlights ? 
 
                      <Row className="object-content-padding shadow">
                        <Col>
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Peamised omadused</Col>
                        </Row>
                     <Row >
                        <ul className="highlights">{this.state.object.highlights.split('\n').map((highlight) => {
                            return(<li><img src={Highlight}/> {highlight}</li>)
                        })}</ul>
                        </Row>
                        </Col>
                     </Row>: ''    
                    }

                    {this.state.object.cadastres.map((cadastre,idx) => {

                        return (
                        <EstateDetails idx={idx+1} total={this.state.object.cadastres.length} cadastre={cadastre}/>
                        )
                    })}
                    <EstateExternalDetails lblink={lblink} mrlink={mrlink}/>
                    {this.state.object.additional_info ? 
                    <Row className="object-content-padding shadow">
                        <Col>
                            <Row>
                                <Col className="lato-bold size-20 highlight-color sub-title">Lisainfo</Col>
                            </Row>
                            <Row>
                                <Col className="lato size-16">{this.state.object.additional_info}</Col>
                            </Row>
                        </Col>
                
                    </Row>
                    :
                    ''
                    }
                    {this.state.object.files.length > 0 ? 
                    <Row className="object-content-padding shadow">
                        <Col className="detail-documentation">
                            <Row>
                                <Col className="lato-bold size-20 highlight-color sub-title">Oksjoni detailinfo</Col>
                            </Row>
                            {this.state.object.files.map((file) => {
                                return (
                                    <Row>
                                        <Col className="lato size-16">
                                            <span>
                                                {file.file_type === 'pdf' ? <Image src={require("./../assets/pdf.png")}/> : <Image src={require("./../assets/word.png")}/>}
                                            </span>
                                            <a href={config.get('apiUrl')+'files/'+file.uuid+"."+file.file_type} target="_blank">{file.name}</a>

                                        </Col>
                                    </Row>
                                )
                            })}
                            
                        </Col>
                
                    </Row>
                    :
                    ''
                    }

                    <Row className="object-content-padding shadow">
                    <Col>
                        <Row>
                            <Col className="lato-bold size-20 highlight-color sub-title">Tehinguinfo</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped responsive>
                                    <tbody>
                                    {this.state.contract.map((item, idx) => {
                                        return (<tr>
                                            <td className="lato-bold size-16">{item}</td>
                                            <td className="lato size-16">{this.state.contractData[idx]}</td>
                                        </tr>)
                                        
                                    })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    </Row>

                </Container>
            </Row>
            <ConfirmationModal
                show={this.state.modalVisible}
                onHide={() => this.setState({modal:false})}
                title={this.state.modalTitle}
                infoText={this.state.modalInfoText}
                confirmationButton={true}
                auctionId={this.props.match.params.uuid}
                valueEntry={this.state.modalValueEntry}
                buyNowPrice={this.state.object.auction_type.id === 3 ? this.state.object.buy_now_price : 0}
                parentCallback={this.handleModalData}
            />

            </Container>
          </Container>

    }

}


function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(Auction)
