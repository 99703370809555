import React from 'react';
import Form from 'react-bootstrap/Form'

export default class FormTextField extends React.Component {

    render() {

      return <Form.Group controlId={this.props.id}>
                {this.props.labelVisible ? <Form.Label className="lato-bold size-16">{this.props.label}</Form.Label> : <div className="form-label"></div>}
                  <Form.Control 
                    className="text-input-borderless" 
                    value={this.props.value} 
                    rows={this.props.rows} 
                    as={this.props.textarea}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled} 
                    type={this.props.type} 
                    onChange={e => {
                    this.props.onChange(e.target.value);
                  }}/>
              </Form.Group>;
    }
  }