import React from 'react';
import Modal from 'react-bootstrap/Modal'

export default function PinModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.errormessage === "" ?
          <span>
          <p>
            Teie mobiiltelefonile saadeti kontrollkood. Kui see vastab sellele kontrollkoodile, mida näete siin, siis sisestage {props.sign ? 'PIN 2' : 'PIN 1'}.
          </p>
          <p>Teie kontrollkood: <b>{props.verificationcode}</b></p></span>
          :
          <span><p> {props.sign ? 'Allkirjastamisel tekkis viga:' : 'Sisselogimisel tekkis viga:'}</p>
          <p>{props.errormessage}</p></span>
          }
        </Modal.Body>
      </Modal>
    );
  }