import React from 'react';
import Autosuggest from 'react-autosuggest'

export default class FormAutosuggest extends React.Component {

    getSuggestionValue = (suggestion) => {
        return suggestion.name;
    }
      
    renderSuggestion = (suggestion) => {
        return (
          <span>{suggestion.name}</span>
        );
      }

    render() {



        return  <div className="form-group">
                <label className="lato-bold size-16 form-label">{this.props.label}</label>
                    <Autosuggest 
                        suggestions={this.props.suggestions}
                        onSuggestionsFetchRequested={this.props.loadSuggestions}
                        onSuggestionsClearRequested={this.props.emptySuggestions}
                        getSuggestionValue={this.getSuggestionValue}
                        onSuggestionSelected={this.props.suggestionSelected}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={this.props.inputProps} />
                </div>;
    }
  }