import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from 'react-global-configuration';
import { connect } from 'react-redux'
import TableItem from '../components/TableItem';
import MyActionsMenu from '../components/MyActionsMenu';

class MyOffers extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            bids: [],
        }
        this.getMyBids()

    }

    getMyBids = () => {
        fetch(config.get('apiUrl')+'myBids',{
            headers: {
                Authorization: 'Bearer '+ localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(json => {
                var myBids = []
                json.bids.map((bid) => 
                {
                    myBids.push({
                        'uuid':bid.auction.uuid,
                        'content':[bid.auction.title, bid.auction.cadastres[0].cadastral_id,bid.max_price,bid.auction.status.title ]
                    })

                })
                this.setState({
                    bids: myBids
                })
            })
    }

  

    render() {


        return <Container className="page-container" fluid> 
            <Container className="top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/subpage_back.svg")})`}} fluid>
            <Row className="page-title">
                <Col>
                    <Row className="lato-black size-40">Tere, {this.props.user.first_name.toString()}</Row>
                </Col>
            </Row>
            <Row className="white-entry-box shadow">

                <Container fluid>
                    <Row>
                <Container fluid>
                    <MyActionsMenu step={1} type={1}/>
                </Container>
                
                <Container className="account shadow" fluid>
                    <Row className="my-actions-section">
                                <Container>
                                    <Row>
                                        <Col className="lato size-25 highlight-color">Viimased pakkumised</Col>
                                    </Row>
                                    {this.state.bids.length === 0 ?  
                                    <Row><Col className="informative-text">Pakkumised puuduvad</Col></Row> : 
                                    <Row><Col>
                                    <TableItem header={['Nimi','Katastritunnus','Minu pakkumine','Staatus']} entities={this.state.bids} entityType="oksjon"/>
                                    </Col></Row>
                                    }
                                </Container>
                    </Row>

                </Container>
                </Row>
                </Container>
                </Row>
                </Container>

     
        </Container>

}


}

function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(MyOffers)
