import React from 'react';
import TypeCount from './TypeCount'
import {ReactComponent as TypeEstate} from './../assets/type_estate.svg'
import {ReactComponent as TypeField} from './../assets/type_field.svg'
import {ReactComponent as TypeLogs} from './../assets/type_logs.svg'
import {ReactComponent as TypeAll} from './../assets/type_all.svg'


export default class SubTypes extends React.Component {

    constructor(props){
        super(props)

        var title = "";
        var myIcon = "";

        switch(this.props.type){
            case "logs" :
              title = "Raieõigus"
              myIcon = <TypeLogs/>
              break;
            case "field" :
              title = "Põllumaa rent"
              myIcon = <TypeField/>
            break;
            case "estate" :
              title = "Kinnistud"
              myIcon = <TypeEstate/>
            break;
            default : 
              title = "Kõik"
              myIcon = <TypeAll/>
            break;
        }

        this.state = {
            title: title,
            img: myIcon
        }

    }

    render() {

      return <div className="sub-types">
            {this.state.img}
            <div className="sub-types-text">{this.state.title}</div>
            <TypeCount count={this.props.count}/>
        </div>
        ;
    }
  }