import React, { useState, memo } from 'react';
import { useForm, Controller } from 'react-hook-form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import config from 'react-global-configuration';
import RoundButton from '../components/RoundButton';
import FormTextFieldFormatted from './FormTextFieldFormatted';
import { store } from '../store'

export const MobileIdAuthentication = memo(props => {

    const { handleSubmit, errors, control, setValue } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [phone, setPhone] = useState('')
    const [serverError, setServerError] = useState('')

    const  getUser = () =>{
        return function(dispatch) {
            return fetch(config.get('apiUrl')+'user',{
                headers: {
                    Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            })
                .then(response => response.json())
                .then(json => {
                    dispatch({type:"STORE_USER",payload: json.user})
                })
        }
    }

    const onSubmit = values => {

        var headers = {
            'Content-Type' : 'application/json'
        }
        var authenticateRequest = ""
        var myPhone = ""
        if (localStorage.getItem("token")){
            headers.Authorization = 'Bearer '+ localStorage.getItem("token")
        }
        else {
            myPhone = values.phone
            authenticateRequest = {
                phone: myPhone
            }
        }

        fetch(config.get('apiUrl')+'midAuthenticationRequest',{
        method: 'POST',
        headers,
        body: JSON.stringify(authenticateRequest)
    })
    .then(res=>res.json())
    .then((data)=> {

        if (data.error) {
            setServerError(data.error)
        }
        else {
        let verificationCode = data.verificationCode
        let myAuthenticationHash = data.authenticationHash

        props.firstCallback({
            modal:true,
            modalTitle: "Mobiil-ID",
            verificationCode: verificationCode,
            loginError: "",
            authenticationHash: myAuthenticationHash
        })

        let requestBody = {
            phone: myPhone,
            authenticationHash: data.authenticationHash
        }

        fetch(config.get('apiUrl')+'midAuthenticate',{
            method: 'POST',
            headers,
            body: JSON.stringify(requestBody)
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.message){
                props.secondCallback({
                    modal:true,
                    modalTitle: "Mobiil-ID",
                    verificationCode: "",
                    loginError: data.message,
                    userVerified: false 
                })
            }
            else {
                let sessionId = data.sessionId
                console.log(sessionId)
                
                let interval = setInterval(() => {

                    if (props.login){

                        let body = {
                            grant_type:"mobileId",
                            client_id:"2",
                            client_secret:"LmoHbyXNYqTVGDawn04yRq1GWXwVbNBmRsFhSEXZ",
                            sessionId: sessionId,
                            authenticationHash: myAuthenticationHash,
                            phone: myPhone
                        }
                        fetch(config.get('apiUrl')+'oauth/token',{
                            method: 'POST',
                            headers,
                            body: JSON.stringify(body)
                        })
                        .then(res=>res.json())
                        .then((data)=> {

                            if (data.error){
                                const message = data.message.split(/:/)
                                if (message[1] !== " Authentication in progress"){
                                    props.secondCallback({
                                        modal:true,
                                        modalTitle: "Mobiil-ID",
                                        verificationCode: "",
                                        loginError: message[1],
                                        userVerified: false
                                    })
                                    clearInterval(interval)
                                }
                            }
                            else {
                                localStorage.setItem("token",data.access_token)
                                store.dispatch(getUser())
                                props.secondCallback({
                                    modal:false,
                                    modalTitle: "Mobiil-ID",
                                    verificationCode: "",
                                    loginError: "",
                                    userVerified: true
                                })
                                clearInterval(interval)
                            }
                        })

                    }
                    else {
                        let body = {
                            sessionId: sessionId,
                            authenticationHash: myAuthenticationHash
                        }
                        fetch(config.get('apiUrl')+'midAuthenticationStatus',{
                            method: 'POST',
                            headers,
                            body: JSON.stringify(body)
                        })
                        .then(res=>res.json())
                        .then((data)=> {
                            if (data.success){
        
                                props.secondCallback({
                                    modal:false,
                                    modalTitle: "Mobile-ID",
                                    verificationCode: "",
                                    loginError: "",
                                    userVerified: true
                                })
                                clearInterval(interval)
                            }
                            else if (data.message.length > 0){
                                props.secondCallback({
                                    modal:true,
                                    modalTitle: "Mobile-ID",
                                    verificationCode: "",
                                    loginError: data.message[0],
                                    userVerified: false
                                })
                                clearInterval(interval)
                            }
                        })
                    }
                }, 3000)
            }
        })
        }
    })
    
    }
    return (
       <span>
           {props.login ? <form onSubmit={handleSubmit(onSubmit)}>
                    <Container>
                    {serverError !== '' ?  <Row><Col className="error">{serverError}</Col></Row> : ''}
                    <Row>
                        <Col lg="3" className="lato size-18">Tel&nbsp;nr.</Col>
                        <Col lg="9">
                        <Controller 
                                render={({ onChange }) => (
                                    <FormTextFieldFormatted 
                                        id="phone" 
                                        type="text" 
                                        label="phone" 
                                        prefix="+372 "
                                        onChange={(value) => {
                                            setPhone(value);
                                            setValue("phone", value)
                                            return value
                                        }}
                                        labelVisible={false} 
                                        value={phone}/>
                                )} 
                                name="phone"
                                value={phone} 
                                defaultValue=""
                                rules={{
                                    required:"Telefoninumbri sisestamine on kohustuslik!",
                                    pattern: {
                                        value: /[0-9]/i,
                                        message: "Sisestatud telefoninumber on vigane"
                                    }
                                }} 
                                control={control}
                            />
                            </Col>
                    </Row>
                    {errors.phone && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.phone.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3"></Col>
                        <Col lg="9"><RoundButton type="submit" icon="mobile-id" color="button-green" size="size-icon"/></Col>
                    </Row>
                    </Container>
        </form> 
        : 
        <RoundButton title="Mobiil&#8209;ID" color="button-green" size="size-16" onClick={()=>onSubmit()}/>
        }
       </span>
        
    )

})