import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ColorBar from './ColorBar';
import PartnerBlockItem from './PartnerBlockItem';

export default class PartnerBlock extends React.Component {
    render() {
      return <Container className="partner-block">
          <Row>
              <Col className="lato-black size-40 center">Miks valida partneriks Maahange?</Col>
          </Row>
          <Row>
              <Col className="partner-block-colors"><ColorBar color="#5D752E" start={true}/><ColorBar color="#F4C478"/><ColorBar color="#2D250C" end={true}/></Col>
          </Row>
          <Row>
              <Col>
              <PartnerBlockItem type="1"/><PartnerBlockItem type="2"/><PartnerBlockItem type="3"/>
              </Col>
          </Row>
      </Container>;
      ;
    }
  }