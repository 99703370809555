import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

export default class MyActionsMenu extends React.Component {

    constructor(props){
        super(props)

        const myAccount =  ['Minu maahange', 'Minu pakkumised','Minu oksjonid','Minu hanked', 'Minu kuulutused', 'Jälgin']
        const addObject = ['Üldised andmed','Ülevaade','Kinnitamine']
        var menuToShow = []


        switch (this.props.type) {
          case 1:
            menuToShow = myAccount
            break;
          case 2:
            menuToShow = addObject
            break;
          default:
            break;
        }
        this.state = {
            items: menuToShow,
            activeItem: this.props.step
        }

    }


    render() {

      const menuLength = this.state.items.length

      return <Row>
              <Col className={this.props.type === 1 ? 'arrow-menu my-actions-menu navbar-light' : 'arrow-menu add-object-menu navbar-light'}>
                <Dropdown className="arrow-menu-toggle">
                  <Dropdown.Toggle alignRight id="navbarDropdownMenu">
                      <span className="navbar-toggler-icon"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight={true}>
                      {this.state.items.map((menu, index) => 
                          <span>
                          <Dropdown.Item 
                              as={Link} to={"/"+ menu.toLowerCase().replace(/\s+/g,'')} 
                              key={index}
                          >{menu}</Dropdown.Item>
                          
                          </span>
                      )}
                  </Dropdown.Menu>
                </Dropdown>

                <ul className="arrow-menu-list">
                   {this.state.items.map((item, index) => <li className={this.state.activeItem === index ? `active action-menu-item-${index} z-index-${menuLength -index}` : `action-menu-item-${index}  z-index-${menuLength -index}`}>{this.props.type === 1 ? <Link to={"/"+ item.toLowerCase().replace(/\s+/g,'')}>{item}</Link> : item}</li>)}
                </ul>
              </Col>
            </Row>

    }
  }

