import { STORE_USER } from './../actions/index'

const initalState = {
    user: ''
}

function rootReducer(state = initalState, action) {
    console.log("rootReducer")
    if (action.type === STORE_USER){
        console.log("reducer store user")
        console.log(action.payload)
        state.user = action.payload
    }
    return {...state}
}

export default rootReducer