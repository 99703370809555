import React from 'react';
import RoundButton from './RoundButton';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CustomLink from './CustomLink';



export default class MapApplication extends React.Component {
    render() {
      return <Container className="map-application">
          <Row className="map-application-title">
              <Col className="lato-black size-25 highlight-color padding-40">Kaardirakendus</Col>
          </Row>
          <Row className="map-application-content">
              <Col className="lato-light size-16">Meie kaardilt saad vaadata kõiki juriidilisi maaomanikke koos nende kontaktidega.</Col>
          </Row>
          <Row className="map-application-map">
              <Col>
              <CustomLink
                tag='span'
                to="/kaardirakendus"
                 >
                  <RoundButton title="Vaata" color="button-white-borderless map-application-button" size="size-18"/>
              </CustomLink>
              </Col>
          </Row>
      </Container>;
    }
  }