import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useForm, Controller } from 'react-hook-form'
import FormTextField from '../components/FormTextField';
import RoundButton from '../components/RoundButton'
import {ReactComponent as MHLogo} from './../assets/bwLogo.svg'
import config from 'react-global-configuration';
import { Link } from 'react-router-dom'



function NewsletterSubscription() {
    const { handleSubmit, errors, control, setValue } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [email, setEmail] = useState('')
    const [serverError, setServerError] = useState('')

    
    const onSubmit = values => {
        setServerError('')
        console.log(email)

        fetch(config.get('apiUrl')+'addSubscriber',{
          method: 'POST',
          headers: {
              'Content-Type' : 'application/json'
          },
          body: JSON.stringify(values)
      })
      .then(res=>res.json())
      .then((data)=> {
          console.log("tere")
          console.log(data)
      })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container className="newsletter-subscription">
            <Row>
              <Col className="lato-bold size-18 white-color">Liitu uudiskirjaga</Col>
            </Row>
            <Row>
              <Col className="lato size-16 white-color">Maahange.ee uudiskirjaga liitudes oled kursis kõigi meie tegemiste ning pakkumistega.</Col>
            </Row>
            {serverError !== '' ?  <Row><Col>{serverError}</Col></Row> : ''}
            {errors.email && (
                                <Row><Col className="lato size-14 white-color">{errors.email.message}</Col></Row>
                            )}
            <Row className="our-page-intro">
              <Col sm={7} lg={8} className="lato-bold size-16 white-color">
              <Controller 
                    render={({ onChange }) => (
                      <FormTextField 
                        id="email" 
                        label="Email" 
                        labelVisible={false}
                        onChange={(value) => {
                          setEmail(value);
                          setValue("email", value)
                          return value
                        }}
                        placeholder="Sinu e-posti aadress" 
                        value={email}/>
                    )} 
                    name="email" 
                    defaultValue=""
                    rules={{
                        required:"E-posti aadressi sisestamine on kohustuslik!",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Sisestatud e-posti aadress on vigane"
                        }
                    }} 
                    control={control}
                />
              </Col>
              <Col sm={5} lg={4}>
              <RoundButton title="Liitu" color="button-white" size="size-18"/>
              </Col>
            </Row>
            </Container>
        </form>
    )
}

export default class Footer extends React.Component {


    render() {
      return <Container className="footer" fluid>
                <Container className="inner-footer-container">
                    <Row className="footer-row">
                        <Col sm="12" md="3" lg="3" className="footer-section">
                          <Container>
                            <Row>
                              <Col>
                          <MHLogo/>
                          </Col>
                          </Row>
                          </Container>
                          </Col>
                        <Col sm="12" md="5" lg="5" className="footer-section">
                          <Container>
                            <Row>
                              <Col className="lato size-16 white-color">Saare küla, Põllu vald, 45403 Tuleviku maakond</Col>
                            </Row>
                            <Row>
                            <Col className="lato size-16 yellow-color">maahange@maahange.ee</Col>
                            </Row>
                            <Row>
                            <Col className="lato size-16 white-color">Tel: 123 4567</Col>
                            </Row>
                            <Row><Col>&nbsp;</Col></Row>
                            <Row>
                            <Col className="lato size-16 white-color"><Link to={"/sisu/"+1}> Kasutustingimused</Link> | <Link to={"/sisu/"+2}>Privaatsuspoliitika</Link></Col>
                            </Row>
                          </Container>
                        </Col>
                        <Col sm="12" md="4" lg="4" className="footer-section">
                          <NewsletterSubscription/>
                        </Col>
                        
                    </Row>
                    <Row>
                      <Col className="lato size-14 white-color">© Maahange OÜ. Kõik õigused kaitstud.</Col>
                    </Row>
                </Container>
             </Container>;
      ;
    }
  }