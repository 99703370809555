import React from 'react';
import ObjectMapIcon from './ObjectMapIcon';

export default class ObjectMap extends React.Component {

    constructor(props){
        super(props)

        const locations = {
            11: {
                1: {x: 20, y:340},
                2: {x: 50, y:270},
                3: {x: 110, y:240}
            },
            1: {
                1: {x: 280, y:35},
                2: {x: 340, y:35},
                3: {x: 400, y:35}
            },
            7: {
                1: {x: 530, y:10},
                2: {x: 530, y:65},
                3: {x: 530, y:120}
            },
            3: {
                1: {x: 620, y:55},
                2: {x: 690, y:55},
                3: {x: 650, y:110}
            },
            4: {
                1: {x: 490, y:210},
                2: {x: 550, y:190},
                3: {x: 615, y:180}
            },
            12: {
                1: {x: 520, y:290},
                2: {x: 580, y:270},
                3: {x: 640, y:270}
            },
            8: {
                1: {x: 580, y:350},
                2: {x: 640, y:350},
                3: {x: 690, y:390}
            },
            15: {
                1: {x: 570, y:410},
                2: {x: 625, y:420},
                3: {x: 590, y:470}
            },
            13: {
                1: {x: 465, y:370},
                2: {x: 525, y:360},
                3: {x: 500, y:420}
            },
            9: {
                1: {x: 270, y:240},
                2: {x: 310, y:280},
                3: {x: 330, y:335}
            },
            14: {
                1: {x: 410, y:250},
                2: {x: 460, y:280},
                3: {x: 420, y:335}
            },
            5: {
                1: {x: 450, y:100},
                2: {x: 460, y:155},
                3: {x: 410, y:200}
            },
            10: {
                1: {x: 290, y:170},
                2: {x: 330, y:115},
                3: {x: 360, y:165}
            },
            6: {
                1: {x: 190, y:110},
                2: {x: 210, y:160},
                3: {x: 200, y:215}
            },
            2: {
                1: {x: 60, y:110},
                2: {x: 90, y:160},
                3: {x: 25, y:170}
            },
        }

        this.state = {
            mapLocations: locations
        }

    }

    render() {
      return <div className="object-map"> 
            {this.props.counties.map((object) => 
                    <span 
                        className="map-icon" 
                        style={{left:this.state.mapLocations[object.id][object.count].x,top: this.state.mapLocations[object.id][object.count].y}}>
                            <ObjectMapIcon count={object.object_count} type={object.transaction_type_id}/>
                    </span>
                        )}

             </div>;
      ;
    }
  }