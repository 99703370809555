import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PriceCalculatorForm from '../components/PriceCalculatorForm';
import RoundButton from '../components/RoundButton';
import ColorBar from '../components/ColorBar';


export default class PriceCalculator extends React.Component {
  

    render() {
        return <Container className="page-container price-calculator-page" fluid> 
        <Container className="sub-top-background-image" style={{float:"left",backgroundImage:`url(${require("./../assets/price_calculator_back.svg")})`}} fluid>
        <Row>
            <Col>
                <Row className="lato-black size-65 white-color price-calculator-page-title">Hinnakalkulaatorid</Row>
            </Col>
        </Row>
        </Container>
        <Container>
            <Row>
                <Col lg="8"><PriceCalculatorForm area1="0ha" area2="0ha" price="0€"/></Col>
                <Col className="left price-offer" lg="4">
                    <Row>
                        <Col><ColorBar color="#2D250C" start={true}/><ColorBar color="#5D752E"/><ColorBar color="#F4C478"/><ColorBar color="#FFE8A6" end={true}/></Col>
                    </Row>
                    <Row>
                        <Col className="lato-light size-18">Hinnapakkumine on koostatud metsamajanduskava alusel arvestades piirkonna eripärasi, sortimenti, turuolukorda jm. asjaolusid, mis mõjutavad metsamaa hinda</Col>
                    </Row>
                    <Row>
                        <Col className="lato-bold size-18">Tegemist on hinnangulise väärtusega ja lõplik hind sõltub kinnistu reaalsest seisukorrast.</Col>
                    </Row>
                    <Row>
                        <Col className="lato-light size-18">NB! Kalkulaator ei arvesta raieid, mis on teostatud peale metsamajanduskava koostamist ega kaitsealade ja erinevate piirangutega.</Col>
                    </Row>
                    <Row>
                        <Col className="lato-light size-16">Soovid hindamisraportit koos Metsaomaniku Teatmikuga e-mailile?</Col>
                    </Row>
                    <Row>
                        <Col><RoundButton title="Saadan e-mailile" color="button-green" size="size-18"/></Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        </Container>;
    }
}