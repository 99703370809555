import React, { useState, memo } from 'react';
import { useForm, Controller } from 'react-hook-form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import FormTextField from '../components/FormTextField';
import config from 'react-global-configuration';
import RoundButton from '../components/RoundButton';
import { store } from '../store'

export const EmailLogin = memo(props => {
    const { handleSubmit, errors, control, setValue } = useForm({mode:"onSubmit",reValidateMode:"onSubmit"})
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [serverError, setServerError] = useState('')
    
    const  getUser = () =>{
        console.log("get user")
        return function(dispatch) {
            return fetch(config.get('apiUrl')+'user',{
                headers: {
                    Authorization: 'Bearer '+ localStorage.getItem("token")
                }
            })
                .then(response => response.json())
                .then(json => {
                    dispatch({type:"STORE_USER",payload: json.user})
                })
        }
    }


    const onSubmit = values => {
        setServerError('')

        let loginRequest = {
            grant_type:"password",
            client_id:"2",
            //client_secret: "wAbIMB1PADPqZdz6OkxvLHYKN68BXMzCgrjcdBQ2",
            client_secret:"LmoHbyXNYqTVGDawn04yRq1GWXwVbNBmRsFhSEXZ",
            username:values['email'],
            password:values['password']
        }
        
        fetch(config.get('apiUrl')+'oauth/token',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify(loginRequest)
        })
        .then(res=>res.json())
        .then((data)=> {
            if (data.error) {
                setServerError("Vigased ligipääsuandmed")
            }
            else {
                localStorage.setItem("token",data.access_token)
                store.dispatch(getUser())
                props.parentCallback(true)
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
                    <Container>
                    {serverError !== '' ?  <Row><Col className="error">{serverError}</Col></Row> : ''}
                    <Row>
                        <Col lg="3" className="lato size-18">Email</Col>
                        <Col lg="9">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="email" 
                                        label="Email"
                                        onChange={(value) => {
                                            setEmail(value);
                                            setValue("email", value)
                                            return value
                                        }}
                                        labelVisible={false} 
                                        value={email}/>
                                )} 
                                name="email"
                                defaultValue={email}
                                rules={{
                                    required:"E-posti aadressi sisestamine on kohustuslik!",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Sisestatud e-posti aadress on vigane"
                                    }
                                }} 
                                control={control}
                            />
                            </Col>
                    </Row>
                    {errors.email && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.email.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3" className="lato size-18">Parool</Col>
                        <Col lg="9">
                            <Controller 
                                render={({ onChange }) => (
                                    <FormTextField 
                                        id="password" 
                                        type="password" 
                                        label="Password"
                                        onChange={(value) => {
                                            setPassword(value);
                                            setValue("password", value)
                                            return value
                                        }}
                                        labelVisible={false} 
                                        value={password}/>
                                )} 
                                name="password"
                                defaultValue=""
                                rules={{
                                    required:"Salasõna sisestamine on kohustuslik!"
                                }} 
                                control={control}
                            />
                            
                        </Col>
                    </Row>
                    {errors.password && (
                                <Row><Col className="error" lg={{offset:3}}>{errors.password.message}</Col></Row>
                            )}
                    <Row>
                        <Col lg="3"></Col>
                        <Col lg="9"><RoundButton title="Sisene" color="button-green" size="size-icon"/></Col>
                    </Row>
                    </Container>
        </form>
    )
})