import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import PriceCalculator from './pages/PriceCalculator';
import OurPage from './pages/OurPage';
import Auctions from './pages/Auctions';
import Procurements from './pages/Procurements';
import Faq from './pages/Faq';
import Login from './pages/Login';
import Register from './pages/Register';
import MyAccount from './pages/MyAccount';
import AddObject from './pages/AddObject';
import ObjectReview from './pages/ObjectReview';
import ObjectConfirm from './pages/ObjectConfirm';
import Auction from './pages/Auction';
import MyActions from './pages/MyActions';
import Classifieds from './pages/Classifieds';
import Procurement from './pages/Procurement';
import Classified from './pages/Classified';
import MapApplication from './pages/MapApplication';
import MyOffers from './pages/MyOffers';
import MyAuctions from './pages/MyAuctions';
import MyProcurements from './pages/MyProcurements';
import MyClassifieds from './pages/MyClassifieds';
import MySubscriptions from './pages/MySubscriptions';
import AddCompany from './pages/AddCompany';
import ConfirmOffer from './pages/ConfirmOffer';
import ProcurementFeedback from './pages/ProcurementFeedback';
import Portfolio from './pages/Portfolio';
import Content from './pages/Content';

const Main = () => {
  return (
    <Switch>
      <Route exact path='/' component={Home}></Route>
      <Route exact path='/hinnakalkulaatorid' component={PriceCalculator}></Route>
      <Route exact path='/meiekeskkonnast' component={OurPage}></Route>
      <Route exact path='/oksjonid' component={Auctions}></Route>
      <Route exact path='/hanked' component={Procurements}></Route>
      <Route exact path='/kuulutused' component={Classifieds}></Route>
      <Route exact path='/kkk' component={Faq}></Route>
      <Route exact path='/logisisse' component={Login}></Route>
      <Route exact path='/registreeru' component={Register}></Route>
      <Route exact path='/minukonto' component={MyAccount}></Route>
      <Route exact path='/minumaahange' component={MyActions}></Route>
      <Route exact path='/lisaobjekt' component={AddObject}></Route>
      <Route exact path='/lisaobjekt2' component={ObjectReview}></Route>
      <Route exact path='/lisaobjekt3' component={ObjectConfirm}></Route>
      <Route exact path='/oksjon/:uuid' component={Auction}></Route>
      <Route exact path='/hange/:uuid' component={Procurement}></Route>
      <Route exact path='/pakkumine/:uuid' component={ConfirmOffer}></Route>
      <Route exact path='/tagasiside/:uuid' component={ProcurementFeedback}></Route>
      <Route exact path='/portfolio/:uuid' component={Portfolio}></Route>
      <Route exact path='/kuulutus/:uuid' component={Classified}></Route>
      <Route exact path='/kaardirakendus' component={MapApplication}></Route>
      <Route exact path='/minutegevused' component={MyActions}></Route>
      <Route exact path='/minupakkumised' component={MyOffers}></Route>
      <Route exact path='/minuoksjonid' component={MyAuctions}></Route>
      <Route exact path='/minuhanked' component={MyProcurements}></Route>
      <Route exact path='/minukuulutused' component={MyClassifieds}></Route>
      <Route exact path='/jälgin' component={MySubscriptions}></Route>
      <Route exact path='/lisaettevote' component={AddCompany}></Route>
      <Route exact path='/sisu/:id' component={Content}></Route>
    </Switch>
  );
}

export default Main;