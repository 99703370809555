import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CustomLink from '../components/CustomLink';
import RoundButton from '../components/RoundButton';

export default class EstateExternalDetails extends React.Component {


    render() {
      return <Row className="object-content-padding shadow single-row-content">
      <Col>
              <Row>
                  <Col lg="6" className="lato-bold size-20 highlight-color sub-title">Kinnistu detailinfo</Col>
                  <Col lg="3" className="lato size-16 single-row-button">
                      
                  <CustomLink
                      tag='span'
                      target={true}
                      to={this.props.lblink}
                      >
                          <RoundButton title="Maa-ametist" color="button-green" size="size-14"/>
                      </CustomLink>
                      </Col>
                  <Col lg="3" className="lato size-16 single-row-button">
                  <CustomLink
                      tag='span'
                      target={true}
                      to={this.props.mrlink}
                      >
                          <RoundButton title="Metsaregistrist" color="button-green" size="size-14"/>
                      </CustomLink></Col>
              </Row>
          </Col>
  
      </Row>
    }

}