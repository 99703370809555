import React from 'react';
import {ReactComponent as Estate} from './../assets/kinnistu_object.svg'
import {ReactComponent as Logs} from './../assets/logs.svg'
import {ReactComponent as Field} from './../assets/field.svg'
export default class ObjectMapIcon extends React.Component {

    constructor(props){
        super(props)

        var myIcon = ''
        switch (this.props.type) {
            case 1:
                myIcon = <Estate/>
                break;
            case 3:
                myIcon = <Logs/>
                break;
            case 4:
                myIcon = <Field/>
                break;
            default:
                myIcon = <Estate/>
        }

        this.state = {
            icon: myIcon
        }

    }

    render() {

      return  <div className="object-map-icon-container">
                <div className="object-map-icon">
                    {this.state.icon}
                </div>
                <span className="object-count-back">
                    <div className="object-count lato-black">{this.props.count}</div>
                </span>
              </div>
        ;
    }
  }