import React from 'react';
import RoundButton from './RoundButton';
import Notifications from './Notifications';
import Login from './Login';
import CustomLink from './CustomLink';
import { connect } from 'react-redux'

export class TopSubMenu extends React.Component {

    render() {
      return <div className={`top-sub-menu ${this.props.visibility}`} >
          <CustomLink
              tag='span'
              to="/lisaobjekt"
              >
                  <RoundButton title="Lisa objekt" color="button-green" size="size-14"/>
              </CustomLink>
            {this.props.user !== "" ? 
            <CustomLink
              tag='span'
              to="/jälgin"
              >
                  <Notifications count={this.props.count}/>
            </CustomLink>
            :
            ''
            }
          <Login/>
      </div>;
    }
  }

  function mapStateToProps(state){
    return {
      user: state.user
    }
  }
  
  export default connect(
    mapStateToProps
  )(TopSubMenu)
