import React from 'react';
import Form from 'react-bootstrap/Form'
import NumberFormat from 'react-number-format';

export default class FormTextFieldFormatted extends React.Component {

    fieldChange = (value) => {
        var replace = this.props.prefix
        value= parseInt(value.replace(replace,""))
        
        this.props.onChange(value)
    }

    render() {

      return <Form.Group controlId={this.props.id}>
                {this.props.labelVisible ? <Form.Label className="lato-bold size-16">{this.props.label}</Form.Label> : <div className="form-label"></div>}
                  
                <NumberFormat 
                    name={this.props.id} 
                    type={this.props.type}  
                    isNumericString={true} 
                    className="text-input-borderless form-control" 
                    value={this.props.value} 
                    disabled={this.props.disabled} 
                    prefix={this.props.prefix}
                    suffix={this.props.suffix}
                    allowEmptyFormatting={true}
                    onChange={e => {
                        this.fieldChange(e.target.value);
                    }}/>
                 
              </Form.Group>;
    }
  }