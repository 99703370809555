import React from 'react';
import {ReactComponent as Partner1} from './../assets/partner5.svg'
import {ReactComponent as Partner2} from './../assets/partner6.svg'
import {ReactComponent as Partner3} from './../assets/partner7.svg'
import { motion } from 'framer-motion'

export default class PartnerBlockItem extends React.Component {

    constructor(props){
        super(props)

        let images = {
            1: <Partner1/>,
            2: <Partner2/>,
            3: <Partner3/>
        }
        let titles = {
            1: "Tasuta",
            2: "45%",
            3: "999+",
        }
        let subTitles = {
            1: "klienditugi",
            2: "rohkem tulu",
            3: "potentsiaalset ostjat",

        }
        let itemContents = {
            1: "Meie klienditugi on valmis teenindama Teid TASUTA!",
            2: "Maahange on Eesti kõige soodaim vahendus-tasuga portaal. Meie keskkonnas müües teenid oma kinnistult keskmiselt 45% kõrgemat tulu.",
            3: "Meie keskkonnas saavad pakkumist esitada üle 1000 ettevõtte ja eraisiku",
        }
        let colors = {
            1: "#FFE8A6",
            2: "#F4C478",
            3: "#2D250C",
        }

        this.state = {
            img: images[this.props.type],
            title: titles[this.props.type],
            subTitle: subTitles[this.props.type],
            itemContent: itemContents[this.props.type],
            color: colors[this.props.type]
        }


    }

    render() {
      return <div className="partner-block-item">
                <motion.div whileHover={{ scale: 1.2 }} className="partner-block-item-badge">
                    {this.state.img}
                </motion.div>
                <div className="partner-block-item-line"></div>
                <div className="partner-item-title lato-black">{this.state.title}</div>
                <div className="partner-item-subtitle lato-bold">{this.state.subTitle}</div>
                <div className="partner-item-content lato-light">{this.state.itemContent}</div>
             </div>;
      ;
    }
  }